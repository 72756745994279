import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { first, skipWhile, takeUntil } from "rxjs/operators";
import { AddServiceComponent } from "src/app/components/forms/add-service/add-service.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Organization } from "src/app/models/organization.model";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-my-service-page",
  templateUrl: "./my-service-page.component.html",
  styleUrls: ["./my-service-page.component.scss"],
})
export class MyServicePageComponent implements OnInit, OnDestroy {
  public service: Healthcareservice; // not null if one particular service is selected
  public services: Healthcareservice[] = []; // used if we select "all services"
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  public currentOrganization: Organization;

  constructor(
    private depService: HealthcareserviceService,
    private sessionService: SessionService,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private responsiveDialog: ResponsiveDialogService
  ) {}

  ngOnInit(): void {
    if ((this.sessionService.currentService || this.sessionService.currentMonitoringService) && this.userService.ownOrganization) {
      this.init();
      this.setupWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          takeUntil(this.onDestroy$),
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.init();
          this.setupWatch();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupWatch() {
    if (this.userService.isMonitoringUser) {
      this.depService
        .watchAvailableMonitoringServices()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.init();
        });
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.init();
      });
    } else {
      this.depService
        .watchAvailableServices()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.init();
        });

      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.init();
      });
    }
  }

  private init() {
    this.services = [];
    this.service = null;

    const serviceRef = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;

    if (serviceRef) {
      const availableOrganizations: Organization[] = this.userService.isMonitoringUser
        ? this.organizationsService.availableMonitoringOrgs()
        : this.organizationsService.availableOrganizations();

      const availableServices = this.userService.isMonitoringUser
        ? this.depService.availableMonitoringServices()
        : this.depService.availableServices();
      if (serviceRef.reference === this.sessionService.allsOption) {
        this.service = null;
        this.services.push(...availableServices);

        if (this.sessionService.organization.reference === this.sessionService.allsOption) {
          // When the "all" service option (previous condition) AND the "all" organization option are selected, the currentOrganization is set to null.
          // This implies that all organizations will be selectable in the user creation form given the logic of the child components.
          this.currentOrganization = null;
        } else {
          // Otherwise, we utilize the organization of the services displayed in the app-service-details-component.
          // In this scenario, as all services belong to the same organization, we can safely use the [0] index.
          this.currentOrganization = availableOrganizations.find((org) => org.organizationReference === this.services[0].organizationRef);
        }
      } else {
        this.services = null;
        this.service = availableServices.find((s) => s.serviceRef === serviceRef.reference);
        // Here, we derive the organization of the selected service to maintain consistency
        // with the service displayed in the app-service-details-component, even when "all" is chosen as the current Organization.
        this.currentOrganization = availableOrganizations.find((org) => org.organizationReference === this.service.organizationRef);
      }
    }
  }

  /**
   * Service creation is only available when there's one organisation selected and "all" option selected for services
   * @returns
   */
  public onCreateService(): void {
    if (!this.currentOrganization || this.service !== null) return;
    this.responsiveDialog.open(AddServiceComponent, {
      disableClose: true,
      data: {
        service: null,
        orgRef: this.currentOrganization?.identifier[0],
        mode: FORMS_MODE.CREATE,
        defaultPrefsIds: this.currentOrganization.defaultPreferencesIds,
        defaultAccessGroup: this.currentOrganization.defaultAccessGroups,
      },
    });
  }
}
