<div class="alertPreferenceDialog">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <h2>{{ "alerts.preferenceTitle" | translate }}</h2>
    <mat-checkbox [(ngModel)]="showAlerts.levelOne" [checked]="showAlerts.levelOne">
      {{ "alerts.showLevelOneAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelTwo" [checked]="showAlerts.levelTwo">
      {{ "alerts.showLevelTwoAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelThree" [checked]="showAlerts.levelThree">
      {{ "alerts.showLevelThreeAlert" | translate }} </mat-checkbox
    ><br />
  </mat-dialog-content>
  <mat-card-actions class="m-0 mt-1" style="display: flex; justify-content: right">
    <button mat-raised-button color="primary" type="button" (click)="onSave()">
      {{ "common.save" | translate }}
    </button>
  </mat-card-actions>
</div>
