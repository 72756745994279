import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AnsPractitionnerResultComponent } from "src/app/components/ans-practitionner-result/ans-practitionner-result.component";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { PREVENTCHARACTER, PreventCharacter } from "src/app/helpers/formValidators";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { SlimUserInfo, UserRole } from "src/app/models/account.interface";
import { Coding } from "src/app/models/coding.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Identifier } from "src/app/models/identifier.interface";
import { AddPractitionerData, IAnsRole, IPractitionerInfo, PractitionerRole, TELECOM_SYSTEM } from "src/app/models/practitioner.interface";
import { Practitioner } from "src/app/models/practitioner.model";
import { Reference } from "src/app/models/sharedModels.model";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { PractitionerAccessGroupsComponent } from "../access-groups-page/practitioner-access-groups/practitioner-access-groups.component";
import { UserIdsValidators } from "./userIdsValidators";

export interface DataForUserPage {
  availableServices: Reference[];
  availableOrganizations: Reference[];
  preselectedService?: Reference;
  preselectedOrganization?: Reference;
  name?: string;
  user?: Practitioner;
  infos?: SlimUserInfo;
}

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.scss"],
})
export class UserPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PractitionerAccessGroupsComponent)
  private practitionerAccessGroupComponent: PractitionerAccessGroupsComponent;

  public mode: FORMS_MODE;
  public MODES = FORMS_MODE;
  public availableTitles: string[];
  public availableQualifications: Coding[];
  public availableRoles: Coding[];
  public availableServices: Reference[];
  public availableOrganizations: Reference[];
  public availableLangs: Coding[];
  public availableGender: Coding[];
  public lastIndexMail: number = null;
  public lastIndexPhone: number = null;
  public isActive = false;
  public isAnsChecked = false;
  public isAnsSearchAvailable = false;
  public ansId: Identifier;
  public ansPractitionerRole: PractitionerRole[];
  public ansRole: IAnsRole[];
  private ansFields = ["name", "firstname", "title", "birthDate", "inami", "nationalNumber", "userLang", "gender", "msSante"];
  public showMsSante = false;
  public PREVENTCHARACTER = PREVENTCHARACTER;

  public practitionerForm: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.pattern("[^0-9]*")]],
    firstname: ["", [Validators.required, Validators.pattern("[^0-9]*")]],
    role: ["", [Validators.required]],
    title: ["", [Validators.required]],
    birthDate: ["", [Validators.required]],
    qualif: ["", [Validators.required]],
    organisation: ["", [Validators.required]],
    services: ["", [Validators.required]],
    activationCode: ["", [Validators.required]],
    inami: ["", [Validators.pattern("[a-zA-Z0-9]*")]],
    internalId: ["", []],
    nationalNumber: ["", [Validators.pattern("[a-zA-Z0-9]*")]],
    needMail: ["", []],
    userLang: ["", [Validators.required]],
    gender: ["", [Validators.required]],
    login: ["", [Validators.required]],
    msSante: ["", []],
  });

  public ansForm: UntypedFormGroup = this.fb.group({
    ansGiven: ["", []],
    ansFamily: ["", []],
    msSanteSearch: ["", []],
  });

  public needMail = false;
  public user: Practitioner = null;
  public accessGroups: string[] = [];
  private data: DataForUserPage;
  private previousPage: string;
  private isInit = false;

  // All permissions of this page
  public canCreateGroup = false;
  public canReadGroups = false;
  public canReadPermissions = false;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private practitionerService: PractitionerService,
    private depService: HealthcareserviceService,
    private accessGroupApiService: AccessGroupApiService,
    private fb: UntypedFormBuilder,
    private formsData: FormsData,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    const navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState?.mode !== undefined) {
      this.mode = navigationState?.mode;
      this.accessGroups = ["practitioner"];
      if (navigationState?.data) {
        this.data = navigationState.data;
        this.initData();
      }
      if (navigationState?.previousPage) {
        this.previousPage = navigationState?.previousPage;
      }
    } else {
      this.router.navigate(["/"]);
    }
    this.checkUsersPermissions();
  }

  ngOnInit(): void {
    if (this.mode === FORMS_MODE.UPDATE) {
      this.practitionerForm.removeControl("login");
      this.practitionerForm.removeControl("activationCode");
      this.updateForm();
      if (this.ansId) {
        this.disableAnsFields();
      }
    } else {
      this.user = this.practitionerService.createTemplate();
      this.addPhone(false, 0);
      this.addMail(false, 0);
      // bind mail with login field
      this.practitionerForm
        .get("mail0")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe((v: string) => {
          this.practitionerForm.get("login").setValue(v);
        });
    }
    this.isAnsSearchAvailable = this.mode === this.MODES.CREATE && this.isPreselectedAndFrService();
    if (this.isPreselectedAndBEService()) {
      this.practitionerForm.get("inami").addValidators(UserIdsValidators.inamiValidator);
      this.practitionerForm.get("nationalNumber").addValidators(UserIdsValidators.belgianSSINValidator);
    }
  }

  ngAfterViewInit(): void {
    this.isInit = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Used when we choose to add another new user, without leaving this page
   */
  private prepareForNewUser(): void {
    if (this.mode !== FORMS_MODE.CREATE) {
      return;
    }
    this.user = this.practitionerService.createTemplate();
    const previousServices = this.practitionerForm.get("services").value;
    const previousOrganization = this.practitionerForm.get("organisation").value;
    this.practitionerForm.reset();
    this.removePhone(2);
    this.removePhone(1);
    this.removeMail(2);
    this.removeMail(1);
    if (this.data.preselectedService) {
      this.practitionerForm.get("services").setValue(previousServices);
      this.practitionerForm.get("organisation").setValue(previousOrganization);
    }
    this.practitionerForm.markAsPristine();
  }

  private async initData(): Promise<void> {
    const isAdmin = this.sessionService.isAdmin();
    this.availableTitles = this.formsData.TITLES;
    this.availableQualifications = this.formsData.QUALIFICATIONS;
    this.availableRoles = this.formsData.ROLES.filter((r) =>
      isAdmin ? r : r.code !== UserRole.ADMIN.toString() && r.code !== UserRole.ORAGNIZATION_ADMIN.toString()
    );
    this.accessGroups = this.data.infos?.accessGroups ? this.data.infos.accessGroups : [];
    this.availableGender = this.formsData.GENDER;
    this.availableServices = this.data.availableServices;
    this.availableOrganizations = this.data.availableOrganizations;
    this.user = this.data.user ? new Practitioner(this.data.user) : undefined;
    if (this.data.preselectedService) {
      const preOrgRef = this.availableOrganizations.find((org) => org.reference === this.data.preselectedOrganization?.reference);
      this.practitionerForm.get("organisation").setValue(preOrgRef);
      const preServicesRefs = this.availableServices.filter((s) => s.reference === this.data.preselectedService?.reference);
      this.practitionerForm.get("services").setValue(preServicesRefs);
      const defaultGroups = this.getDefaultAccessGroups(preServicesRefs);
      const groups = [...this.accessGroups, ...defaultGroups];
      this.accessGroups = Tools.uniq(groups);
    }
    this.practitionerForm
      .get("organisation")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((v: Reference) => {
        if (!this.isInit) {
          return;
        }
        this.depService
          .list(v.reference)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((services: Healthcareservice[]) => {
            this.availableServices = services.map((s) => s.asReference);
            if (this.mode === FORMS_MODE.UPDATE) {
              const services = this.availableServices.filter((s) => this.user.allServicesRefs.indexOf(s.reference) !== -1);
              this.practitionerForm.controls.services.setValue(services);
            }
          });
      });
    this.practitionerForm
      .get("services")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((v: Reference[]) => {
        if (this.isInit) {
          const prevValue = this.practitionerForm.value["services"];
          const newServicesRefs = v.filter((s) => !prevValue.includes(s));
          const defaultGroups = this.getDefaultAccessGroups(newServicesRefs);
          const groups = [...this.accessGroups, ...defaultGroups];
          this.accessGroups = Tools.uniq(groups);
        }
      });
    this.availableLangs = await this.formsData.getLanguages();
  }

  private updateForm(): void {
    if (this.mode === FORMS_MODE.CREATE) {
      return;
    }
    const orga = this.availableOrganizations.find((or) => or.reference === this.user.organization?.reference);
    const qualif = this.availableQualifications.find((q) => q.code === this.user.mainQualif?.code);
    const services = this.availableServices.filter((s) => this.user.allServicesRefs.indexOf(s.reference) !== -1);
    const gender = this.availableGender.find((g) => g.code === this.user.gender)?.code;
    const role = this.availableRoles.find((r) => r.code === this.data.infos.role?.[0]?.toString())?.code;
    const lang = this.availableLangs.find((l) => l.code === this.user.mainLang)?.code;
    this.isActive = this.user.active;
    this.practitionerForm.controls.name.setValue(this.user.familyName);
    this.practitionerForm.controls.firstname.setValue(this.user.firstname);
    this.practitionerForm.controls.role.setValue(role);
    this.practitionerForm.controls.title.setValue(this.transformPrefixToValidi18nKey(this.user.prefix));
    this.practitionerForm.controls.birthDate.setValue(this.user.birthDate);
    this.practitionerForm.controls.qualif.setValue(qualif);
    this.practitionerForm.controls.organisation.setValue(orga);
    this.practitionerForm.controls.services.setValue(services);
    this.practitionerForm.controls.inami.setValue(this.user.inamiNbr);
    this.practitionerForm.controls.nationalNumber.setValue(this.data.infos.nationalnumber);
    this.practitionerForm.controls.userLang.setValue(lang);
    this.practitionerForm.controls.gender.setValue(gender);
    this.practitionerForm.controls.msSante.setValue(this.getMsSante());
    this.practitionerForm.controls.internalId.setValue(this.user.internalId);
    if (this.user.phoneNbr.length) {
      this.user.phoneNbr.forEach((v, index) => {
        this.addPhoneControl(v, index);
      });
    } else {
      this.addPhone(false, 0);
    }

    if (this.user.mailAdress.length) {
      this.user.mailAdress.forEach((v, index) => {
        this.addMailControl(v, index);
      });
    } else {
      this.addMail(false, 0);
    }
    this.ansId = FHIRHelper.getAnsId(this.data?.user?.identifier);
    this.ansPractitionerRole = this.data?.user?.practitionerRole.slice(1);
    this.ansRole = this.data?.user?.ansRole;

    this.practitionerForm.markAsPristine();
    this.practitionerForm.markAsUntouched();
  }

  /**
   * Add the default access groups' ids from the selected services to the users.
   * If there are services refs in the parameters, it will use those rather than the selected ones.
   * If there are no default access groups in the service, it will take the default access groups from the
   * organization.
   * @param servicesRefs (optional) the services from which we want the default access groups' ids
   * @returns
   */
  private getDefaultAccessGroups(servicesRefs?: Reference[]): string[] {
    this.practitionerAccessGroupComponent?.save();
    const selectedServicesRefs: string[] = servicesRefs
      ? servicesRefs.map((s) => s.reference)
      : this.practitionerForm.get("services").value.map((s) => s.reference);
    const selectedServices = [
      ...this.userService.allServices.filter((s) => selectedServicesRefs.includes(s.asReference.reference)),
      ...this.userService.allMonitoringServices.filter((s) => selectedServicesRefs.includes(s.asReference.reference)),
    ];
    const defaultAccessGroups: string[] = [];
    for (const service of selectedServices) {
      if (service.defaultAccessGroups?.length) {
        defaultAccessGroups.push(...service.defaultAccessGroups);
      } else {
        const org = this.userService.allOrganizations.find((o) => o.asReference.reference === service.organizationRef);
        if (org && org.defaultAccessGroups) {
          defaultAccessGroups.push(...org.defaultAccessGroups);
        }
      }
    }
    return Tools.uniq(defaultAccessGroups);
  }

  public async apply(addAnotherUser = false): Promise<void> {
    if (!this.practitionerForm.valid) {
      this.practitionerForm.markAsTouched();
      this.practitionerForm.markAsDirty();
      return;
    }
    this.practitionerAccessGroupComponent.save();
    const result: AddPractitionerData = {
      ...this.practitionerForm.getRawValue(),
      needMail: this.needMail,
      user: this.user,
      active: this.isActive,
      accessGroups: this.accessGroups,
      ansId: this.ansId,
      ansPractitionerRole: this.ansPractitionerRole,
      ansRole: this.ansRole,
    };
    if (this.mode === FORMS_MODE.UPDATE) {
      this.practitionerService.update(result).subscribe(
        () => {
          this.translateService.get("common.saveSuccess").subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 10000 });
          });
        },
        (err) => {
          FileLogger.error("UserPageComponent", "Error while updating user", err);
          this.translateService.get("common.saveFail").subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 10000 });
          });
        },
        () => {
          this.router.navigate([this.previousPage]);
        }
      );
    } else if (this.mode === FORMS_MODE.CREATE) {
      this.practitionerService.create(result).subscribe(
        () => {
          this.translateService.get("common.saveSuccess").subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 10000 });
          });
        },
        (err) => {
          if (err.message === "ALREADY EXISTS") {
            const alreadyExistsi18n = "dialog.practitionerAlreadyExists.databaseExistingMailPractitioner";
            this.snackBar.open(this.translateService.instant(alreadyExistsi18n), "ok", { duration: 10000 });
          } else {
            this.snackBar.open(this.translateService.instant("common.saveFail"), "ok", { duration: 10000 });
          }
        },
        () => {
          if (!addAnotherUser) {
            this.router.navigate([this.previousPage]);
          } else {
            this.prepareForNewUser();
          }
        }
      );
    }
  }

  private addPhoneControl(v: Identifier, index: number): void {
    this.practitionerForm.addControl(
      `phone${index}`,
      this.fb.control(
        v.value,
        index === 0 ? [Validators.required, this.phoneValidator, Validators.pattern("[0-9+]*")] : this.phoneValidator
      )
    );
    this.practitionerForm.addControl(`phoneComment${index}`, this.fb.control(v.label));
  }

  private addMailControl(v: Identifier, index: number): void {
    this.practitionerForm.addControl(
      `mail${index}`,
      this.fb.control(v.value, index === 0 ? [Validators.required, Validators.email] : Validators.email)
    );
    this.practitionerForm.addControl(`mailComment${index}`, this.fb.control(v.label));
  }

  public addPhone(fromTemplate: boolean, index?: number): void {
    index = fromTemplate ? this.user.phoneNbr.length : index;
    this.addPhoneControl(this.getTemplate(TELECOM_SYSTEM.PHONE), index);
    this.user.addTelecom(this.getTemplate(TELECOM_SYSTEM.PHONE));
  }

  public addMail(fromTemplate: boolean, index?: number): void {
    index = fromTemplate ? this.user.mailAdress.length : index;
    this.addMailControl(this.getTemplate(TELECOM_SYSTEM.MAIL), index);
    this.user.addTelecom(this.getTemplate(TELECOM_SYSTEM.MAIL));
  }

  public removeMail(index: number): void {
    if (!this.practitionerForm.contains(`mail${index}`)) {
      return;
    }
    this.user.removeTelecom(TELECOM_SYSTEM.MAIL);
    this.practitionerForm.removeControl(`mail${index}`);
    this.practitionerForm.removeControl(`mailComment${index}`);
  }

  public removePhone(index: number): void {
    if (!this.practitionerForm.contains(`phone${index}`)) {
      return;
    }
    this.user.removeTelecom(TELECOM_SYSTEM.PHONE);
    this.practitionerForm.removeControl(`phone${index}`);
    this.practitionerForm.removeControl(`phoneComment${index}`);
  }

  private getTemplate(type: TELECOM_SYSTEM): Identifier {
    return {
      value: null,
      use: null,
      label: null,
      system: type,
    };
  }

  public phoneValidator(control: AbstractControl): { [key: string]: true } | null {
    return Tools.phoneValidator(control);
  }

  public generatePassword(): void {
    this.practitionerForm.get("activationCode").setValue(Tools.generatePassword(8));
  }

  private transformPrefixToValidi18nKey(prefix: string): string {
    if (prefix === "Dr." || prefix === "DR.") {
      return this.translateService.instant("forms.title.doctor.short");
    } else if (prefix === "Mme" || prefix === "Mrs." || prefix === "Frau" || prefix === "Mw") {
      return this.translateService.instant("forms.title.miss.short");
    } else if (prefix === "M." || prefix === "Herr.") {
      return this.translateService.instant("forms.title.mister.short");
    } else if (prefix === "Pr" || prefix === "Pr.") {
      return this.translateService.instant("forms.title.professor.short");
    } else {
      return prefix;
    }
  }

  private checkUsersPermissions(): void {
    this.canReadGroups = this.userService.isAuthorizedSync(null, this.accessGroupApiService.readRoutes[0], "GET");
    this.canReadPermissions = this.userService.isAuthorizedSync(null, this.accessGroupApiService.readRoutes[1], "GET");
    this.canCreateGroup = this.userService.isAuthorizedSync(null, this.accessGroupApiService.createRoutes[0], "POST");
  }

  public onSearchAns(isMsSante = false): void {
    const dialogRef = this.dialog.open(AnsPractitionnerResultComponent, {
      data: {
        given: this.ansForm.get("ansGiven").value,
        family: this.ansForm.get("ansFamily").value,
        msSante: isMsSante ? this.ansForm.get("msSanteSearch").value : undefined,
      },
    });
    dialogRef.afterClosed().subscribe((result: IPractitionerInfo) => {
      if (result) {
        this.setAnsValue(result);
      }
    });
  }

  private setAnsValue(p: IPractitionerInfo): void {
    this.ansFields.forEach((f) => {
      const fieldForm = this.practitionerForm.get(f);
      if (f === "inami") {
        f = "practitionerReferenceNumber";
      }
      if (f === "userLang") {
        f = "language";
      }

      if (f === "msSante" && p?.telecom?.value) {
        this.showMsSante = true;
        fieldForm.setValue(p.telecom.value);
        fieldForm.disable();
      }

      if (Tools.isDefined(p[f])) {
        fieldForm.setValue(p[f]);
        fieldForm.disable();
      }
    });
    this.ansId = p.identifier;
    if (p.practitionerRole) {
      this.ansPractitionerRole = p.practitionerRole;
    }
    if (p.ansRole) {
      this.ansRole = p.ansRole;
    }
  }

  private isPreselectedAndFrService(): boolean {
    if (this.data.preselectedService) {
      const service = this.userService.allServices.find((s) => {
        return s.asReference.reference === this.data.preselectedService.reference;
      });
      return service?.location?.country === this.sessionService.FRANCE;
    } else {
      return true;
    }
  }
  private isPreselectedAndBEService(): boolean {
    if (this.data.preselectedService) {
      const service = this.userService.allServices.find((s) => {
        return s.asReference.reference === this.data.preselectedService.reference;
      });
      return service?.location?.country === this.sessionService.BELGIUM;
    } else {
      return false;
    }
  }

  private disableAnsFields(): void {
    this.ansFields.forEach((f) => {
      const fieldForm = this.practitionerForm.get(f);
      fieldForm.disable();
    });
  }

  private getMsSante(): string {
    const msSante = this.data.user.telecom.find((t) => {
      return t.system === TELECOM_SYSTEM.MSSANTE;
    });
    if (msSante?.value) {
      this.showMsSante = true;
      return msSante.value;
    }
    return undefined;
  }

  public preventCharacter(event: KeyboardEvent, characters: PREVENTCHARACTER[], formControlName?: string): void {
    // we need this timeout because the replacement must be after the change of the input value
    setTimeout(() => {
      const value = PreventCharacter.preventMultiple(event, characters);
      this.practitionerForm.get(formControlName).setValue(value);
    }, 0);
  }
}
