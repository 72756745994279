import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Scoring } from "src/app/models/questionnaireScoring.model";

export class QRDataSource extends MatTableDataSourceExtended<Scoring> {
  sortingDataAccessor = (data: Scoring, sortHeaderId: string): number | string => {
    switch (sortHeaderId) {
      case "name":
        return data.questionnaireResponse.identifier.value;
      case "date":
        return moment(data.questionnaireResponse.authored).unix();
      case "status":
        return data.questionnaireResponse.status;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(QR: Scoring[]): void {
    this.data = QR;
  }
}
