import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EntityDrug } from "../models/entity.interface";

@Pipe({ name: "displayQuantity" })
export class DisplayQuantityPipe implements PipeTransform {
  public transform(quantity: string): string {
    return EntityDrug.computeQuantityDisplay(quantity);
  }
}

@Pipe({ name: "displayTextForQuantity" })
export class DisplayTextForQuantityPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(quantity: string): string {
    if (Number.isFinite(Number(quantity))) {
      return this.translateService.instant("drugDetails.plannedQuantity");
    } else {
      return this.translateService.instant("mydrugs.indication");
    }
  }
}
