<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn" aria-label="Close dialog" matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <h4>{{ "pdf.questionnaire.export.title" | translate }} :</h4>
    <div class="btn-container">
      <button mat-raised-button (click)="onExportPDF(false)" style="margin-right: 0.5em">
        {{ "pdf.questionnaire.export.list" | translate }}
      </button>
      <button mat-raised-button (click)="onExportPDF(true)">
        {{ "pdf.questionnaire.export.table" | translate }}
      </button>
    </div>
  </div>
</div>
