<div class="ui-dialog-container" style="position: relative">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <!-- Only show stepper if more than one slide -->

    <mat-horizontal-stepper *ngIf="!isOnlyOnePage()" #stepper>
      <mat-step *ngFor="let slide of slides">
        <h2>{{ slide.title | translate }}</h2>
        <!-- Loop on descriptions and keep index to get image -->
        <div *ngFor="let description of slide.descriptions; let i = index">
          <p [innerHTML]="description | translate : { cgu: ('page.login.cgu' | translate), link: ('page.login.cguLink' | translate) }"></p>
          <br />
          <img
            class="helpImg"
            [src]="slide.images[i]?.path"
            [ngStyle]="{
              height: (responsiveService.isHandset$ | async) === false ? slide.images[i]?.height : 'auto',
              width: (responsiveService.isHandset$ | async) === false ? slide.images[i]?.width : 'auto',
              maxWidth: '100%'
            }"
          />
          <br />
        </div>
        <!-- Only show more images if necessary -->
        <div *ngIf="isMoreImageThanDescription(slide)">
          <!-- Loop on images not show yet in previous div -->
          <div *ngFor="let image of getMissingImages(slide)">
            <img
              class="helpImg"
              [src]="image.path"
              [ngStyle]="{
                height: (responsiveService.isHandset$ | async) === false ? image.height : 'auto',
                width: (responsiveService.isHandset$ | async) === false ? image.width : 'auto',
                maxWidth: '100%'
              }"
            />
          </div>
        </div>
      </mat-step>
      <!-- Icon overrides. -->
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
    </mat-horizontal-stepper>
    <!-- Only show this div if one slide -->
    <div *ngIf="isOnlyOnePage()">
      <h2>{{ slides[0].title | translate }}</h2>
      <!-- Loop on descriptions and keep index to get image -->
      <div *ngFor="let description of slides[0].descriptions; let i = index">
        <p>{{ description | translate }}</p>
        <br />
        <img
          class="helpImg"
          [src]="slides[0]?.images[i]?.path"
          [ngStyle]="{
            height: (responsiveService.isHandset$ | async) === false ? slides[0]?.images[i]?.height : 'auto',
            width: (responsiveService.isHandset$ | async) === false ? slides[0]?.images[i]?.width : 'auto',
            maxWidth: '100%'
          }"
        />
        <br />
      </div>
      <!-- Only show more images if necessary -->
      <div *ngIf="isMoreImageThanDescription(slides[0])">
        <!-- Loop on images not show yet in previous div -->
        <div *ngFor="let image of getMissingImages(slides[0])">
          <img
            class="helpImg"
            [src]="image.path"
            [ngStyle]="{
              height: (responsiveService.isHandset$ | async) === false ? image.height : 'auto',
              width: (responsiveService.isHandset$ | async) === false ? image.width : 'auto',
              maxWidth: '100%'
            }"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="ui-dialog-actions">
    <button mat-button *ngIf="!isFirstSlide && slides?.length > 1" (click)="onPreviousSlide()">
      {{ "globalHelp.backSlide" | translate }}
    </button>
    <button mat-button class="next-btn" *ngIf="!isLastSlide && slides?.length > 1" (click)="onNextSlide()">
      {{ "globalHelp.nextSlide" | translate }}
    </button>
    <button mat-button mat-dialog-close *ngIf="isLastSlide">
      {{ "globalHelp.endSlide" | translate }}
    </button>
  </div>
</div>
