<app-patient-infos [patientUserSet]="patientUser"></app-patient-infos>
<mat-drawer-container class="patient-page" id="patientPageContainer">
  <mat-drawer #widget mode="side" opened="false" position="start">
    <ng-template appWidget></ng-template>
  </mat-drawer>
  <button
    type="button"
    mat-raised-button
    color="primary"
    (click)="widget.toggle(); recordOpenPatientListWidget()"
    *ngIf="widgetName && (responsiveService.isHandset$ | async) === false"
    matTooltip="{{ 'widget.' + widgetName | translate }}"
    class="widget-toggle-btn"
  >
    <mat-icon>vertical_split</mat-icon>
  </button>
  <div class="loading" *ngIf="!pageLoaded">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    {{ "common.loading" | translate }}
  </div>
  <div class="noneWidgetSelected" *ngIf="noneWidgetSelected">
    <h2>{{ "page.patient.emptyPageTitle" | translate }}</h2>
    <p>{{ "page.patient.emptyPageText" | translate }}</p>
  </div>
  <div [ngStyle]="{ visibility: pageLoaded ? 'visible' : 'hidden' }" class="container">
    <div class="sub-container" [ngClass]="{ handset: responsiveService.isHandset$ | async }">
      <div *ngFor="let availableTile of selectedWidgetPatientListLeft">
        <app-patient-careplans
          *ngIf="('careplans' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'careplans'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-careplans>
        <app-patient-drugs
          *ngIf="('entitylinks' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'drugs'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-drugs>
        <app-patient-vital-signs
          *ngIf="
            ('dashboard/vitalProfileDefinitions' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'vitalSigns'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-vital-signs>
        <app-patient-teleconsultations
          *ngIf="
            ('patientsAppointments' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'teleConsultations'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-teleconsultations>
        <app-patient-risk-assessment
          *ngIf="
            ('riskAssessments' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'riskAssessment'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-risk-assessment>
        <app-patient-observations
          *ngIf="('observations' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'observations'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-observations>
        <app-patient-qr
          class="card"
          *ngIf="
            ('questionnaires' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'questionnaires'
          "
          [patientUser]="patientUser"
        ></app-patient-qr>
        <app-patient-alerts
          *ngIf="('rulealerts' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'alerts'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-alerts>
        <app-patient-communications
          *ngIf="
            ('dashboard/communications' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'communications'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-communications>
        <app-patient-reward
          *ngIf="
            ('rewardScoresByPatient' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'rewards'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-reward>
        <app-patient-documents
          *ngIf="
            ('dashboard/documentsInfos' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'documents' &&
            patientUser
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-documents>
      </div>
    </div>
    <div class="sub-container" [ngClass]="{ handset: responsiveService.isHandset$ | async }">
      <div *ngFor="let availableTile of selectedWidgetPatientListRight">
        <app-patient-careplans
          *ngIf="('careplans' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'careplans'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-careplans>
        <app-patient-drugs
          *ngIf="('entitylinks' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'drugs'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-drugs>
        <app-patient-vital-signs
          *ngIf="
            ('dashboard/vitalProfileDefinitions' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'vitalSigns'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-vital-signs>
        <app-patient-teleconsultations
          *ngIf="
            ('patientsAppointments' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'teleConsultations'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-teleconsultations>
        <app-patient-risk-assessment
          *ngIf="
            ('riskAssessments' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'riskAssessment'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-risk-assessment>
        <app-patient-observations
          *ngIf="('observations' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'observations'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-observations>
        <app-patient-qr
          class="card"
          *ngIf="
            ('questionnaires' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'questionnaires'
          "
          [patientUser]="patientUser"
        ></app-patient-qr>
        <app-patient-alerts
          *ngIf="('rulealerts' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'alerts'"
          class="card"
          [patientUser]="patientUser"
        ></app-patient-alerts>
        <app-patient-communications
          *ngIf="
            ('dashboard/communications' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'communications'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-communications>
        <app-patient-reward
          *ngIf="
            ('rewardScoresByPatient' | isAuthorized | async) === true && visibleWidgets.get(availableTile) && availableTile === 'rewards'
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-reward>
        <app-patient-documents
          *ngIf="
            ('dashboard/documentsInfos' | isAuthorized | async) === true &&
            visibleWidgets.get(availableTile) &&
            availableTile === 'documents' &&
            patientUser
          "
          class="card"
          [patientUser]="patientUser"
        ></app-patient-documents>
      </div>
    </div>
  </div>
</mat-drawer-container>
