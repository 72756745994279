import { Injectable } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";
import { AppError } from "../models/app-error.interface";
import { ExportApiService } from "./api/export-api.service";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor(private exportApiService: ExportApiService) {}

  public async exportAlerts(patientId: string, lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.exportApiService.exportAlerts(patientId, lang).subscribe(
        (blob) => {
          const url = URL.createObjectURL(blob);
          window.open(url);
          resolve();
        },
        (error: AppError) => {
          FileLogger.error("ExportService", "exportAlerts", error);
          reject(error.message);
        }
      );
    });
  }
}
