<mat-card class="widget">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "vitalSigns.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openRefValueHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>
    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="responsiveService.isHandset$ | async"
      (actionTriggered)="handleAction($event.action)"
    ></app-widget-actions>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ 'mb-0': isMobile }">
    <form [formGroup]="formValue">
      <table mat-table matSort matSortDirection="asc" [dataSource]="dataSource" [ngClass]="{ hide: dataSource?.data?.length === 0 }">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.name" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.name' | translate }}"
              propertyName="translatedName"
              [currentLang]="currentLang"
              [dataType]="dataTypeChoice"
              [data]="dataSource.data"
              [defaultValue]="getFilter('translatedName')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="custom-icon-small" *ngIf="element.isHidden" matTooltip="{{ 'vitalSigns.hiddenForMobile' | translate }}">
              mobile_off
            </mat-icon>
            {{
              (element.def?.nameDashboard ? element.def.nameDashboard : element.def?.nameMobile ? element.def.nameMobile : null)
                | getTranslation : element.code : currentLang
            }}
          </td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.value" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [ngSwitch]="element.def?.type">
            <ng-container *ngSwitchCase="'number'">
              <span *ngIf="element.code !== editElementCode" (click)="editElementCode = element.code">{{
                element.value ? element.value : 0
              }}</span>
              <mat-form-field *ngIf="element.code === editElementCode">
                <input matInput [formControlName]="editElementCode" (keydown.enter)="save(element)" />
                <mat-error *ngIf="formValue.get(element.code).hasError('valueValidator')">
                  {{ "forms.checkValueFormat" | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'array'">
              <button mat-icon-button matTooltip="{{ 'vitalSigns.viewArrayTooltip' | translate }}" (click)="openValueArrayDialog(element)">
                <i class="fas fa-table"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <!-- unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.unit" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.def?.unit | getTranslation : "" : currentLang }}
          </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.action" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [ngSwitch]="element.def?.type">
            <ng-container *ngSwitchCase="'number'">
              <button
                *ngIf="element.code !== editElementCode && ('vitalSign' | isAuthorized : 'PUT' | async) === true"
                class="custom-small-btn"
                mat-icon-button
                matTooltip="{{ 'btn.update' | translate }}"
                (click)="!editElementCode ? (editElementCode = element.code) : null"
                aria-label="icon button with a create icon"
              >
                <mat-icon class="custom-icon-small baseline-align warn">create</mat-icon>
              </button>
              <button
                *ngIf="element.code === editElementCode"
                [disabled]="formValue.get(element.code).hasError('valueValidator')"
                class="custom-small-btn"
                mat-icon-button
                matTooltip="{{ 'btn.save' | translate }}"
                (click)="save(element)"
                aria-label="icon button with a loupe icon"
              >
                <mat-icon
                  class="custom-icon-small baseline-align"
                  [ngClass]="formValue.get(element.code).hasError('valueValidator') ? 'mid-alpha' : 'success'"
                  >done
                </mat-icon>
              </button>
              <button
                *ngIf="element.code === editElementCode"
                class="custom-small-btn"
                mat-icon-button
                matTooltip="{{ 'btn.cancel' | translate }}"
                (click)="editElementCode = undefined"
                aria-label="icon button with a cancel icon"
              >
                <mat-icon class="custom-icon-small baseline-align fail">cancel</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </form>
  </mat-card-content>
  <mat-card-footer class="m-0">
    <mat-paginator
      [ngClass]="{ hide: dataSource?.data?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10]"
      [hidePageSize]="isMobile"
    >
    </mat-paginator>
  </mat-card-footer>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="dataSource?.data?.length === 0">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
