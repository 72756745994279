import { Component, Inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { AppError, ERROR_MSG } from "src/app/models/app-error.interface";
import { RiskAssessmentService } from "src/app/providers/risk-assessments-api.service";
import { UiFileDropComponent } from "../../shared/ui-file-drop/ui-file-drop.component";

@Component({
  selector: "app-prediction-model-import",
  templateUrl: "./prediction-model-import.component.html",
  styleUrls: ["./prediction-model-import.component.scss"],
})
export class PredictionModelImportComponent {
  @ViewChild("uiFileDrop", { static: false }) uiFileDrop: UiFileDropComponent;
  public hasError = false;
  public isSaving = false;
  public predictionModelForm: UntypedFormGroup = this.fb.group({
    name: new UntypedFormControl({ value: undefined, disabled: true }, Validators.required),
    file: new UntypedFormControl(undefined, Validators.required),
    caremateId: new UntypedFormControl(undefined, Validators.required),
    model: new UntypedFormControl("topmd", Validators.required),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private raService: RiskAssessmentService,
    private dialogRef: MatDialogRef<PredictionModelImportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string }
  ) {}

  public setFile(file: File): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      try {
        this.predictionModelForm.get("name").setValue(file.name);
        this.predictionModelForm.get("file").setValue(file);
        this.predictionModelForm.get("caremateId").setValue(this.data.patientId);
      } catch (error) {
        this.hasError = true;
        this.snackBar.open(`❗${this.translateService.instant("prediction.import.fileErrors.invalidFormat")}`, "OK", {
          duration: 5000,
        });
        FileLogger.error("PredictionModelImportComponent", `Error while importing the prediction model file.`);
      }
    };
    fileReader.readAsText(file);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    this.isSaving = true;
    this.predictionModelForm.markAllAsTouched();
    if (this.predictionModelForm.invalid) {
      this.isSaving = false;
      return;
    }

    const formData = new FormData();
    const formValues = this.predictionModelForm.getRawValue();

    for (const key of Object.keys(formValues)) {
      if (this.predictionModelForm.get(key).value) {
        formData.append(key, this.predictionModelForm.get(key).value);
      }
    }

    this.raService
      .importPredictionFile(formData)
      .pipe(
        catchError((error: AppError) => {
          if (error.message === ERROR_MSG.INVALID_DATA) {
            this.snackBar.open(`❗${this.translateService.instant("prediction.import.fileErrors.invalidCsvFormat")}`, "OK", {
              duration: 5000,
            });
          } else if (error.message === ERROR_MSG.ALREADY_EXISTS) {
            this.snackBar.open(`❗${this.translateService.instant("prediction.import.fileErrors.alreadyExists")}`, "OK", {
              duration: 5000,
            });
          }
          FileLogger.error("PredictionModelImportComponent", "Error while importing the prediction file", error.message);
          return of(false);
        }),
        first()
      )
      .subscribe((result) => {
        if (result !== false) {
          this.snackBar.open(`✅ ${this.translateService.instant("common.success")}`, undefined, {
            duration: 3000,
          });
          this.dialogRef.close(true);
        } else {
          this.isSaving = false;
          return;
        }
      });
  }
}
