<div class="buttons-bar">
  <button mat-icon-button color="warn" aria-label="Close Dialog" mat-dialog-close class="negative-margin">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="camera-container">
  <!-- Video frame or image -->
  <div *ngIf="!imageCaptured">
    <video #videoElement autoplay playsinline></video>
  </div>
  <div *ngIf="imageCaptured">
    <img [src]="capturedImage" alt="Captured" />
  </div>

  <!-- Floating buttons -->

  <div class="floating-buttons">
    <div class="btn-spacer" *ngIf="!imageCaptured"></div>
    <!-- Button to capture an image -->
    <button mat-fab color="accent" aria-label="Capture Image" *ngIf="!imageCaptured" (click)="captureImage()">
      <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-camera"></mat-icon>
    </button>

    <!-- Button to reset (only if an image is captured) -->
    <button mat-mini-fab color="basic" aria-label="Reset" *ngIf="imageCaptured" (click)="reset()">
      <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-undo"></mat-icon>
    </button>

    <!-- Button to validate and close the modal (only if an image is captured) -->
    <button mat-fab color="accent" aria-label="Validate" *ngIf="imageCaptured" (click)="validate()">
      <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-check"></mat-icon>
    </button>

    <div class="btn-spacer" *ngIf="imageCaptured"></div>

    <!-- Button to switch camera -->
    <button mat-mini-fab color="basic" aria-label="Switch Camera" *ngIf="!imageCaptured" (click)="switchCamera()">
      <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-sync-alt"></mat-icon>
    </button>
  </div>
  <div class="flash" *ngIf="isFlashVisible"></div>
</div>
