<div class="close-cross">
  <button mat-icon-button mat-dialog-close class="negative-margin">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="CareplanKnowledgesModalContainer">
  <div *ngIf="isLoading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <h3 matLine>
    {{ data.drug.entityData.prescriptionName ?? data.drug.entityData.name }}
  </h3>
  <form [formGroup]="form" *ngIf="!isLoading" style="display: flex; flex-direction: column">
    <div style="flex-direction: row">
      <label>{{ "drugIntake.administered" | translate }} : </label>
      <mat-radio-group formControlName="intakeValidation">
        <mat-radio-button style="margin-left: 5px" [value]="true">{{ "common.yes" | translate }}</mat-radio-button>
        <mat-radio-button style="margin-left: 5px" [value]="false">{{ "common.no" | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="flex-direction: row; margin-bottom: 1em" *ngIf="data.quantity">
      <label>{{ data.quantity | displayTextForQuantity }} : {{ data.quantity | displayQuantity }}</label>
    </div>

    <mat-form-field class="mat-field" class="quantityContainer" *ngIf="form.controls.intakeValidation.value === true">
      <mat-select
        placeholder="{{ 'drugDetails.administeredQuantity' | translate }}"
        formControlName="quantityTaken"
        [id]="'quantitySelect'"
        matTooltip="{{ 'mydrugs.quantityTooltip' | translate }}"
        matTooltipPosition="right"
        matTooltipHideDelay="1000"
      >
        <mat-option style="width: 75px">
          <ngx-mat-select-search
            placeholderLabel="{{ 'mydrugs.dose' | translate }}"
            formControlName="quantityTaken"
            [clearSearchInput]="false"
            hideClearSearchButton="true"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let quantity of availableQuantities | async" [value]="quantity">
          {{ quantity }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="timeTakenContainer">
      <mat-label>{{ "drugDetails.time" | translate }} :</mat-label>
      <ngx-timepicker-field
        [format]="24"
        formControlName="time"
        [controlOnly]="true"
        [defaultTime]="form.controls.time.value"
        style="padding-bottom: 1em"
      ></ngx-timepicker-field>
    </div>
    <mat-form-field>
      <textarea cdkTextareaAutosize matInput placeholder="{{ 'forms.addComment' | translate }}" formControlName="comment"></textarea>
    </mat-form-field>
  </form>
  <button mat-flat-button color="primary" [matTooltip]="'btn.save' | translate" (click)="save()">
    {{ "btn.save" | translate }}
  </button>
</mat-dialog-content>
