import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { AddDrugSchemaComponent } from "src/app/components/forms/add-drug-schema/add-drug-schema.component";
import { AddStepwiseDrugComponent } from "src/app/components/forms/add-stepwise-drug/add-stepwise-drug.component";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { IDrugSchema } from "src/app/models/drugSchema.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { IStepwise, IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { StepwiseDrugService } from "src/app/providers/stepwiseDrug.service";
import { UserService } from "src/app/providers/user.service";
import { DrugSchemaDataSource } from "./drug-schema-datasource";

@Component({
  selector: "app-drug-schema-page",
  templateUrl: "./drug-schema-page.component.html",
  styleUrls: ["./drug-schema-page.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class DrugSchemaPageComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild("MatPaginatorMain") paginator: MatPaginator;
  @ViewChild("MatSortMain") sort: MatSort;
  @ViewChild("MatTableMain") table: MatTable<IDrugSchema | IStepwiseDrug>;
  public dataSource: DrugSchemaDataSource;
  public columnsToDisplay = ["visibility", "name", "drugs", "modified", "author", "duration", "action"];
  public expandedElement = null;
  public expandedArray: number[] = [];
  public actionOnMultiple = false;
  public get displayedColumns(): string[] {
    return ["expand", ...this.columnsToDisplay];
  }
  public rowOfDisplayedColumns: string[][] = [];
  // Filter
  public cycleFilters: Filter[] = [];
  public stepwiseFilters: Filter[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public currentPageSize: number;
  public isCycleSelected = true;
  // Refresh page
  public refreshSubLoading: Subscription;
  public isAuthSub: Subscription;
  public refreshSub = this.sessionService.refreshDrugSchemaList.subscribe(() => {
    this.loadData();
    this.refreshSubLoading = this.dataSource.loading$.subscribe();
  });
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    public sessionService: SessionService,
    private userService: UserService,
    private drugSchemaService: DrugSchemaService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private helpData: HelpData,
    private stepwiseDrugService: StepwiseDrugService,
    private preferenceService: PreferenceService
  ) {
    this.loadPreferences();
  }

  ngOnInit(): void {
    this.dataSource = new DrugSchemaDataSource(this.drugSchemaService, this.stepwiseDrugService);
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.loadPreferences();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });
  }

  ngOnDestroy(): void {
    this.dataSource.clear();
    this.refreshSub.unsubscribe();
    this.refreshSubLoading?.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData() {
    this.isAuthSub?.unsubscribe();
    if (this.isCycleSelected) {
      this.isAuthSub = this.userService
        .isAuthorized("dashboard/drugSchema", "GET")
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isAuth) => {
          if (isAuth) {
            this.dataSource.loadSchemas(true);
          }
        });
    } else {
      this.isAuthSub = this.userService
        .isAuthorized("dashboard/stepwiseDrug", "GET")
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isAuth) => {
          if (isAuth) {
            this.dataSource.loadSchemas(false);
          }
        });
    }
  }

  public createNewSchema(): void {
    if (this.isCycleSelected) {
      const dialogRef = this.dialog.open(AddDrugSchemaComponent, {
        data: {
          mode: FORMS_MODE.CREATE,
          isMenu: true,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.sessionService.needRefreshDrugSchemaList();
          }
        });
    } else {
      const dialogRef = this.dialog.open(AddStepwiseDrugComponent, {
        data: {
          mode: FORMS_MODE.CREATE,
          isMenu: true,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.sessionService.needRefreshDrugSchemaList();
          }
        });
    }
  }

  public updateSchema(schema: IDrugSchema | IStepwiseDrug): void {
    if (this.isCycleSelected) {
      this.dialog
        .open(AddDrugSchemaComponent, {
          data: {
            mode: FORMS_MODE.UPDATE,
            cycleSchema: schema,
            isMenu: true,
          },
          disableClose: true,
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.sessionService.needRefreshDrugSchemaList();
          }
        });
    } else {
      this.dialog
        .open(AddStepwiseDrugComponent, {
          data: {
            mode: FORMS_MODE.UPDATE,
            stepwiseDrug: schema,
            isMenu: true,
          },
          disableClose: true,
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.sessionService.needRefreshDrugSchemaList();
          }
        });
    }
  }

  public deleteSchema(id: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.drugSchema.askDeleteSchema"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((yes) => {
        if (yes) {
          if (this.isCycleSelected) {
            this.drugSchemaService
              .delete(id)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.sessionService.needRefreshDrugSchemaList();
              });
          } else {
            this.stepwiseDrugService
              .delete(id)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.sessionService.needRefreshDrugSchemaList();
              });
          }
        }
      });
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(withPrefUpdate = true): void {
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
    if (withPrefUpdate) {
      this.updatePreference();
    }
  }

  public updateSearch(value?: string): void {
    this.globalSearchValue = value;
    this.dataSource.setFilter({
      data: value,
      propertyName: "globalSearch",
      dataType: DataType.COMBINED,
      combinedProperties: ["name"],
    });
  }

  private formatDisplayedColumns(schema: boolean[] | IStepwise[]) {
    if (schema) {
      const length =
        typeof schema[0] === "boolean"
          ? (schema as boolean[]).length
          : (schema[schema.length - 1] as IStepwise).startDay + (schema[schema.length - 1] as IStepwise).days.length;
      const columns = [];
      for (let i = 0; i < length; i++) {
        columns.push(i.toString());
      }
      this.rowOfDisplayedColumns = [];
      let value = columns.length / 31;
      if (value % 1 > 0) {
        value++;
      }
      value = Math.floor(value);
      for (let i = 0; i < value; i++) {
        this.rowOfDisplayedColumns.push([]);
        columns.forEach((index) => {
          if (+index >= 31 * i && +index < 31 * (i + 1)) {
            this.rowOfDisplayedColumns[i].push(index);
          }
        });
      }
    }
  }

  public expand(element: IDrugSchema | IStepwiseDrug): void {
    this.rowOfDisplayedColumns = [];
    this.expandedElement = this.expandedElement?._id === element?._id ? null : element;
    this.cdRef.detectChanges();
    if (element) {
      if (this.isCycleSelected) {
        this.formatDisplayedColumns((element as IDrugSchema)?.cycle);
      } else if (!this.isCycleSelected) {
        this.formatDisplayedColumns((element as IStepwiseDrug)?.stepwises);
      }
    }
  }

  public isExpand(drugSchema: IDrugSchema): boolean {
    return this.expandedElement?._id === drugSchema._id;
  }

  public openDrugSchemaHelp(): void {
    if (this.isCycleSelected) {
      this.responsiveDialog.open(
        GlobalHelpDialogComponent,
        {
          data: { slides: this.helpData.drugSchemaPageHelp },
          disableClose: true,
        },
        { maxWidth: "80vw" }
      );
    } else {
      this.responsiveDialog.open(
        GlobalHelpDialogComponent,
        {
          data: { slides: this.helpData.drugStepwiseSchemaPageHelp },
          disableClose: true,
        },
        { maxWidth: "80vw" }
      );
    }
  }

  public isCycleSelectedChanged(): void {
    this.rowOfDisplayedColumns = [];
    if (this.isCycleSelected) {
      this.columnsToDisplay = ["visibility", "name", "drugs", "modified", "author", "duration", "action"];
      this.dataSource.loadSchemas();
    } else {
      this.columnsToDisplay = ["visibility", "name", "modified", "author", "duration", "action"];
      this.dataSource.loadSchemas(false);
    }
    this.clearFilter(false);
    this.loadPreferences();
  }

  private loadPreferences() {
    this.preferenceService
      .list(this.isCycleSelected ? PreferenceContext.DRUG_SCHEMA_PAGE_CYCLE_TAB : PreferenceContext.DRUG_SCHEMA_PAGE_STEPWISE_TAB)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TableParameter) => {
        if (parameters) {
          if (parameters?.filters?.length) {
            // Apply saved filters
            parameters.filters.forEach((filter: Filter) => {
              this.applyFilter(filter);
            });
          }
          // Set page size from preference
          this.currentPageSize = parameters.itemsPerPage;
        }
      });
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: this.isCycleSelected ? PreferenceContext.DRUG_SCHEMA_PAGE_CYCLE_TAB : PreferenceContext.DRUG_SCHEMA_PAGE_STEPWISE_TAB,
        parameters: {
          filters: this.dataSource?.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isCycleSelected) {
          this.cycleFilters = this.dataSource?.getAllFilters();
        } else {
          this.stepwiseFilters = this.dataSource?.getAllFilters();
        }
      });
  }
}
