import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, ViewChild } from "@angular/core";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { HelpData } from "src/app/helpers/helpData";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";

@Component({
  selector: "app-patient-alert-red-widget",
  templateUrl: "./patient-alert-red-widget.component.html",
  styleUrls: ["./patient-alert-red-widget.component.scss"],
})
export class PatientAlertRedWidgetComponent implements IWidgetComponent {
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  public isDraggable = true;
  public widgetName = WidgetName.PATIENT_ALERT_RED;

  constructor(private responsiveDialog: ResponsiveDialogService, public helpData: HelpData) {}

  public openRedAlertHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientsRedAlert },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }
}
