<span #focus></span>
<mat-card class="widget" [ngClass]="{ big: isBig, handset: isMobile }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "drugs.title" | translate }}</p>
    <button mat-icon-button aria-label="icon button with a ? icon" (click)="openDrugHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>
    <span class="spacerBetween"></span>

    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="responsiveService.isHandset$ | async"
      (actionTriggered)="handleAction($event.action, $event.event)"
    ></app-widget-actions>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: dataSource?.data?.length === 0 }">
    <app-drug-expansion-panel
      *ngIf="(responsiveService.isHandset$ | async) && dataDrugs?.length && !intakeIsLoading"
      [data]="dataSource.data"
      [dataDrugs]="dataDrugs"
      [loading]="loading"
      [knowledgesLoaded]="knowledgesLoaded"
      [drugsKnowledges]="drugsKnowledges"
      [drugsInfo]="drugsInfo"
      [historic]="historic"
      [intakeDrugs]="intakeDrugs"
      [patientId]="pu.user.caremateIdentifier"
      (triggerShowKnowledges)="showKnowledges($event.drug, $event.docCategory)"
      (triggerUpdateDrug)="updateDrug($event)"
      (triggerDeleteDrug)="deleteDrug($event)"
      (triggerShowHistoricLocal)="showHistoricLocal($event)"
      (triggerOnPauseCycle)="OnPauseCycle($event)"
      (triggerShowMore)="showMore($event)"
    ></app-drug-expansion-panel>
    <mat-table
      matSort
      matSortDirection="asc"
      matSortActive="name"
      [dataSource]="dataSource"
      [style.display]="displayedColumns.length > 0 && (responsiveService.isHandset$ | async) === false ? 'block' : 'none'"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.name' | translate }}"
            propertyName="entityData.name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('entityData.name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.entityData.prescriptionName ?? element.entityData.name }}
          <mat-icon *ngIf="isHospital(element.entityData)" class="fail custom-icon-small bottom-align"> local_hospital </mat-icon>
          <mat-icon
            *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length"
            class="custom-icon-small bottom-align"
            matTooltip="{{ 'drugSchema.withCycle' | translate }}"
          >
            timelapse
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.DESCRIPTION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.DESCRIPTION)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.DESCRIPTION | translate"
          >
            info
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.SIDEEFFECT"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.SIDEEFFECT)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.SIDEEFFECT | translate"
          >
            error
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.RECOMMENDATION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.RECOMMENDATION)"
            [matTooltip]="'knowledgebase.doccategory.' + KNOW_DOC_CATEGORY.RECOMMENDATION | translate"
          >
            bookmark
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugInfoLink : drugsInfo"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="onURL(element.entityData)"
            [matTooltip]="'btn.moreInfo' | translate"
          >
            link
          </mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Frequency Column -->
      <ng-container matColumnDef="label">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.frequency" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.entityData | frequencyLabel }}
        </mat-cell>
      </ng-container>

      <!-- startDate Column -->
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.startDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.startDate' | translate }}"
            propertyName="entityData.frequency.boundsPeriod.start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('entityData.frequency.boundsPeriod.start')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.entityData.frequency.boundsPeriod.start | date : "dd/MM/yyyy" }}
        </mat-cell>
      </ng-container>

      <!-- endDate Column -->
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.endDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.endDate' | translate }}"
            propertyName="entityData.frequency.boundsPeriod.end"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('entityData.frequency.boundsPeriod.end')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="!isInfinite(element.entityData.frequency.boundsPeriod.end)">
            {{ element.entityData.frequency.boundsPeriod.end | date : "dd/MM/yyyy" }}
          </span>
          <mat-icon *ngIf="isInfinite(element.entityData.frequency.boundsPeriod.end)" class="custom-icon-small"> all_inclusive</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- startAndEndDate Column -->
      <ng-container matColumnDef="startAndEndDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.startAndEndDate" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.entityData.frequency.boundsPeriod.start | date : "dd/MM/yyyy" }}
          -
          {{
            !isInfinite(element.entityData.frequency.boundsPeriod.end)
              ? (element.entityData.frequency.boundsPeriod.end | date : "dd/MM/yyyy")
              : ""
          }}
          <mat-icon *ngIf="isInfinite(element.entityData.frequency.boundsPeriod.end)" class="custom-icon-small" style="display: contents">
            all_inclusive
          </mat-icon>
        </mat-cell>
      </ng-container>

      <!-- posology Column -->
      <ng-container matColumnDef="posology">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.posology" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.entityData?.frequency?.asNecessary"> {{ "forms.asNecessary" | translate }} </span>
          <div *ngIf="!element.entityData?.frequency?.asNecessary" [innerHTML]="element.entityData | timingDisplay"></div>
        </mat-cell>
      </ng-container>

      <!-- prescriptor Column -->
      <ng-container matColumnDef="prescriptor">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.prescriptor" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ getPerformerName(element.entityData.performer.reference) }}
        </mat-cell>
      </ng-container>

      <!-- comment Column -->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.comment" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.entityData?.comment }}
        </mat-cell>
      </ng-container>

      <!-- gauge Column -->
      <ng-container matColumnDef="ratioIntake">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.ratioIntake" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div
            class="gauge"
            *ngIf="element | hasIntakesData : intakeDrugs"
            matTooltip="{{ element | intakesToolTip : historic : intakeDrugs }}"
            [ngStyle]="element | intakesGauge : historic : intakeDrugs"
          ></div>
          <p *ngIf="!(element | hasIntakesData : intakeDrugs) && !intakeIsLoading">
            {{ "widget.noData" | translate }}
          </p>
          <p *ngIf="intakeIsLoading">{{ "widget.loading" | translate }}...</p>
        </mat-cell>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.update' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="updateDrug(element)"
            class="warn custom-icon-w"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-w baseline-align">create</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.removeDrug' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="deleteDrug(element)"
            class="fail custom-icon-w"
            aria-label="icon button with a trash icon"
          >
            <mat-icon class="custom-icon-w baseline-align">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.history' | translate }}"
            *ngIf="historic && historic.length"
            (click)="showHistoricLocal(element)"
            class="primary custom-icon-w"
            aria-label="icon button with a historic icon"
          >
            <mat-icon class="custom-icon-w baseline-align">history</mat-icon>
          </button>
          <button
            class="disabledButton"
            mat-icon-button
            *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length"
            [disabled]="isCycleInPause(element.entityData.cycle)"
          >
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="!isCycleInPause(element.entityData.cycle) && ('entity' | isAuthorized : 'POST' | async) === true"
              matTooltip="{{ 'drugSchema.pause' | translate }}"
              (click)="OnPauseCycle(element)"
            >
              pause
            </mat-icon>
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="isCycleInPause(element.entityData.cycle)"
              matTooltip="{{
                ('drugSchema.pauseInfo' | translate) +
                  ' ' +
                  getFormatDate(element.entityData.cycle.pauseDate[element.entityData.cycle.pauseDate.length - 1])
              }}"
            >
              play_arrow
            </mat-icon>
          </button>
          <button
            class="custom-icon-w"
            mat-icon-button
            [matTooltip]="'drugIntake.administrations' | translate"
            (click)="showMore(element)"
            aria-label="icon button with a plus icon"
          >
            <mat-icon class="custom-icon-w baseline-align">zoom_in</mat-icon>
          </button>
          <app-drug-stock
            *ngIf="element.entityData?.managedStock"
            [drugId]="element._id"
            [patientId]="pu.user.caremateIdentifier"
          ></app-drug-stock>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [ngClass]="{ hide: drugs?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="isBig ? [5, 10, 15, 20] : [5]"
      [hidePageSize]="!isBig"
    >
    </mat-paginator>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="dataSource?.data?.length === 0 && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
