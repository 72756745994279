import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import moment from "moment";
import { IDateInterval } from "../../../models/sharedInterfaces";

@Component({
  selector: "app-date-interval-selector",
  templateUrl: "./date-interval-selector.component.html",
  styleUrls: ["./date-interval-selector.component.scss"],
})
export class DateIntervalSelectorComponent implements OnInit {
  @Input() direction: "row" | "column" = "row";
  @Input() initialFromDate: moment.Moment;
  @Input() initialToDate: moment.Moment;
  @Output() changeDate: EventEmitter<IDateInterval> = new EventEmitter<IDateInterval>();

  public filterFormTable = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });
  public minToDate: moment.Moment = null;
  public maxFromDate: moment.Moment = null;
  public maxToDate: moment.Moment = null;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const fromDate = this.initialFromDate ? this.initialFromDate : moment().subtract(3, "month");
    const toDate = this.initialToDate ? this.initialToDate : moment().endOf("day");
    this.filterFormTable.patchValue({ fromDate, toDate });
    this.maxFromDate = toDate;
    this.minToDate = fromDate;
    this.maxToDate = moment().endOf("day");
    this.onChangeDate();
  }

  public onChangeFromDateTable(): void {
    this.minToDate = this.filterFormTable.get("fromDate").value;
    this.onChangeDate();
  }
  public onChangeToDateTable(): void {
    this.maxFromDate = this.filterFormTable.get("toDate").value;
    this.onChangeDate();
  }

  public onChangeDate(): void {
    if (this.filterFormTable.invalid) return;

    this.changeDate.emit({
      fromDate: this.filterFormTable.get("fromDate").value,
      toDate: this.filterFormTable.get("toDate").value,
    });
  }
}
