<form [formGroup]="ansForm">
  <h2 *ngIf="mode === MODES.CREATE">{{ "forms.createUserTitle" | translate }}</h2>
  <h2 *ngIf="mode === MODES.UPDATE">{{ "forms.updateUserTitle" | translate }}</h2>
  <button
    mat-raised-button
    *ngIf="!this.isAnsChecked && isAnsSearchAvailable"
    color="primary"
    (click)="isAnsChecked = true"
    style="margin-bottom: 10px"
  >
    {{ "forms.createUserWithAns" | translate }}
  </button>
  <fieldset *ngIf="isAnsChecked">
    {{ "forms.ansResearchTitle" | translate }}
    <div style="display: flex">
      <mat-form-field>
        <input type="text" matInput placeholder="{{ 'forms.name' | translate }}" formControlName="ansFamily" />
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput placeholder="{{ 'forms.firstname' | translate }}" formControlName="ansGiven" />
      </mat-form-field>
      <button mat-raised-button color="primary" style="height: 40px" (click)="onSearchAns()">
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field style="margin-left: 15px">
        <input type="text" matInput placeholder="{{ 'forms.msSante' | translate }}" formControlName="msSanteSearch" />
      </mat-form-field>
      <button mat-raised-button color="primary" style="height: 40px" (click)="onSearchAns(true)">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </fieldset>
</form>
<form [formGroup]="practitionerForm" (ngSubmit)="apply()">
  <div>
    <fieldset>
      <mat-checkbox [checked]="isActive" (change)="isActive = !isActive">{{ "forms.contactVisible" | translate }} </mat-checkbox>
      <div class="formblock">
        <div>
          <mat-form-field>
            <mat-label>{{ "forms.title.title" | translate }}</mat-label>
            <mat-select formControlName="title">
              <mat-option *ngFor="let title of availableTitles" [value]="title">{{ title }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              cdkFocusInitial
              type="text"
              matInput
              placeholder="{{ 'forms.name' | translate }}"
              formControlName="name"
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'name')"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.firstname' | translate }}"
              formControlName="firstname"
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'firstname')"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [matDatepicker]="birthDate" formControlName="birthDate" placeholder="{{ 'forms.birthdate' | translate }}" />
            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
            <mat-datepicker #birthDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "forms.gender" | translate }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option *ngFor="let gender of availableGender" [value]="gender.code">{{ gender.display }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ "forms.nationalNumber" | translate }}</mat-label>
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.nationalNumber' | translate }}"
              formControlName="nationalNumber"
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.specialChar], 'nationalNumber')"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "forms.userLang" | translate }}</mat-label>
            <mat-select formControlName="userLang">
              <mat-option *ngFor="let lang of availableLangs" [value]="lang.code">{{ lang.display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <div *ngFor="let item of user?.phoneNbr; let index = index">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{ 'forms.phone' | translate }}"
                [formControlName]="'phone' + index"
                (keypress)="preventCharacter($event, [PREVENTCHARACTER.letter, PREVENTCHARACTER.specialCharExceptPlus], 'phone' + index)"
              />
              <mat-error *ngIf="practitionerForm.get('phone' + index).hasError('phoneValidator')">
                {{ "forms.checkPhoneFormat" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput placeholder="{{ 'forms.comment' | translate }}" [formControlName]="'phoneComment' + index" />
            </mat-form-field>
            <button *ngIf="index === 0 && user?.phoneNbr.length < 4" mat-icon-button color="primary" type="button" (click)="addPhone(true)">
              <mat-icon>add</mat-icon>
            </button>
            <button
              *ngIf="index === user?.phoneNbr.length - 1 && index !== 0"
              mat-icon-button
              color="warn"
              type="button"
              (click)="removePhone(index)"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <div *ngFor="let item of user?.mailAdress; let index = index">
            <mat-form-field>
              <input type="text" matInput placeholder="{{ 'forms.mail' | translate }}" [formControlName]="'mail' + index" />
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput placeholder="{{ 'forms.comment' | translate }}" [formControlName]="'mailComment' + index" />
            </mat-form-field>
            <button
              *ngIf="index === 0 && user?.mailAdress.length < 4"
              mat-icon-button
              color="primary"
              type="button"
              (click)="addMail(true)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              *ngIf="index === user?.mailAdress.length - 1 && index !== 0"
              mat-icon-button
              color="warn"
              type="button"
              (click)="removeMail(index)"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </div>
        <mat-form-field *ngIf="showMsSante">
          <input type="text" matInput placeholder="{{ 'forms.msSante' | translate }}" formControlName="msSante" />
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset class="formblock">
      <div>
        <mat-form-field>
          <mat-label>{{ "forms.role" | translate }}</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of availableRoles" [value]="role.code">{{ role.display }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "forms.qualification" | translate }}</mat-label>
          <mat-select formControlName="qualif">
            <mat-option *ngFor="let qualif of availableQualifications" [value]="qualif">{{ qualif.display }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "forms.inami" | translate }}</mat-label>
          <input
            type="text"
            matInput
            placeholder="{{ 'forms.inami' | translate }}"
            formControlName="inami"
            (keypress)="preventCharacter($event, [PREVENTCHARACTER.specialChar], 'inami')"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ "forms.organisation" | translate }}</mat-label>
          <mat-select formControlName="organisation">
            <mat-option *ngFor="let organisation of availableOrganizations" [value]="organisation"> {{ organisation.display }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "forms.services" | translate }}</mat-label>
          <mat-select formControlName="services" multiple>
            <mat-option *ngFor="let service of availableServices" [value]="service">{{ service.display }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "forms.internalId" | translate }}</mat-label>
          <input type="text" matInput placeholder="{{ 'forms.internalId' | translate }}" formControlName="internalId" />
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset class="formblock" *ngIf="ansRole?.length" style="display: flex; flex-direction: column">
      <div *ngFor="let r of ansRole; let i = index">
        <mat-form-field *ngIf="r.profession">
          <mat-label>{{ "forms.profession" | translate }}</mat-label>
          <input type="text" matInput [value]="r.profession" [disabled]="true" />
        </mat-form-field>
        <mat-form-field *ngIf="r.specialty">
          <mat-label>{{ "forms.specialty" | translate }}</mat-label>
          <input type="text" matInput [value]="r.specialty" [disabled]="true" />
        </mat-form-field>
        <mat-form-field *ngIf="r.categorie">
          <mat-label>{{ "forms.categorie" | translate }}</mat-label>
          <input type="text" matInput [value]="r.categorie" [disabled]="true" />
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset *ngIf="mode === MODES.CREATE">
      <mat-form-field>
        <input type="text" matInput placeholder="{{ 'forms.login' | translate }}" formControlName="login" />
      </mat-form-field>
      <mat-form-field>
        <mat-icon matSuffix class="color-button" (click)="generatePassword()">refresh</mat-icon>
        <input type="text" matInput placeholder="{{ 'forms.activationCode' | translate }}" formControlName="activationCode" />
      </mat-form-field>
      <mat-checkbox (change)="needMail = !needMail">{{ "forms.needMail" | translate }} </mat-checkbox>
    </fieldset>
  </div>
  <fieldset [ngStyle]="{ display: !canReadGroups || !canReadPermissions ? 'none' : 'block' }">
    <app-practitioner-access-groups
      [mode]="mode"
      [(practitionerAccessGroupsIds)]="accessGroups"
      [needDelete]="true"
      [needAdd]="true"
      [needCreate]="canCreateGroup ? true : false"
    ></app-practitioner-access-groups>
  </fieldset>

  <div>
    <button mat-raised-button *ngIf="mode === MODES.CREATE" color="primary" type="submit">
      {{ "forms.createUser" | translate }}
    </button>
    <button mat-raised-button *ngIf="mode === MODES.UPDATE" color="primary" type="submit">
      {{ "forms.updateUser" | translate }}
    </button>
    <mat-label class="msg-error" *ngIf="practitionerForm.touched && practitionerForm.invalid">
      {{ "forms.dataNotValid" | translate }}
    </mat-label>
  </div>
</form>
