<div class="drugGraphComponent">
  <ng-container *ngIf="hasData">
    <mat-button-toggle-group [(ngModel)]="drugsGraphRange" (ngModelChange)="setDrugsGraphRange(drugsGraphRange)">
      <mat-button-toggle value="days">
        {{ "periodUnit.day.plural" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="weeks">
        {{ "periodUnit.week.plural" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="months">
        {{ "periodUnit.month.plural" | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="export-container">
      <button
        mat-icon-button
        (click)="onExportPDF()"
        aria-label="icon button with a pdf icon"
        *ngIf="('dashboard/exportDrugIntakePdf' | isAuthorized : 'POST' | async) === true"
        [disabled]="exportPdfInProgress"
      >
        <mat-icon *ngIf="exportPdfInProgress"><mat-spinner diameter="24"></mat-spinner></mat-icon>
        <mat-icon *ngIf="!exportPdfInProgress" color="primary">picture_as_pdf</mat-icon>
      </button>
    </div>
    <canvas
      id="chart"
      baseChart
      #baseChart="base-chart"
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [colors]="chartColors"
      chartType="bar"
    ></canvas>
  </ng-container>
  <p *ngIf="!hasData">{{ "widget.noData" | translate }}</p>
</div>
