import * as moment from "moment";
import { FormsData } from "../helpers/formsData";
import { Tools } from "../helpers/tools";
import { ToolsService } from "../providers/tools.service";
import { IDrugInfo, IPackExtended } from "./drugInfo.interface";
import { KeyValue } from "./keyValues.model";
import { Reference } from "./reference.interface";
import { ITiming } from "./sharedInterfaces";
import { IStepwise } from "./stepwiseDrug.interface";

export enum ActionStatusEntity {
  ACTIVE = 0,
  CREATED = 1,
  MODIFIED = 2,
  DELETED = 3,
}

/**
 * status of an entity
 */
export enum StatusEntity {
  ACTIVE = 1,
  ARCHIVED = 2,
  SHARED = 3,
  LOCKED = 4,
  PASSWORDRESET = 5,
  CANCELLED = 6,
  INVITED = 7,
  DELETED = 8,
}

export interface Entity {
  _id?: string;
  creation?: string;
  modified?: string;
  modified_by?: {
    caremateId: string;
    date: string;
  }[];
  created_by?: string;
  actionStatus?: ActionStatusEntity;
  entityStatus?: StatusEntity[];
  options?: EntityOption; // custom options for entity
}

export interface EntityOption {
  allowCreation: boolean; // For usual readonly entity, allow creation by patient (Used in careplan template, on mobile app)
  allowModification: boolean; // For usual readonly entity, allow modification by patient (Used in careplan template, on mobile app)
}

/**
 *  type of object data handled by link
 */
export enum ENTITY_TYPE {
  NOTE = 1,
  PHOTO = 2,
  DRUG = 3,
  QUESTIONNAIRE = 4,
}

/**
 * Interface to link Drug extra data to Careplan  (from hospital, unmodifiable) or Patient (personal drug)
 */
export class EntityDrug {
  public static get PERFORMER_HOSPITAL(): string {
    return "H";
  }
  public static get PERFORMER_FAMILYDOCTOR(): string {
    return "D";
  }
  public static get PERFORMER_PHARMACIST(): string {
    return "P";
  }

  public notify: boolean;
  public name: string;
  public reference: string;
  public quantity: string;
  public frequency: ITiming;
  public location: ACTIVITY_LOCATION;
  public performer: Reference;
  // public timingCode?: string;    // M=morning, N=noon, E=evening ;
  public comment: string; // only modifiable in both cases
  public photo: string; // photo data/url
  public cycle?: CycleSchema;
  public source: string;
  public stepwiseSchema?: {
    name: string;
    stepwises: IStepwise[];
  };
  public atcCode?: string;
  public knownPackaging?: boolean;
  public managedStock?: boolean;
  public allPack?: IPackExtended[];
  public lastPackNumber?: number;
  public vmpCode?: string;
  public prescriptionName?: string;
  /**
   *
   * @param quantityDisplay string with number and fraction (ex: 1 + 1/2)
   * @returns a string representinf a number (ex: 1.5)
   */
  public static computeQuantityValue(quantityDisplay: string, mustBeNumber: boolean): string {
    if (!quantityDisplay || quantityDisplay === "0") {
      return null;
    }
    const split = quantityDisplay.split(" + ");
    const lessThan0 = split[0].includes("/");
    if (split.length === 1 && !lessThan0) {
      if (isNaN(Number(quantityDisplay)) && mustBeNumber) return "1";
      return quantityDisplay;
    }
    const n = lessThan0 ? 0 : isNaN(Number(split[0])) ? 1 : Number(split[0]);
    const div = lessThan0 ? split[0].split("/") : split[1].split("/");
    if (div.length !== 2) {
      return n.toString();
    }
    const d1 = isNaN(Number(div[0])) ? 0 : Number(div[0]);
    const d2 = isNaN(Number(div[1])) ? 1 : Number(div[1]);
    const val = d1 / d2;
    const roundedDiv = Math.floor(val * 100) / 100;
    const quantity = n + roundedDiv;
    return quantity.toString();
  }

  /**
   *
   * @param quantityValue a string representinf a number (ex: 1.5)
   * @param withoutSuffix if the number is managed (see EntityDrug.QUANTITTY), add the suffix indicated on the return line
   * @returns a string with number and fraction (ex: 1 + 1/2) followed by the suffix if the number is managed, otherwise returns quantityValue
   */
  public static computeQuantityDisplay(quantityValue: string, withoutSuffix = false): string {
    if (!quantityValue) return null;
    if (isNaN(Number(quantityValue))) return quantityValue;
    const val = Number(quantityValue);
    const frac = Math.floor((val % 1) * 100) / 100;
    const num = val - frac;
    const allQuantities = FormsData.QUANTITY;
    const fracString = allQuantities.find((i) => i.value === frac) ?? "";
    if (fracString) {
      return (num > 0 ? num + " + " : "") + fracString.key + (withoutSuffix ? "" : ToolsService.SUFFIX_QUANTITY);
    }
    return quantityValue + (withoutSuffix ? "" : ToolsService.SUFFIX_QUANTITY);
  }
}

export interface IDrugStock {
  patientId: string;
  drugId: string;
  totalUsages: number;
  totalRemainingUsages: number;
}
export interface CycleSchema {
  name: string;
  cycle: boolean[];
  startDate: Date;
  pauseDate?: Date[];
  isLinked?: boolean;
}

/**
 *  Interfaces used to handle any kind of custom data linked to another entity
 */
export interface IEntitylink extends Entity {
  entityType: ENTITY_TYPE;
  caremateOwnerId: string; // caremateIdentifier for owner
  parentType: PARENT_TYPE; // parent type
  parentId: string; // parent identifier
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityData: any; // may contains EntityNote, EntityDrug or EntityQuestionnaire interface
}

/**
 * enum for activity locations
 */
export const enum ACTIVITY_LOCATION {
  HOME,
  HOSPITAL,
  OTHER,
}

/**
 * Parent type for Entity
 */
export enum PARENT_TYPE {
  CAREPLAN = 1,
  ACTIVITY = 2,
  ACTIVITY_DRUG = 3,
  DRUG = 4,
  APPOINTEMENT = 5,
  PATIENT = 6,
}

export const enum GAUGE_COLOR {
  GREEN = "#28a745",
  RED = "#dc3545",
  ORANGE = "#ffc107",
}
export interface IDrugStockIncrement {
  patientId?: string;
  drugId: string;
  totalUsagesInc: number;
  totalRemainingUsagesInc: number;
}
export class Entity {
  public static get RISING(): string {
    return "rising";
  }
  public static get MORNING(): string {
    return "morning";
  }
  public static get NOON(): string {
    return "noon";
  }
  public static get EVENING(): string {
    return "evening";
  }
  public static get BEDING(): string {
    return "beding";
  }

  public static get TIMING_RISING(): string {
    return "R";
  }
  public static get TIMING_MORNING(): string {
    return "M";
  }
  public static get TIMING_NOON(): string {
    return "N";
  }
  public static get TIMING_EVENING(): string {
    return "E";
  }
  public static get TIMING_BED(): string {
    return "B";
  }
  public static get TIMING_NONE(): string {
    return "";
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match
  _id?: string;
  creation?: string;
  modified?: string;
  actionStatus?: ActionStatusEntity;
  entityStatus?: StatusEntity[]; // an array because an entity can be ARCHIVED and SHARED and ...
  modified_by?: {
    caremateId: string;
    date: string;
  }[];
  created_by?: string;

  public static toKeyValues(code: string): KeyValue[] {
    const timings = new Array<KeyValue>();
    if (!code) {
      code = ""; // always return 3 KeyValues
    }
    timings.push(
      new KeyValue({
        key: Entity.RISING,
        value: code.indexOf(Entity.TIMING_RISING) >= 0,
      })
    );
    timings.push(
      new KeyValue({
        key: Entity.MORNING,
        value: code.indexOf(Entity.TIMING_MORNING) >= 0,
      })
    );
    timings.push(
      new KeyValue({
        key: Entity.NOON,
        value: code.indexOf(Entity.TIMING_NOON) >= 0,
      })
    );
    timings.push(
      new KeyValue({
        key: Entity.EVENING,
        value: code.indexOf(Entity.TIMING_EVENING) >= 0,
      })
    );
    timings.push(
      new KeyValue({
        key: Entity.BEDING,
        value: code.indexOf(Entity.TIMING_BED) >= 0,
      })
    );
    return timings;
  }
}

export class Entitylink implements IEntitylink {
  entityType: ENTITY_TYPE;
  caremateOwnerId: string;
  parentType: PARENT_TYPE;
  parentId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityData: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  _id?: string;
  creation?: string;
  modified?: string;
  actionStatus?: ActionStatusEntity;
  entityStatus?: StatusEntity[];
  options?: EntityOption;
  /**
   * create a empty drug
   */
  public static createDrug(ownerId: string, parentId: string, parentType: PARENT_TYPE, drugInfo?: IDrugInfo, lang?: string): IEntitylink {
    // create main Entitylink
    const entity: IEntitylink = {
      _id: Tools.genValidId(), // this ID is purely internal, it will be change once synchronized with application server
      entityType: ENTITY_TYPE.DRUG,
      caremateOwnerId: ownerId,
      creation: moment().format(),
      modified: moment().format(),
      actionStatus: ActionStatusEntity.CREATED,
      entityStatus: [StatusEntity.ACTIVE],
      parentId,
      parentType,
      entityData: {},
    };
    // create specific EntityDrug
    const drug: EntityDrug = {
      notify: true,
      name: "",
      prescriptionName: drugInfo && lang ? drugInfo[lang].prescriptionNameFamhp : undefined,
      reference: "",
      quantity: "",
      frequency: {
        boundsPeriod: {
          start: moment().format(),
          end: moment().add(1, "months").format(),
        },
        count: null,
        endless: false,
        frequency: 1,
        period: 1,
        periodUnits: "d",
        when: "",
        timingCode: "", // M=morning, N=noon, E=evening
      },
      location: 0,
      performer: {
        reference: EntityDrug.PERFORMER_HOSPITAL,
        display: "hospital",
      },
      comment: "", // only modifiable in both cases
      photo: null,
      source: drugInfo?.dbName,
      atcCode: drugInfo?.atc?.code,
      knownPackaging: drugInfo?.knownPackaging,
      managedStock: false,
      lastPackNumber: Tools.isDefined(drugInfo?.packNumber) ? drugInfo.packNumber : -1,
      vmpCode: drugInfo?.vmpCode,
      allPack: drugInfo?.allPack ?? [],
    };
    entity.entityData = drug;
    return entity;
  }
}
