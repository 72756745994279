import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { CycleSchema, EntityDrug, IEntitylink } from "src/app/models/entity.interface";
import { IKnowledges, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { Notification, NOTIFICATION_STATUS } from "src/app/models/notifications.interface";
import { IConfiguration } from "src/app/models/patientConfig.interface";
import { TimingData } from "src/app/models/timingData.model";
import { DrugsService } from "src/app/providers/drugs.service";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { PatientService } from "src/app/providers/patient.service";
import { SessionService } from "src/app/providers/session.service";
import { AddDrugIntakeDialogComponent } from "../add-drug-intake-dialog/add-drug-intake-dialog.component";

@Component({
  selector: "app-drug-expansion-panel",
  templateUrl: "./drug-expansion-panel.component.html",
  styleUrls: ["./drug-expansion-panel.component.scss"],
})
export class DrugExpansionPanelComponent implements OnInit {
  @Input() data: IEntitylink[];
  @Input() dataDrugs: IEntitylink[];
  @Input() loading: boolean;
  @Input() knowledgesLoaded: boolean;
  @Input() drugsKnowledges: IKnowledges[];
  @Input() drugsInfo: IDrugInfo[];
  @Input() historic: IDrugsHistoric[];
  @Input() intakeDrugs: Notification[];
  @Input() patientId: string;

  @Output() triggerShowKnowledges = new EventEmitter<{ drug: EntityDrug; docCategory: KNOW_DOC_CATEGORY }>();
  @Output() triggerUpdateDrug = new EventEmitter<IEntitylink>();
  @Output() triggerDeleteDrug = new EventEmitter<IEntitylink>();
  @Output() triggerShowHistoricLocal = new EventEmitter<IEntitylink>();
  @Output() triggerOnPauseCycle = new EventEmitter<IEntitylink>();
  @Output() triggerShowMore = new EventEmitter<IEntitylink>();

  public toggleExpansion = [];
  public KNOW_DOC_CATEGORY = KNOW_DOC_CATEGORY;
  public drugNotifications: { [key: string]: Notification[] } = {};
  public NOTIFICATION_STATUS = NOTIFICATION_STATUS;
  public patientConfig: IConfiguration;

  private onDestroy$ = new Subject<void>();

  constructor(
    private drugsService: DrugsService,
    private sessionService: SessionService,
    private drugSchemaService: DrugSchemaService,
    private dialog: MatDialog,
    private patientService: PatientService
  ) {
    this.sessionService.refreshDrugsData.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.loadIntakes();
    });
  }

  ngOnInit(): void {
    this.patientService
      .getPatientConfiguration(this.patientId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((config) => {
        this.patientConfig = config;
      });
    this.loadIntakes();
  }

  public isInfinite(date: string): boolean {
    return this.drugsService.isInfinite(date);
  }

  public showKnowledges(drug: EntityDrug, docCategory: KNOW_DOC_CATEGORY): void {
    this.triggerShowKnowledges.emit({ drug, docCategory });
  }

  public onURL(drug: EntityDrug): void {
    const userLang = this.sessionService.userLang;
    const infos: IDrugInfo = this.drugsInfo.find((d) => d?.cnk === drug.reference);
    if (infos) {
      const url = infos[userLang] ? infos[userLang].url : infos.fr.url;
      window.open(url, "_blank");
    }
  }

  public updateDrug(drug: IEntitylink): void {
    this.triggerUpdateDrug.emit(drug);
  }

  public deleteDrug(drug: IEntitylink): void {
    this.triggerDeleteDrug.emit(drug);
  }

  public showHistoricLocal(drug: IEntitylink): void {
    this.triggerShowHistoricLocal.emit(drug);
  }

  public isCycleInPause(cycle: CycleSchema): boolean {
    return this.drugSchemaService.isCycleInPause(cycle);
  }

  public OnPauseCycle(element: IEntitylink): void {
    this.triggerOnPauseCycle.emit(element);
  }

  public getFormatDate(d: Date): string {
    return this.drugsService.getFormatDate(d);
  }

  public showMore(element: IEntitylink): void {
    this.triggerShowMore.emit(element);
  }

  private loadIntakes() {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");

    this.data.forEach((drug) => {
      const entityId = drug._id;
      const drugHistoric = this.historic.filter((v) => v.entityId === entityId).reverse();
      const supposedIntakes = DrugsService.getSupposedIntakes(drug.entityData, drugHistoric);
      const cibledNotif = DrugsService.getCibledNotification(entityId, this.intakeDrugs).reverse();
      const combinedNotifs = DrugsService.combineSupposedAndRealIntakes(supposedIntakes, cibledNotif);

      this.drugNotifications[drug._id] = combinedNotifs.filter((notif) => {
        const notifDate = moment(notif.time).startOf("day");
        return notifDate.isSame(today) || notifDate.isSame(yesterday);
      });
    });
  }

  public onAddIntake(drug: IEntitylink, notif: Notification): void {
    const time = TimingData.getMomentHour(notif.moment, this.patientConfig.settings.notificationSettings, notif.time);
    const dialogRef = this.dialog.open(AddDrugIntakeDialogComponent, {
      data: {
        patientId: this.patientId,
        drug: drug,
        time: time ? time : notif.time,
        moment: notif.moment,
        quantity: notif.quantity,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result?.length === 1) {
          this.sessionService.needRefreshDrugsData();
        }
      });
  }

  public onAddUnscheduledIntake(drug: IEntitylink): void {
    const dialogRef = this.dialog.open(AddDrugIntakeDialogComponent, {
      data: {
        patientId: this.patientId,
        drug: drug,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.sessionService.needRefreshDrugsData();
        }
      });
  }
}
