import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { WidgetItem } from "src/app/components/widgets/widget-item";
import { Account, CONNECTION_OPTION } from "src/app/models/account.interface";
import { GlobalParameter, PatientWidgetName, PreferenceContext, TileParameter, WidgetParameter } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/reference.interface";
import { RouteData } from "src/app/models/route-data.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { WidgetStored } from "src/app/models/widget.interface";
import { FormPage } from "src/app/pages/formPage";
import { LanguagesService } from "src/app/providers/languages.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetManagerService } from "src/app/providers/widget-manager.service";
import { ErrorOnGettingCaremateIDComponent } from "../error-on-getting-caremate-id/error-on-getting-caremate-id.component";
import { ResetOwnPasswordComponent } from "../forms/reset-own-password/reset-own-password.component";
import { UiAlertComponent } from "../shared/ui-alert/ui-alert.component";

@Component({
  selector: "app-preference-dialog",
  templateUrl: "./preference-dialog.component.html",
  styleUrls: ["./preference-dialog.component.scss"],
})
export class PreferenceDialogComponent extends FormPage implements OnInit, OnDestroy {
  @ViewChild("helpAlert") helpAlert: UiAlertComponent;
  public pageLoaded = false;
  public errorMessage: string;
  public FRANCE: string;
  public country: string;
  public preferenceForm = this.fb.group({
    preferredLanguage: ["", Validators.required],
    defaultLandingPage: ["", Validators.required],
    preferredOrg: ["", Validators.required],
    preferredService: ["", Validators.required],
    usePreferredLanguageWhenConflict: [],
    keepLayoutFromPatientPage: [],
    showAllWidgetWhenDetails: [],
    nbColumn: ["", [Validators.required]],
    inactivityDuration: ["", Validators.required],
    go2PatientAfterInscription: [],
  });
  public availableServices: Reference[];
  public availableOrgs: Reference[];
  private mapOrgsAndServices: Map<string, Reference[]> = new Map<string, Reference[]>();
  public availableWidgets: string[];
  public selectedWidgets: string[] = [];
  public widgetItems: WidgetItem[] = [];
  public selectedTile: PatientWidgetName[] = [];
  public hasError = false;
  public availableTile: string[] = this.initTileList();
  public saveInProgress = false;
  public showHelp = false;
  public availableLangs: ITranslation[];
  private account: Account;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    public sessionService: SessionService,
    private userService: UserService,
    private widgetManagerService: WidgetManagerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PreferenceDialogComponent>,
    private dialog: MatDialog,
    public tileManager: TileManager,
    protected languagesService: LanguagesService
  ) {
    super();
    this.availableWidgets = this.widgetManagerService.getAllVisibleWidgetName(
      this.userService.isAuthorizedSync(null, "patientAlertNoDataTransmission", "GET"),
      this.userService.isAuthorizedSync(null, "dashboard/prescriptionInfos", "GET")
    );
    this.account = this.sessionService.account;
    this.country = this.account.referenceCountry ? this.account.referenceCountry : this.sessionService.currentCountry;
    this.FRANCE = this.sessionService.FRANCE;
    const deactivatedAuth = this.account.authOptionsDeactivated ? this.account.authOptionsDeactivated : [];
    this.account.authOptionsDeactivated = deactivatedAuth;
    if (this.country === this.FRANCE && deactivatedAuth.includes(CONNECTION_OPTION.PSC)) {
      this.preferenceForm.addControl("togglePSC", new UntypedFormControl(false));
    } else if (this.country === this.FRANCE && !deactivatedAuth.includes(CONNECTION_OPTION.PSC)) {
      this.preferenceForm.addControl("togglePSC", new UntypedFormControl(true));
    } else if (this.country !== this.FRANCE && deactivatedAuth.includes(CONNECTION_OPTION.EHEALTH)) {
      this.preferenceForm.addControl("toggleEHealth", new UntypedFormControl(false));
    } else if (this.country !== this.FRANCE && !deactivatedAuth.includes(CONNECTION_OPTION.EHEALTH)) {
      this.preferenceForm.addControl("toggleEHealth", new UntypedFormControl(true));
    }
  }

  ngOnInit(): void {
    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => (this.availableLangs = languages));

    const $getTilePrefObs = this.preferenceService.list(PreferenceContext.TILE);
    const $getWidgetPrefObs = this.preferenceService.list(PreferenceContext.WIDGET);

    this.preferenceService
      .getGlobal()
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((parameters) => {
        let selectedOrg: Reference;
        let selectedService: Reference;
        if (parameters) {
          selectedOrg = parameters.preferredOrg;
          selectedService = parameters.preferredService;
          const layout = parameters.keepLayoutFromPatientPage !== undefined ? parameters.keepLayoutFromPatientPage : false;
          const selectedInactivityDuration = parameters?.inactifPatientDuration ? parameters.inactifPatientDuration : "1 week";
          this.preferenceForm
            .get("preferredLanguage")
            .setValue(parameters.preferredLanguage ? parameters.preferredLanguage : this.availableLangs[0]);
          this.preferenceForm
            .get("defaultLandingPage")
            .setValue(
              parameters.defaultLandingPage ? this.landingPages.find((lp) => lp === parameters.defaultLandingPage) : this.landingPages[0]
            );
          this.preferenceForm.get("usePreferredLanguageWhenConflict").setValue(parameters.usePreferredLanguageWhenConflict);
          this.preferenceForm.get("showAllWidgetWhenDetails").setValue(parameters.showAllWidgetWhenDetails);
          this.preferenceForm.get("keepLayoutFromPatientPage").setValue(layout);
          this.preferenceForm.get("inactivityDuration").setValue(selectedInactivityDuration);
          this.preferenceForm.get("go2PatientAfterInscription").setValue(parameters.go2PatientAfterInscription);
        } else {
          this.preferenceForm.get("defaultLandingPage").setValue(this.landingPages[0]);
          if (this.userService.isMonitoringUser) {
            selectedOrg = this.userService.allMonitoringOrganizations[0].asReference;
            selectedService = this.userService.allMonitoringServices.find(
              (s) => s.providedBy.reference === selectedOrg.reference
            )?.asReference;
            this.preferenceForm.get("preferredOrg").setValue(selectedOrg);
            this.preferenceForm.get("preferredService").setValue(this.userService.allMonitoringServices[0].asReference);
          } else {
            selectedOrg = this.userService.allOrganizations[0].asReference;
            selectedService = this.userService.allServices.find((s) => s.providedBy.reference === selectedOrg.reference)?.asReference;
            this.preferenceForm.get("preferredService").setValue(this.userService.allServices[0].asReference);
          }
          this.preferenceForm.get("usePreferredLanguageWhenConflict").setValue(true);
          this.preferenceForm.get("keepLayoutFromPatientPage").setValue(false);
          this.preferenceForm.get("showAllWidgetWhenDetails").setValue(false);
          this.preferenceForm.get("preferredLanguage").setValue(this.sessionService.userLang);
          this.preferenceForm.get("inactivityDuration").setValue("1 week");
          this.preferenceForm.get("go2PatientAfterInscription").setValue(undefined);
        }
        this.preferenceForm.get("preferredOrg").setValue(selectedOrg);
        this.preferenceForm.get("preferredService").setValue(selectedService);
        forkJoin([$getWidgetPrefObs, $getTilePrefObs])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(([a, b]: Array<WidgetParameter | TileParameter>) => {
            const widgetParameters: WidgetParameter = a as WidgetParameter;
            const tileParameters: TileParameter = b as TileParameter;

            if (widgetParameters) {
              if (widgetParameters.nbColumn) {
                this.preferenceForm.get("nbColumn").setValue(widgetParameters.nbColumn);
              }
              if (widgetParameters.widgets) {
                this.widgetItems = this.widgetManagerService.getWidgetItems(widgetParameters.widgets);
                this.selectedWidgets = this.widgetItems.map((v) => v.name);
              }
            } else {
              this.preferenceForm.get("nbColumn").setValue(this.widgetManagerService.defaultParameter.nbColumn);
              this.selectedWidgets = this.widgetManagerService.defaultParameter.widgets.map((v) => v.name);
            }

            if (tileParameters) {
              if (tileParameters.list) {
                this.selectedTile = tileParameters.list;
                // reorder the availableTile with the db tiles order
                this.selectedTile.forEach((dbTile, i) => {
                  const indexFound = this.availableTile.findIndex((tile) => tile === dbTile);
                  moveItemInArray(this.availableTile, indexFound, i);
                });
              }
            } else {
              this.selectedTile = [...this.availableTile] as PatientWidgetName[];
            }
            if (this.userService.isMonitoringUser) {
              this.availableOrgs = this.userService.allMonitoringOrganizations.map((s) => s.asReference);
              this.availableServices = this.userService.allMonitoringServices.map((s) => s.asReference);
            } else {
              this.availableOrgs = this.userService.allOrganizations.map((s) => s.asReference);
              this.availableServices = this.userService.allServices.map((s) => s.asReference);
            }
            for (const org of this.availableOrgs) {
              const services = this.userService.isMonitoringUser ? this.userService.allMonitoringServices : this.userService.allServices;
              const orgServices = services.filter((s) => s.providedBy.reference === org.reference).map((s) => s.asReference);
              this.mapOrgsAndServices.set(org.reference, orgServices);
            }
            if (!selectedOrg) {
              selectedOrg = this.availableOrgs[0];
              this.preferenceForm.get("preferredOrg").setValue(selectedOrg);
            }
            this.availableServices = this.mapOrgsAndServices.get(selectedOrg.reference);
            if (!selectedService) {
              selectedService = this.availableServices[0];
              this.preferenceForm.get("preferredService").setValue(selectedService);
            }
            this.setupMonitOrgWatch();
            this.pageLoaded = true;
          });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupMonitOrgWatch() {
    this.preferenceForm
      .get("preferredOrg")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((org: Reference) => {
        this.availableServices = this.mapOrgsAndServices.get(org.reference);
        this.preferenceForm.get("preferredService").setValue(this.availableServices[0]);
      });
  }

  public initTileList(): string[] {
    return Object.keys(PatientWidgetName)
      .map((k) => PatientWidgetName[k])
      .filter((key) => {
        const perm = this.getPermissionsKeyFromName(key);
        return this.userService.isAuthorizedSync(null, perm.routeName, perm.method);
      });
  }

  private getPermissionsKeyFromName(key: string): {
    routeName: string;
    method: string;
  } {
    switch (key) {
      case PatientWidgetName.OBSERVATIONS:
        return {
          routeName: "observations",
          method: "GET",
        };
      case PatientWidgetName.DRUGS:
        return {
          routeName: "entitylinks",
          method: "GET",
        };
      case PatientWidgetName.QUESTIONNAIRES:
        return {
          routeName: "questionnaires",
          method: "GET",
        };
      case PatientWidgetName.TELECONSULTATIONS:
        return {
          routeName: "patientsAppointments",
          method: "GET",
        };
      case PatientWidgetName.ALERTS:
        return {
          routeName: "rulealerts",
          method: "GET",
        };
      case PatientWidgetName.VITAL_SIGNS:
        return {
          routeName: "dashboard/vitalProfileDefinitions",
          method: "GET",
        };
      case PatientWidgetName.CAREPLANS:
        return {
          routeName: "careplans",
          method: "GET",
        };
      case PatientWidgetName.COMMUNICATIONS:
        return {
          routeName: "dashboard/communications",
          method: "GET",
        };
      case PatientWidgetName.RA:
        return {
          routeName: "riskAssessments",
          method: "GET",
        };
      case PatientWidgetName.REWARDS:
        return {
          routeName: "rewardScoresByPatient",
          method: "GET",
        };
      case PatientWidgetName.DOCUMENTS:
        return {
          routeName: "dashboard/documentsInfos",
          method: "GET",
        };
    }
  }

  public getForm(): UntypedFormGroup {
    return this.preferenceForm;
  }

  public save(): void {
    if (this.preferenceForm.valid) {
      this.saveInProgress = true;
      const widgetsToStore = this.selectedWidgets.map((widgetName: string) => {
        const widgetItem = this.widgetItems.find((w) => w.name === widgetName);
        return {
          name: widgetName,
          row: widgetItem ? widgetItem.row : 0,
          column: widgetItem ? widgetItem.column : 0,
        } as WidgetStored;
      });
      const widgetParameter: WidgetParameter = {
        nbColumn: this.preferenceForm.get("nbColumn").value,
        widgets: widgetsToStore,
      };
      this.orderTile();
      const tileParameter: TileParameter = {
        list: this.selectedTile,
      };

      const globalParameter: GlobalParameter = {
        preferredLanguage: this.preferenceForm.get("preferredLanguage").value,
        defaultLandingPage: this.preferenceForm.get("defaultLandingPage").value,
        usePreferredLanguageWhenConflict: this.preferenceForm.get("usePreferredLanguageWhenConflict").value,
        preferredService: this.preferenceForm.get("preferredService").value,
        preferredOrg: this.preferenceForm.get("preferredOrg").value,
        keepLayoutFromPatientPage: this.preferenceForm.get("keepLayoutFromPatientPage").value,
        showAllWidgetWhenDetails: this.preferenceForm.get("showAllWidgetWhenDetails").value,
        inactifPatientDuration: this.preferenceForm.get("inactivityDuration").value,
        go2PatientAfterInscription: this.preferenceForm.get("go2PatientAfterInscription").value,
      };
      TileManager.showAll = globalParameter.showAllWidgetWhenDetails;
      const globalObservable = this.preferenceService.update({
        context: PreferenceContext.GLOBAL,
        parameters: globalParameter,
      });
      const widgetObservable = this.preferenceService.update({
        context: PreferenceContext.WIDGET,
        parameters: widgetParameter,
      });
      const tileObservable = this.preferenceService.update({
        context: PreferenceContext.TILE,
        parameters: tileParameter,
      });

      if (this.country === this.FRANCE && this.preferenceForm.get("togglePSC").value) {
        this.account.authOptionsDeactivated = this.account.authOptionsDeactivated.filter((a) => a !== CONNECTION_OPTION.PSC);
      } else if (
        this.country === this.FRANCE &&
        !this.preferenceForm.get("togglePSC").value &&
        !this.account.authOptionsDeactivated.includes(CONNECTION_OPTION.PSC)
      ) {
        this.account.authOptionsDeactivated.push(CONNECTION_OPTION.PSC);
      } else if (this.country !== this.FRANCE && this.preferenceForm.get("toggleEHealth").value) {
        this.account.authOptionsDeactivated = this.account.authOptionsDeactivated.filter((a) => a !== CONNECTION_OPTION.EHEALTH);
      } else if (
        this.country !== this.FRANCE &&
        !this.preferenceForm.get("toggleEHealth").value &&
        !this.account.authOptionsDeactivated.includes(CONNECTION_OPTION.EHEALTH)
      ) {
        this.account.authOptionsDeactivated.push(CONNECTION_OPTION.EHEALTH);
      }

      widgetObservable.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        tileObservable.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          globalObservable.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.userService
              .updateAccount(this.account)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                const lang = this.preferenceForm.value.preferredLanguage;
                this.translateService.use(lang);
                this.sessionService.userLang = lang;
                // waiting cause translateService take time to refresh lang
                this.translateService
                  .get("common.saveSuccess")
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe((trans) => {
                    this.snackBar.open(trans, "Ok", { duration: 3000 });
                  });
                this.dialogRef.close(true);
              });
          });
        });
      });
    } else {
      this.showError();
    }
  }

  private showError(): void {
    this.hasError = true;
    this.translateService
      .get("error.invalidData")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((trans) => {
        this.snackBar.open(trans, "Ok", {
          duration: 2000,
        });
      });
  }

  public get landingPages(): string[] {
    return this.router.config
      .filter(
        (route) => route.data && (route.data as RouteData).canBeLanding && this.userService.isAuthorizedSync(null, "/" + route.path, "GET")
      )
      .map((route) => route.path);
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1.reference === o2.reference;
  }

  public isEnabled(widgetName: string): boolean {
    if (this.selectedWidgets) {
      return this.selectedWidgets.includes(widgetName);
    }
    return false;
  }

  public isTileEnabled(tileName: PatientWidgetName): boolean {
    return this.selectedTile.findIndex((t) => t === tileName) > -1;
  }

  public changeTile(enabled: boolean, tileName: PatientWidgetName): void {
    if (enabled) {
      this.selectedTile.push(tileName);
    } else {
      const posIndex = this.selectedTile.findIndex((w) => w === tileName);
      if (posIndex >= 0) {
        this.selectedTile.splice(posIndex, 1);
      }
    }
  }

  public changeWidget(enabled: boolean, widgetName: string): void {
    if (enabled) {
      this.selectedWidgets.push(widgetName);
    } else {
      const posIndex = this.selectedWidgets.findIndex((w) => w === widgetName);
      if (posIndex >= 0) {
        this.selectedWidgets.splice(posIndex, 1);
      }
    }
  }

  public tileDropped(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.availableTile, event.previousIndex, event.currentIndex);
  }

  public handleTileArrowClicked(isUp: boolean, index: number): void {
    moveItemInArray(this.availableTile, index, isUp ? index - 1 : index + 1);
  }

  private orderTile() {
    const selectedTileOrdered: PatientWidgetName[] = [];
    this.availableTile.forEach((tile) => {
      if (this.isTileEnabled(tile as PatientWidgetName)) {
        selectedTileOrdered.push(tile as PatientWidgetName);
      }
    });
    this.selectedTile = selectedTileOrdered;
  }

  public onShowHelp(): void {
    this.showHelp = true;
  }

  public dismissHelp(): void {
    this.helpAlert.onDismiss();
  }

  public displayConfirmResetModal(): void {
    const account = this.sessionService.account;
    if (!account.caremateIdentifier) {
      this.dialog.open(ErrorOnGettingCaremateIDComponent, {
        width: "450px",
        height: "200px",
        data: {
          currentUser: {
            mail: account.mail,
            name: account.name,
            firstname: account.firstname,
          },
          preference: true,
        },
      });
      return;
    }
    this.dialog.open(ResetOwnPasswordComponent, {
      data: {
        login: account.mail,
        lang: this.sessionService.userLang,
      },
    });
  }
}
