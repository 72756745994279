import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { RewardDefinition } from "src/app/models/rewardDefinition.model";
import { REWARD_STATUS } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { RewardDefinitionService } from "src/app/providers/rewardDefinition.service";
import { RewardScoreService } from "src/app/providers/rewardScore.service";
import { SessionService } from "src/app/providers/session.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";
import { Item } from "../item-selector/item-selector.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { PatientRewardDataSource } from "./patient-reward-datasource";
import { RewardDetailsComponent } from "./reward-details/reward-details.component";

@Component({
  selector: "app-patient-reward",
  templateUrl: "./patient-reward.component.html",
  styleUrls: ["./patient-reward.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientRewardComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public actions: WidgetActionConfig[];
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.pu = pu;
      this.patientServiceId = pu.patient?.healthcareservice?.[0]?.reference;
      this.loadDataSource();
      this.loadPreferences();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<RewardScore>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public displayedColumns: string[] = [
    "objectifName",
    "objectifStart",
    "objectifEnd",
    "objectifProgress",
    // 'rewardStatut',
    // 'rewardHistory'
    "status",
    "action",
  ];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match

  public dataSource: PatientRewardDataSource;
  private currentPageSize: number;
  public sliderData: Item[] = [];
  public showDraft = true;
  public pu: PatientUser;
  public filterFormTable = this.fb.group({
    fromDate: new UntypedFormControl(moment().subtract(3, "month"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
    toDate: new UntypedFormControl(moment().endOf("day"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
  });
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public maxFromDate: string;
  public minToDate: string;
  public today = moment();
  public allDefinitions: RewardDefinition[];
  public refreshSub = this.sessionService.refreshRewardsList.subscribe(() => {
    this.loadDataSource();
  });
  private get from() {
    return this.filterFormTable.get("fromDate")?.value as string;
  }
  private get to() {
    return this.filterFormTable.get("toDate")?.value as string;
  }
  public patientServiceId: string;
  public lastFilter: Filter;
  public REWARD_STATUS = REWARD_STATUS;

  constructor(
    private preferenceService: PreferenceService,
    private sessionService: SessionService,
    protected rewardScoreService: RewardScoreService,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    protected rewardDefinitionService: RewardDefinitionService,
    private helpData: HelpData,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
  }

  ngOnInit(): void {
    this.createDataSource();
    this.filterFormTable.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.updateDate());
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataSource.clear();
    this.refreshSub.unsubscribe();
  }

  private updateDate() {
    const from = moment(this.from).format("YYYY-MM-DDTHH:mm:ss");
    const to = moment(this.to).format("YYYY-MM-DDTHH:mm:ss");
    this.maxFromDate = moment(to).toISOString();
    this.minToDate = moment(from).toISOString();
    if (from && to && !this.dataSource.loading) {
      this.dataSource.loadRewardsPage(from, to, false, this.pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget());
    }
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_REWARD_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private refreshData() {
    this.dataSource.loadRewardsPage(
      this.from,
      this.to,
      false,
      this.pu.user.caremateIdentifier,
      this.preferenceService.getAutoRefreshWidget()
    );
  }

  private loadDataSource() {
    this.createDataSource();
    this.dataSource.loadRewardsPage(
      moment(this.from).format("YYYY-MM-DDTHH:mm:ss"),
      moment(this.to).format("YYYY-MM-DDTHH:mm:ss"),
      false,
      this.pu.user.caremateIdentifier,
      this.preferenceService.getAutoRefreshWidget()
    );
  }

  private createDataSource() {
    if (!this.dataSource) {
      this.dataSource = new PatientRewardDataSource(this.rewardScoreService, this.translateService);
      this.rewardDefinitionService
        .listRewardDefinitions()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.allDefinitions = res;
          this.dataSource.rewardDefinitions = this.allDefinitions;
        });
    }
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_REWARD_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.lastFilter = filter;
    this.dataSource.setFilter(filter);
  }

  public getTargetName(reference: string): string {
    return this.getTargetDef(reference)?.rewardDisplayName?.[this.translateService.currentLang] || "-";
  }

  public getTargetDescription(reference: string): string {
    return this.getTargetDef(reference)?.dashboardRewardText?.[this.translateService.currentLang] || "-";
  }

  private getTargetDef(reference: string) {
    return this.allDefinitions?.find((d) => FHIRHelper.getMainIdentifier(d)?.value === reference);
  }

  private getTargetScore(reference: string) {
    return this.getTargetDef(reference)?.rewardScore;
  }

  public getTargetValue(score: RewardScore): string {
    const targetDef = this.getTargetDef(score.rewardDefinitionReference.reference);
    return `${score?.currentScore}/${targetDef?.rewardScore}`;
  }

  private getChoices(propertyName: string) {
    return this.dataSource.data
      .map((patientUser) => {
        return propertyName.split(".").reduce((p, c) => (p && p[c]) || null, patientUser);
      })
      .filter((value, index, self) => {
        return value && self.indexOf(value) === index;
      });
  }

  public onDetails(reward: RewardScore, createAction: boolean, description: string, score: string): void {
    this.dialog
      .open(RewardDetailsComponent, {
        data: {
          reward,
          createAction,
          description,
          score,
          patientServiceId: this.patientServiceId,
        },
        maxWidth: createAction ? "80vw" : "30vw",
        maxHeight: "93vh",
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.applyFilter(this.lastFilter);
        this.loadDataSource();
      });
  }

  public openRewardHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientRewardHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }
}
