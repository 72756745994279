import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { IObservation } from "../models/observations.interface";
import { QuestionnaireList } from "../models/questionnaireList.model";
import { IQuestionnaireResponse } from "../models/questionnaireResponse.interface";
import { Scoring } from "../models/questionnaireScoring.model";
import { QRApiService } from "./api/qr-api.service";
import { HealthcareserviceService } from "./healthcareservice.service";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class QRService {
  constructor(
    private qrApi: QRApiService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  public list(patientId: string, fromDate?: string, toDate?: string): Observable<Scoring[]> {
    const routeName = this.qrApi.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("QRService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<Scoring[]>;
        }
        return this.qrApi.list(patientId, fromDate, toDate).pipe(
          map((values) => {
            return values.map((v) => new Scoring(v, this.translateService));
          })
        );
      })
    );
  }

  /**
   * Create a IQuestionnaireResponse and its linked observations if necessary
   * @param qr
   * @param observations
   * @returns
   */
  public createQR(qr: IQuestionnaireResponse, observations?: IObservation[]): Observable<IQuestionnaireResponse> {
    return this.qrApi.createQR(qr, observations);
  }
  /**
   * Update a IQuestionnaireResponse and its linked observations if necessary
   * @param qr
   * @param observations
   * @returns
   */
  public updateQR(qr: IQuestionnaireResponse, observations?: IObservation[]): Promise<never> {
    return this.qrApi.updateQR(qr, observations).toPromise();
  }

  public getListExtended(
    fromDate: string,
    toDate: string,
    services?: string[],
    monitoringServicesIds?: string[]
  ): Observable<QuestionnaireList[]> {
    let currentService = services && services.length > 0 ? null : this.sessionService.currentService?.reference;
    if (currentService && currentService === this.sessionService.allsOption) {
      currentService = null;
      if (!services || services.length === 0) {
        services = this.healthcareService.availableServices().map((s) => s.asReference.reference);
      }
    }
    return this.qrApi.getListExtended(this.sessionService.userLang, fromDate, toDate, currentService, services, monitoringServicesIds).pipe(
      map((values) => {
        return values.map((v) => new QuestionnaireList(v));
      })
    );
  }

  public getAnonymousListExtended(): Observable<QuestionnaireList[]> {
    return this.qrApi.getAnonymousListExtended().pipe(
      map((values) => {
        return values.map((v) => new QuestionnaireList(v));
      })
    );
  }

  public getOneScoring(questionnaireScoring: string): Observable<Scoring> {
    return this.qrApi.getOne(questionnaireScoring).pipe(map((value) => new Scoring(value, this.translateService)));
  }

  public getScorings(questionnaireScorings: string[]): Observable<Scoring[]> {
    return this.qrApi.getScorings(questionnaireScorings);
  }
}
