import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { CommunicationOverviewComponent } from "../components/communication-overview/communication-overview.component";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../components/confirmation-dialog/confirmation-dialog.component";
import { AddCommunicationComponent } from "../components/forms/add-communication/add-communication.component";
import { FORMS_MODE } from "../helpers/formsData";
import { Opened_Status } from "../models/communications.interface";
import { InfoCommunication } from "../models/communications.model";
import { Group } from "../models/group.model";
import { Reference } from "../models/reference.interface";
import { CommunicationsService } from "./communications.service";
import { ResponsiveDialogService } from "./responsive-dialog.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class CommunicationsCrudService {
  constructor(
    private communicationsService: CommunicationsService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private responsiveDialog: ResponsiveDialogService
  ) {}

  public viewCommunication(communication: InfoCommunication): void {
    this.communicationsService.listOne(communication.selfId, communication.patientId).subscribe((v) => {
      this.dialog.open(CommunicationOverviewComponent, {
        data: { communication: v },
      });
    });
  }

  public deleteCommunication(communication: InfoCommunication, patientsIds: Reference[]): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.communicationsService.delete(communication.selfId, communication.patientId, patientsIds).subscribe(() => {
            this.showSuccess();
            this.sessionService.needRefreshCommunicationsList();
          });
        }
      });
  }

  public editCommunication(
    communication: InfoCommunication,
    allDatas?: InfoCommunication[],
    fromMainCom?: boolean,
    onePatientOnly?: boolean
  ): void {
    this.communicationsService.listOne(communication.selfId, communication.patientId).subscribe((com) => {
      // if communication is grouped and no have allDatas is cause we came from tile so we need ask back end other communication
      if (communication.isGrouped && !allDatas) {
        this.communicationsService.listAllForGroup(communication.selfId).subscribe((coms) => {
          const selection = coms.filter((c) => c.selfId === communication.selfId)?.map((c) => c.subject);
          this.dialog.open(AddCommunicationComponent, {
            data: {
              mode: FORMS_MODE.UPDATE,
              selection,
              communication: com,
              fromMainCom,
              allCanBeUpdated: communication.openedStatus === Opened_Status.SENT,
              onePatientOnly,
            },
          });
        });
      } else {
        const selection = allDatas?.filter((c) => c.selfId === communication.selfId)?.map((c) => c.subject) || [communication.subject];
        this.dialog.open(AddCommunicationComponent, {
          data: {
            mode: FORMS_MODE.UPDATE,
            selection,
            communication: com,
            fromMainCom,
            allCanBeUpdated: communication.openedStatus === Opened_Status.SENT,
            onePatientOnly,
          },
        });
      }
    });
  }

  public createCommunication(selection?: Reference[], allPatients?: Reference[], group?: Group, onePatientOnly?: boolean): void {
    this.responsiveDialog.open(AddCommunicationComponent, {
      // global config
      data: {
        mode: FORMS_MODE.CREATE,
        group,
        selection,
        allPatients,
        onePatientOnly,
      },
    });
  }

  private showSuccess(): void {
    const msg = this.translateService.instant("common.success");
    this.snackBar.open(msg, "ok", { duration: 3000 });
  }
}
