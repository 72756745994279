import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { IObservation } from "src/app/models/observations.interface";
import { IQuestionnaireList } from "src/app/models/questionnaireList.interface";
import { IQuestionnaireResponse } from "src/app/models/questionnaireResponse.interface";
import { IScoring } from "src/app/models/questionnaireScoring.interface";
import { Scoring } from "src/app/models/questionnaireScoring.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class QRApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "QuestionnairesResponses", // Theme !
      ["dashboard/questionnaireResponse"], // Post route paths
      [
        "dashboard/questionnaireScoringResult", // 0
        "questionnaireresponsescorings", // 1
        "dashboard/questionnaireList", // 2
        "dashboard/anonymousQuestionnaires", // 3
        "dashboard/questionnaireScoringsResults", // 4
      ], // Get route paths
      ["dashboard/questionnaireResponse"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string, fromDate?: string, toDate?: string): Observable<IScoring[]> {
    return this.api.get(this.readRoutes[1], {
      identifier: patientId,
      fromDate,
      toDate,
    }) as Observable<IScoring[]>;
  }

  /**
   * Create a IQuestionnaireResponse and its linked observations if necessary
   * @param qr
   * @param observations
   * @returns
   */
  public createQR(qr: IQuestionnaireResponse, observations?: IObservation[]): Observable<IQuestionnaireResponse> {
    const body = {
      response: qr,
      observations: observations,
    };
    return this.api.post(this.createRoutes[0], body) as Observable<never>;
  }
  /**
   * Update a IQuestionnaireResponse and its linked observations if necessary
   * @param qr
   * @param observations
   * @returns
   */
  public updateQR(qr: IQuestionnaireResponse, observations?: IObservation[]): Observable<never> {
    const body = {
      response: qr,
      observations: observations,
    };
    return this.api.put(this.updateRoutes[0], body) as Observable<never>;
  }

  public getListExtended(
    userLang: string,
    fromDate: string,
    toDate: string,
    serviceId?: string,
    services?: string[],
    monitoringServicesIds?: string[]
  ): Observable<IQuestionnaireList[]> {
    const params = {
      userLang,
      fromDate,
      toDate,
      serviceId: null,
      services: null,
      monitoringServicesIds: null,
    };
    if (Tools.isDefined(serviceId)) {
      params.serviceId = serviceId;
    }
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    return this.api.get(this.readRoutes[2], params) as Observable<IQuestionnaireList[]>;
  }

  public getAnonymousListExtended(): Observable<IQuestionnaireList[]> {
    return this.api.get(this.readRoutes[3]) as Observable<IQuestionnaireList[]>;
  }

  public getOne(questionnaireId: string): Observable<IScoring> {
    return this.api.get(this.readRoutes[0], {
      questionnaireId,
    }) as Observable<IScoring>;
  }

  public getScorings(questionnaireIds: string[]): Observable<Scoring[]> {
    return this.api.get(this.readRoutes[4], {
      questionnaireIds: questionnaireIds?.join(","),
    }) as Observable<Scoring[]>;
  }
}
