import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import moment from "moment";
import { Observable, Subject, of } from "rxjs";
import { concatMap, takeUntil } from "rxjs/operators";
import { ConfirmResetPasswordDialogComponent } from "src/app/components/confirm-reset-password-dialog/confirm-reset-password-dialog.component";
import { ErrorOnGettingCaremateIDComponent } from "src/app/components/error-on-getting-caremate-id/error-on-getting-caremate-id.component";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Item } from "src/app/components/item-selector/item-selector.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Organization } from "src/app/models/organization.model";
import { Practitioner } from "src/app/models/practitioner.model";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/reference.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { DataForUserPage } from "../user-page/user-page.component";
import { UserListDataSource } from "./user-list-datasource";

@Pipe({ name: "validDate" })
export class ValidDate implements PipeTransform {
  transform(date: string): string {
    return moment(date).isValid() ? date : "";
  }
}
@Component({
  selector: "app-user-list-page",
  templateUrl: "./user-list-page.component.html",
  styleUrls: ["./user-list-page.component.scss"],
})
export class UserListPageComponent implements AfterViewInit, OnInit, OnDestroy {
  // Material table
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Practitioner>;
  @Input()
  get service(): Healthcareservice {
    return this.hs[0];
  }
  set service(hs: Healthcareservice) {
    if (hs) {
      if (hs.mainId === this.sessionService.allsOption) {
        this.hs = this.userService.isMonitoringUser
          ? this.departementService.availableMonitoringServices()
          : this.departementService.availableServices();
      } else {
        this.hs = [hs];
      }
      this.loadData();
      if (this.table) {
        this.setupPaginator();
      }
    }
  }

  @Input()
  get services(): Healthcareservice[] {
    return this.hs;
  }
  set services(hs: Healthcareservice[]) {
    if (hs) {
      if (hs.length > 0 && hs[0].mainId === this.sessionService.allsOption) {
        this.hs = this.userService.isMonitoringUser
          ? this.departementService.availableMonitoringServices()
          : this.departementService.availableServices();
      } else {
        this.hs = hs;
      }
      this.loadData();
      if (this.table) {
        this.setupPaginator();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match
  private _organization: Organization = undefined;

  @Input()
  set organization(value: Organization) {
    this._organization = value;
    // Handle the changes here after ngOnInit
    this.computeServicesAndOrganizations();
  }
  get organization(): Organization {
    return this._organization;
  }

  @Input() isDialog?: boolean;

  public hs: Healthcareservice[];

  public dataSource: UserListDataSource;
  public data: unknown;
  public currentPageSize = 15;
  public actionOnMultiple = false;

  // Columns
  public availableColumnItems: Item[] = [];
  public get displayedColumns(): string[] {
    return [...this.availableColumnItems.filter((item) => item.checked).map((item) => item.value)];
  }
  // Filter
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public isFiltered = false;
  public filters: Filter[] = [];

  // Services et organizations
  public availableOrganizations: Reference[];
  public availableServices: Reference[];
  public selection = new SelectionModel<Practitioner>(true, []);
  public isUserAuthorizedToReset = false;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private practitionerService: PractitionerService,
    private dialog: MatDialog,
    private responsiveDialog: ResponsiveDialogService,
    private preferenceService: PreferenceService,
    private sessionService: SessionService,
    @Optional() private dialogRef: MatDialogRef<UserListPageComponent>,
    public helpData: HelpData,
    private userService: UserService,
    private departementService: HealthcareserviceService,
    private orgaService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.isCurrentUserCanResetPassword();

    this.availableColumnItems.length = 0;
    this.availableColumnItems = [
      { value: "visible", display: "model.patient.visible", checked: true },
      { value: "name", display: "model.patient.name", checked: true },
      { value: "firstname", display: "model.patient.firstname", checked: true },
      { value: "birthday", display: "model.patient.birthdate", checked: true },
      { value: "phone", display: "model.patient.phone", checked: true },
      { value: "email", display: "model.patient.email", checked: false },
      { value: "gender", display: "model.patient.gender", checked: true },
      {
        value: "healthcare",
        display: "model.patient.healthcare",
        checked: false,
      },
      {
        value: "managingOrganization",
        display: "model.patient.organization",
        checked: false,
      },
    ];

    if (this.isUserAuthorizedToReset) {
      this.availableColumnItems.push({
        value: "password",
        display: "model.patient.password",
        checked: true,
      });
    }
    this.computeServicesAndOrganizations();

    this.loadData();

    this.loadPreferences();
  }

  ngAfterViewInit(): void {
    this.setupPaginator();
    this.dataSource.sort?.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.dataSource.sort.direction) {
        this.isFiltered = true;
      } else {
        this.isFiltered = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.dataSource.clear();
    this.updatePreference();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData() {
    // Load data
    this.dataSource = new UserListDataSource(this.practitionerService, this.sessionService);
    this.userService
      .isAuthorized("practitioners", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuth) => {
        if (isAuth) {
          this.dataSource.loadPractitioners(
            null,
            this.hs?.map((s) => s.mainId)
          );
          this.data = this.dataSource.data;
        }
      });
  }

  private setupPaginator() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    if (this.currentPageSize > 15 && this.isDialog) {
      this.currentPageSize = 5;
    } else if (this.currentPageSize < 15 && !this.isDialog) {
      this.currentPageSize = 15;
    } else {
      this.currentPageSize = this.paginator.pageSize;
    }
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public availableColumnChanged(_items: Item[]): void {
    this.updatePreference();
  }

  /**
   * Drag n'Drop
   */
  public columnDropped(event: CdkDragDrop<string[]>): void {
    const realIndexFrom =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.previousIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    const realIndexTo =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.currentIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    moveItemInArray(this.availableColumnItems, realIndexFrom, realIndexTo);
    this.updatePreference();
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(): void {
    this.isFiltered = false;
    this.sort.sort({ id: null, start: "desc", disableClear: false }); // reset also the top / bottom sort
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
    this.filters = [];
    this.updatePreference();
  }

  public updateSearch(value?: string): void {
    this.globalSearchValue = value;
    if (this.globalSearchValue) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
    this.dataSource.setFilter({
      data: value,
      propertyName: "globalSearch",
      dataType: DataType.COMBINED,
      combinedProperties: ["familyName", "firstname"],
    });
  }

  /**
   * Selection
   */
  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PRACTITIONER_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          availableColumnItems: this.availableColumnItems,
          itemsPerPage: this.dataSource.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
    if (this.dataSource.getAllFilters().length) {
      this.isFiltered = true;
      this.filters = this.dataSource.getAllFilters();
    } else {
      this.isFiltered = false;
    }
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PRACTITIONER_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TableParameter) => {
        if (parameters) {
          // Verification of practitioner rights
          if (!this.isUserAuthorizedToReset) {
            // We delete the password columns after re-verification of practitioner rights
            parameters.availableColumnItems = parameters.availableColumnItems.filter((parameter) => parameter.value !== "password");
          }

          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });

          // Set page size from preference
          this.paginator.pageSize = this.computePageSize(parameters.itemsPerPage);

          this.availableColumnItems = this.availableColumnItems
            .map((itAvailable) => {
              const itPref = parameters.availableColumnItems.find((itPreference) => itPreference.value === itAvailable.value);
              if (!itPref) {
                return itAvailable;
              }
              itAvailable.checked = itPref.checked;
              return itAvailable;
            })
            .sort((a, b) => {
              const aPrefIndex = parameters.availableColumnItems.findIndex((itPreference) => itPreference.value === a.value);
              const bPrefIndex = parameters.availableColumnItems.findIndex((itPreference) => itPreference.value === b.value);
              if (aPrefIndex !== -1 && bPrefIndex !== -1) {
                return aPrefIndex - bPrefIndex; // if exists in DB, keep the order of the db
              } else {
                return aPrefIndex === -1 ? 1 : -1; // if one of the two does not exist, we put it at the end
              }
            });
        }

        if (!this.dataSource.sort && !this.dataSource.filter && !this.globalSearchValue) {
          this.isFiltered = false;
        } else {
          this.isFiltered = true;
        }
        this.setupPaginator();
      });
  }

  public computePageSize(prefPageSize: number): number {
    if (this.isDialog && prefPageSize > 15) {
      return 15;
    } else if (!this.isDialog && prefPageSize > 25) {
      return 25;
    } else {
      return prefPageSize;
    }
  }

  public addUser(): void {
    const userData: DataForUserPage = {
      availableServices: this.availableServices,
      availableOrganizations: this.availableOrganizations,
      preselectedService: this.hs[0]?.asReference,
      preselectedOrganization: this.organization?.asReference ? this.organization.asReference : this.hs[0]?.orgRef,
    };
    this.router.navigate(["/user"], {
      state: { data: userData, mode: FORMS_MODE.CREATE, previousPage: this.isDialog ? "organizations" : "myService" },
    });
    if (this.isDialog) {
      this.dialogRef.close();
    }
  }

  public updateUser(user: Practitioner): void {
    if (!user.caremateId?.value) {
      return;
    }
    this.canUpdateUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        if (v) {
          this.practitionerService
            .getInfo(user.caremateId?.value)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((infos) => {
              const userData: DataForUserPage = {
                availableServices: this.availableServices,
                availableOrganizations: this.availableOrganizations,
                user,
                infos,
              };
              this.router.navigate(["user"], {
                state: { data: userData, mode: FORMS_MODE.UPDATE, previousPage: this.isDialog ? "organizations" : "myService" },
              });
              if (this.isDialog) {
                this.dialogRef.close();
              }
            });
        }
      });
  }

  public openUserListHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.userListPageHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  private canUpdateUser(): Observable<boolean> {
    return this.userService.isAuthorized("practitioner", "PUT").pipe(
      concatMap((auth1) => {
        if (auth1) {
          return this.userService.isAuthorized("dashboard/userInfo", "GET");
        }
        return of(false);
      }),
      concatMap((auth2) => {
        if (auth2) {
          return this.userService.isAuthorized("/user", "GET");
        }
        return of(false);
      })
    );
  }

  public isCurrentUserCanResetPassword(): void {
    this.userService
      .isAuthorized("dashboard/resetPractitionerPassword", "POST")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        return (this.isUserAuthorizedToReset = value);
      });
  }

  public displayConfirmResetModal(user: Practitioner, event: Event): void {
    event.stopPropagation();

    if (!user.caremateId?.value) {
      this.dialog.open(ErrorOnGettingCaremateIDComponent, {
        width: "450px",
        height: "200px",
        data: {
          currentUser: {
            mail: user?.mailAdress?.[0]?.value,
            name: user?.name.family[0],
            firstname: user?.name.given[0],
            caremateId: user.caremateId?.value,
          },
          preference: false,
        },
      });

      return;
    }

    this.dialog.open(ConfirmResetPasswordDialogComponent, {
      width: "450px",
      height: "250px",
      data: {
        currentUser: {
          mail: user?.mailAdress?.[0]?.value,
          name: user?.name.family[0],
          firstname: user?.name.given[0],
          caremateId: user.caremateId?.value,
        },
        preference: false,
      },
    });
  }

  private computeServicesAndOrganizations(): void {
    if (this.organization) {
      this.availableOrganizations = [this.organization].map((o) => o.asReference);
      if (this.organization.isMonitoring()) {
        this.availableServices = this.userService.allMonitoringServices
          .filter((s) => s.providedBy.reference === this.organization.organizationReference)
          .map((s) => s.asReference);
      } else {
        this.availableServices = this.userService.allServices
          .filter((s) => s.providedBy.reference === this.organization.organizationReference)
          .map((s) => s.asReference);
      }
    } else {
      this.availableOrganizations = this.userService.isMonitoringUser
        ? this.orgaService.availableMonitoringOrgs().map((o) => o.asReference)
        : this.orgaService.availableOrganizations().map((o) => o.asReference);
      this.availableServices = this.userService.isMonitoringUser
        ? this.departementService.availableMonitoringServices().map((s) => s.asReference)
        : this.departementService.availableServices().map((s) => s.asReference);
    }
  }
}
