<mat-list>
  <ng-container *ngFor="let element of dataDrugs; let i = index">
    <mat-list-item
      class="drug-item p-0"
      [ngStyle]="loading ? { display: 'none' } : {}"
      [ngClass]="!toggleExpansion[i] ? 'border-bottom' : ''"
    >
      <button mat-icon-button (click)="toggleExpansion[i] = !toggleExpansion[i]">
        <mat-icon *ngIf="!toggleExpansion[i]">expand_more</mat-icon>
        <mat-icon *ngIf="toggleExpansion[i]">expand_less</mat-icon>
      </button>
      <div class="drug-details">
        <h3 matLine>
          {{ element.entityData.prescriptionName ?? element.entityData.name }}
        </h3>
        <p matLine style="font-style: italic">
          <ng-container *ngIf="element?.entityData?.performer?.reference === 'H'">({{ "drugs.isHospital" | translate }})</ng-container>
          <ng-container *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length">
            {{ "drugSchema.withCycle" | translate }}
          </ng-container>
          <ng-container *ngIf="element.entityData?.stepwiseSchema && element.entityData?.stepwiseSchema?.stepwises.length">
            {{ "drugSchema.withStepwise" | translate }}
          </ng-container>
          <ng-container *ngIf="element.entityData?.frequency?.asNecessary">
            {{ "forms.asNecessary" | translate }}
          </ng-container>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.DESCRIPTION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.DESCRIPTION)"
          >
            info
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.SIDEEFFECT"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.SIDEEFFECT)"
          >
            error
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugKnowledge : drugsKnowledges : KNOW_DOC_CATEGORY.RECOMMENDATION"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="showKnowledges(element.entityData, KNOW_DOC_CATEGORY.RECOMMENDATION)"
          >
            bookmark
          </mat-icon>
          <mat-icon
            *ngIf="knowledgesLoaded && element.entityData | hasDrugInfoLink : drugsInfo"
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            (click)="onURL(element.entityData)"
          >
            link
          </mat-icon>
        </p>
        <p matLine [innerHTML]="element.entityData | timingDisplay"></p>
        <p style="font-weight: 450" matLine>{{ element?.entityData?.comment }}</p>
        <p matLine>
          {{ element.entityData.frequency.boundsPeriod.start | date : "dd/MM/yyyy" }}
          -
          {{
            !isInfinite(element.entityData.frequency.boundsPeriod.end)
              ? (element.entityData.frequency.boundsPeriod.end | date : "dd/MM/yyyy")
              : ""
          }}
          <mat-icon *ngIf="isInfinite(element.entityData.frequency.boundsPeriod.end)" class="custom-icon-small vertical-align">
            all_inclusive
          </mat-icon>
        </p>
      </div>
      <ng-container *ngTemplateOutlet="mobileActionMenu; context: { element: element }"></ng-container>
      <!-- Menu on smartphone  -->
      <ng-template #mobileActionMenu let-element="element">
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            matTooltip="{{ 'btn.update' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="updateDrug(element)"
            class="custom-icon-w"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-w baseline-align">create</mat-icon>
            <span>{{ "btn.update" | translate }}</span>
          </button>
          <button
            mat-menu-item
            matTooltip="{{ 'btn.removeDrug' | translate }}"
            *ngIf="('entity' | isAuthorized : 'POST' | async) === true"
            (click)="deleteDrug(element)"
            class="custom-icon-w"
            aria-label="icon button with a trash icon"
          >
            <mat-icon class="custom-icon-w baseline-align">delete</mat-icon>
            <span>{{ "btn.removeDrug" | translate }}</span>
          </button>
          <button
            mat-menu-item
            matTooltip="{{ 'btn.history' | translate }}"
            *ngIf="historic && historic.length"
            (click)="showHistoricLocal(element)"
            class="custom-icon-w"
            aria-label="icon button with a historic icon"
          >
            <mat-icon class="custom-icon-w baseline-align">history</mat-icon>
            <span>{{ "btn.history" | translate }}</span>
          </button>
          <button
            mat-menu-item
            class="disabledButton"
            *ngIf="element.entityData?.cycle && element.entityData?.cycle?.cycle.length"
            [disabled]="isCycleInPause(element.entityData.cycle)"
          >
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="!isCycleInPause(element.entityData.cycle) && ('entity' | isAuthorized : 'POST' | async) === true"
              matTooltip="{{ 'drugSchema.pause' | translate }}"
              (click)="OnPauseCycle(element)"
            >
              pause
            </mat-icon>
            <mat-icon
              class="custom-icon-w baseline-align"
              *ngIf="isCycleInPause(element.entityData.cycle)"
              matTooltip="{{
                ('drugSchema.pauseInfo' | translate) +
                  ' ' +
                  getFormatDate(element.entityData.cycle.pauseDate[element.entityData.cycle.pauseDate.length - 1])
              }}"
            >
              play_arrow
            </mat-icon>
            <span>{{ "drugSchema.pause" | translate }}</span>
          </button>
          <button
            mat-menu-item
            class="custom-icon-w"
            matTooltip="{{ 'drugIntake.administrations' | translate }}"
            (click)="showMore(element)"
            aria-label="icon button with a add icon"
          >
            <mat-icon class="custom-icon-w baseline-align">zoom_in</mat-icon>
            <span>{{ "drugIntake.administrations" | translate }}</span>
          </button>
        </mat-menu>

        <!-- Bouton principal pour ouvrir le menu -->
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </ng-template>
    </mat-list-item>
    <mat-list [ngClass]="toggleExpansion[i] ? 'border-bottom' : ''">
      <ng-container *ngIf="toggleExpansion[i] && drugNotifications[element._id]?.length">
        <h3>{{ "drugIntake.administrations" | translate }}</h3>
        <mat-list-item class="drug-item pb-1 pl-05" *ngFor="let notification of drugNotifications[element._id]">
          <div class="drug-details">
            <h3 matLine></h3>
            <p matLine>
              <mat-icon class="fail vertical-middle" *ngIf="notification.status === NOTIFICATION_STATUS.REJECTED">cancel</mat-icon>
              <mat-icon
                class="success vertical-middle"
                *ngIf="notification.status === NOTIFICATION_STATUS.ACCEPTED && notification.quantityTaken === notification.quantity"
              >
                done
              </mat-icon>
              <mat-icon
                class="warn vertical-middle"
                *ngIf="notification.status === NOTIFICATION_STATUS.ACCEPTED && notification.quantityTaken !== notification.quantity"
              >
                done
              </mat-icon>
              <mat-icon class="warn vertical-middle" *ngIf="notification.status === NOTIFICATION_STATUS.NONE">question_mark</mat-icon>
              <mat-icon class="fail vertical-middle" *ngIf="notification.status === NOTIFICATION_STATUS.DELETED">delete</mat-icon>
              <mat-icon
                class="vertical-middle"
                *ngIf="notification.device?.reference"
                color="primary"
                [matTooltip]="notification.device?.reference"
              >
                bluetooth
              </mat-icon>
              {{ notification.time | date : "dd/MM/yyyy" }} -
              <span *ngIf="!notification.moment || notification.status !== NOTIFICATION_STATUS.NONE">
                {{
                  notification?.administrationTime
                    ? (notification.administrationTime | date : "HH:mm")
                    : (notification.time | date : "HH:mm")
                }}
              </span>
              <span *ngIf="notification.moment && notification.status === NOTIFICATION_STATUS.NONE">
                {{ "mydrugs." + notification.moment | translate }}
              </span>
              <mat-icon *ngIf="notification?.unscheduledIntake" class="vertical-middle" color="primary">event_busy</mat-icon>
            </p>
            <p matLine>{{ notification?.quantityTaken ? (notification.quantityTaken | displayQuantity) : "" }}</p>
            <p matLine>{{ notification.comment }}</p>
          </div>
          <button
            mat-icon-button
            (click)="onAddIntake(element, notification)"
            style="padding-right: 1rem !important"
            *ngIf="('dashboard/drugIntake' | isAuthorized : 'POST' | async) === true && notification.status === NOTIFICATION_STATUS.NONE"
          >
            <mat-icon color="primary">edit</mat-icon>
          </button>
        </mat-list-item>
      </ng-container>
      <mat-list-item class="drug-item pb-1 pl-05" *ngIf="toggleExpansion[i]">
        <div class="drug-details">
          <h3 matLine></h3>
          <p matLine *ngIf="!drugNotifications[element._id]?.length" style="text-align: center">
            {{ "drugIntake.noScheduledIntake" | translate }}
          </p>
          <p matLine>
            {{ "drugIntake.addIntake" | translate }}
          </p>
        </div>
        <button
          mat-icon-button
          (click)="onAddUnscheduledIntake(element)"
          style="padding-right: 1rem !important"
          *ngIf="('dashboard/drugIntake' | isAuthorized : 'POST' | async) === true"
        >
          <mat-icon color="primary">add</mat-icon>
        </button>
      </mat-list-item>
      <div class="unscheduledIntake"></div>
    </mat-list>
  </ng-container>
</mat-list>
