import { LocationStrategy } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { WarningBoxModalComponent } from "src/app/components/warning-box-modal/warning-box-modal.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { IKnowMedia, IKnowledgeBase, KNOW_DOC_CATEGORY, KNOW_DOC_TYPE, MEDIA_CATEGORY } from "src/app/models/knowledge.interface";
import { IQuestionnaire, SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { KnowledgeComparisonDialogComponent } from "src/app/pages/knowledge-media-editor/knowledge-comparison-dialog/knowledge-comparison-dialog.component";
import { KnowledgeOverviewComponent } from "src/app/pages/knowledge-media-editor/knowledge-overview/knowledge-overview.component";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { LanguagesService } from "src/app/providers/languages.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-knowledge-media-editor",
  templateUrl: "./knowledge-media-editor.component.html",
  styleUrls: ["./knowledge-media-editor.component.scss"],
})
export class KnowledgeMediaEditorComponent implements OnInit, OnDestroy {
  public isMngtAuthorized: boolean;
  public docCategory = KNOW_DOC_CATEGORY;
  public isNewMedia: boolean;
  public media: IKnowMedia;
  public draftKnowledge: IKnowledgeBase;
  public publishedKnowledge: IKnowledgeBase;
  public availableLangs: ITranslation[] = [];
  public availableTypes = [1, 2, 3];
  public saveInterval: ReturnType<typeof setInterval>;
  public lastSaveDate: number;
  public readOnly: boolean;
  private allSpecificQuestionnaires: IQuestionnaire[] = [];
  public filteredSpecificQuestionnaires: IQuestionnaire[] = [];
  public linkedSpecificQuestionnaires: string[] = [];
  private questUnlinkedByLangSwitch: IQuestionnaire[] = [];
  public isAlreadySaving = false;
  public joditConfig = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    minHeight: 450,
    disablePlugins: "file, media",
    style: { font: "16px Arial" },
    container: {
      style: { border: "solid 2px red" },
    },
    readonly: false,
  };
  public specificUse: SPECIFIC_USE;
  public SPECIFIC_USE = SPECIFIC_USE;
  public editorForm: UntypedFormGroup = this.fb.group({
    category: ["", [Validators.required]],
    content: this.fb.array([]),
    description: ["", []],
    importanceLevel: ["", []],
    label: ["", [Validators.required]],
    language: ["", [Validators.required]],
    type: ["", [Validators.required]],
    specificQuestionnaire: [[], []],
    visibleForPatient: [true, []],
    visibleForMonitoring: [false, []],
  });

  public isLoading: boolean;
  public currentSlide = 0;
  public slidesList;
  private previousLocation: string;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  protected onDestroy$ = new Subject<void>();

  get category(): MEDIA_CATEGORY {
    return this.editorForm.get("category").value;
  }
  set category(value: MEDIA_CATEGORY) {
    this.editorForm.get("category").setValue(value);
  }

  get content(): UntypedFormArray {
    return this.editorForm.get("content") as UntypedFormArray;
  }

  get description(): string {
    return this.editorForm.get("description").value;
  }
  set description(value: string) {
    this.editorForm.get("description").setValue(value);
  }

  get importanceLevel(): number {
    return this.editorForm.get("importanceLevel").value;
  }
  set importanceLevel(value: number) {
    this.editorForm.get("importanceLevel").setValue(value);
  }

  get label(): string {
    return this.editorForm.get("label").value;
  }
  set label(value: string) {
    this.editorForm.get("label").setValue(value);
  }

  get language(): string {
    return this.editorForm.get("language").value;
  }
  set language(value: string) {
    this.editorForm.get("language").setValue(value);
  }

  get type(): KNOW_DOC_TYPE {
    return this.editorForm.get("type").value;
  }
  set type(value: KNOW_DOC_TYPE) {
    this.editorForm.get("type").setValue(value);
  }

  get specificQuestionnaire(): string[] {
    return this.editorForm.get("specificQuestionnaire").value;
  }
  set specificQuestionnaire(value: string[]) {
    this.editorForm.get("specificQuestionnaire").setValue(value);
  }

  get visibleForPatient(): boolean {
    return this.editorForm.get("visibleForPatient").value;
  }
  set visibleForPatient(value: boolean) {
    this.editorForm.get("visibleForPatient").setValue(value);
  }

  get visibleForMonitoring(): boolean {
    return this.editorForm.get("visibleForMonitoring").value;
  }
  set visibleForMonitoring(value: boolean) {
    this.editorForm.get("visibleForMonitoring").setValue(value);
  }

  get mediaId(): string {
    return this.media._id;
  }

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private knowledgeService: KnowledgeService,
    private location: LocationStrategy,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private questionnaireService: QuestionnairesService,
    public helpData: HelpData,
    private userService: UserService,
    protected languagesService: LanguagesService
  ) {
    // Disable browser back button
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.media = this.router.getCurrentNavigation()?.extras?.state?.media;
    this.draftKnowledge = this.router.getCurrentNavigation()?.extras?.state?.draftKnowledge;
    if (!this.draftKnowledge) {
      this.router.navigate(["/knowledgeList"]);
    }
    this.publishedKnowledge = this.router.getCurrentNavigation()?.extras?.state?.publishedKnowledge;
    this.readOnly = this.router.getCurrentNavigation()?.extras?.state?.readOnly;
    this.isNewMedia = this.router.getCurrentNavigation()?.extras?.state?.isNewMedia;
    this.previousLocation = this.router.getCurrentNavigation()?.extras?.state?.previousLocation;
  }

  ngOnInit(): void {
    this.isLoading = true;

    // Check knowledge management authorization
    this.userService
      .isAuthorized("dashboard/knowledge", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isMngtAuthorized = value;
      });

    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => (this.availableLangs = languages));

    // detect the type of specific Questionnaire to link (quizz or consent) based on the knowledge category
    this.setupQuestionnairesLists();
    this.isLoading = false;
    if (this.readOnly) {
      this.joditConfig.readonly = true;
      this.editorForm.get("category").disable();
      this.editorForm.get("description").disable();
      this.editorForm.get("importanceLevel").disable();
      this.editorForm.get("label").disable();
      this.editorForm.get("language").disable();
      this.editorForm.get("type").disable();
      this.editorForm.get("specificQuestionnaire").disable();
      this.editorForm.get("visibleForPatient").disable();
      this.editorForm.get("visibleForMonitoring").disable();
    }

    if (!this.readOnly) {
      this.autoSave();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.saveInterval);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupQuestionnairesLists() {
    if (this.draftKnowledge?.documentCategory === KNOW_DOC_CATEGORY.CONSENT) {
      this.specificUse = SPECIFIC_USE.CONSENT;
    } else {
      this.specificUse = SPECIFIC_USE.QUIZ;
    }
    const lang = this.media?.language || "fr";
    this.questionnaireService.getSpecificQuestionnaireListWithLang(lang, this.specificUse).subscribe((specificQuestionnaires) => {
      this.allSpecificQuestionnaires = specificQuestionnaires;
      this.setupFilteredSpecificQuestionnaires();
      this.initialiseForm();
    });
  }

  public initialiseForm(): void {
    if (this.media) {
      this.findLinkedSpecificQuestionnaires();
      this.editorForm.patchValue({
        category: this.media.category,
        description: this.media.description ? this.media.description : "",
        importanceLevel: this.media.importanceLevel ? this.media.importanceLevel : null,
        label: this.media.label,
        language: this.media.language,
        type: this.media.type,
        specificQuestionnaire: this.linkedSpecificQuestionnaires,
        visibleForPatient: !this.media.notVisibleForPatient,
        visibleForMonitoring: this.media.visibleForMonitoring,
      });
    }
    this.editorForm = this.knowledgeService.slideItUp(this.editorForm, this.media);
    this.slidesList = Object.keys(this.content.getRawValue());

    this.editorForm.get("language").valueChanges.subscribe((lang) => {
      // Changing lang automatically unlink all the specificQuestionnaires of the previous language:
      const quests = this.allSpecificQuestionnaires.filter((q) => this.specificQuestionnaire.includes(q.identifier[0].value));
      this.questUnlinkedByLangSwitch = this.questUnlinkedByLangSwitch.concat(quests);
      this.editorForm.get("specificQuestionnaire").patchValue([]);
      this.linkedSpecificQuestionnaires = [];
      // And we need a new updated list of specificQuestionnaires to choose from:
      this.questionnaireService.getSpecificQuestionnaireListWithLang(lang, this.specificUse).subscribe((specificQuestionnaires) => {
        this.allSpecificQuestionnaires = specificQuestionnaires;
        this.setupFilteredSpecificQuestionnaires();
      });
    });
  }

  /**
   * The trick here is to not includes the specificQuestionnaires linked in the published version but
   * unlinked in the draft version.
   */
  private findLinkedSpecificQuestionnaires() {
    this.linkedSpecificQuestionnaires = [];
    if (this.media && this.media.specificQuestionnaire && this.media.specificQuestionnaire.length) {
      this.linkedSpecificQuestionnaires = this.filteredSpecificQuestionnaires
        .filter((q) => this.media.specificQuestionnaire.includes(q.identifier[0].value))
        .map((q) => q.identifier[0].value);
    }
    return this.linkedSpecificQuestionnaires;
  }

  /**
   * Filtered specificQuestionnaires should also contain the specificQuestionnaires linked by this media (
   * either by the draft or the published version)
   */
  private setupFilteredSpecificQuestionnaires() {
    this.filteredSpecificQuestionnaires = this.allSpecificQuestionnaires.filter(
      (q: IQuestionnaire) =>
        (!q.knowledgeId && !q.mediaId) ||
        ((q.knowledgeId === this.draftKnowledge?.identifier.value || q.knowledgeId === this.publishedKnowledge?.identifier.value) &&
          q.mediaId === this.media?.identifier.value)
    );
  }

  public setImportanceLevel(level: number): void {
    if (this.importanceLevel === level) {
      this.editorForm.get("importanceLevel").setValue(0);
    } else {
      this.editorForm.get("importanceLevel").setValue(level);
    }
  }

  public saveDraft(close?: boolean): void {
    this.isAlreadySaving = true;
    if (!this.editorForm.pristine) {
      let questionnairesToUnlink: string[] = [];
      const selectedSpecificQuestionnaires = this.specificQuestionnaire as string[];
      if (this.media) {
        // editing existing media
        const media = this.draftKnowledge.medias.find((m) => m._id === this.mediaId);
        media.category = this.category;
        media.content = this.knowledgeService.aggregateSlides(this.editorForm);
        media.description = this.description;
        media.importanceLevel = this.importanceLevel;
        media.label = this.label;
        media.language = this.language;
        media.type = this.type;
        if (media.specificQuestionnaire && media.specificQuestionnaire) {
          questionnairesToUnlink = media.specificQuestionnaire.filter((q: string) => !selectedSpecificQuestionnaires.includes(q));
        }
        media.specificQuestionnaire = selectedSpecificQuestionnaires;
        // delete description key if empty to avoid detecting false difference between published and draft version
        if (!media.description) {
          delete media.description;
        }
        media.notVisibleForPatient = !this.visibleForPatient;
        media.visibleForMonitoring = this.visibleForMonitoring;
        this.media = media;
      } else {
        // adding new media
        this.media = {
          identifier: null,
          category: this.category,
          content: this.knowledgeService.aggregateSlides(this.editorForm),
          description: this.description,
          importanceLevel: this.importanceLevel,
          label: this.label,
          language: this.language,
          type: this.type,
          specificQuestionnaire: this.specificQuestionnaire,
          notVisibleForPatient: !this.visibleForPatient,
          visibleForMonitoring: this.visibleForMonitoring,
          _id: undefined,
        };
        this.draftKnowledge.medias.push(this.media);
      }

      // update the (last) author of the knowledge before saving it

      this.draftKnowledge.author = {
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      };

      if (this.draftKnowledge?._id) {
        // update existing draft
        this.knowledgeService.updateDraft(this.draftKnowledge).subscribe(
          async (k) => {
            this.draftKnowledge = k;
            // update this.media to get the media with it's _id for next update within the view
            if (this.isNewMedia) {
              this.media = this.draftKnowledge.medias[this.draftKnowledge.medias.length - 1];
            }
            await this.linkAndUnlinkQuest(questionnairesToUnlink);
            if (close) {
              await this.close();
            }
            this.isAlreadySaving = false;
          },
          (err) => {
            FileLogger.error("KnowledgeMediaEditorComponent", "updateDraft", err);
            if (err.code === 0) {
              this.showErrorTooBig();
            }
            this.isAlreadySaving = false;
          }
        );
      } else {
        // create new draft in db
        this.knowledgeService.createDraft(this.draftKnowledge).subscribe(
          async (k) => {
            this.draftKnowledge = k;
            // update this.media to get the media with it's _id for next update within the view
            if (this.isNewMedia) {
              this.media = this.draftKnowledge.medias[this.draftKnowledge.medias.length - 1];
            }
            await this.linkAndUnlinkQuest(questionnairesToUnlink);
            if (close) {
              await this.close();
            }
            this.isAlreadySaving = false;
          },
          (err) => {
            FileLogger.error("KnowledgeMediaEditorComponent", "createDraft", err);
            if (err.code === 0) {
              this.showErrorTooBig();
            }
            this.isAlreadySaving = false;
          }
        );
      }

      this.lastSaveDate = Date.now();
    }
  }

  private async linkAndUnlinkQuest(questionnairesToUnlink: string[]) {
    const allUpdatePromises = [];
    for (const quiz of this.specificQuestionnaire) {
      const currentQuiz = this.allSpecificQuestionnaires.find((q) => q.identifier[0].value === quiz) as IQuestionnaire;
      allUpdatePromises.push(
        this.questionnaireService.linkQuestionnaireToMedia(
          currentQuiz.identifier[0].value,
          currentQuiz.version,
          this.draftKnowledge.identifier.value,
          this.media.identifier.value
        )
      );
    }
    const publishedMedia = this.isNewMedia ? null : this.publishedKnowledge?.medias.find((element) => element._id === this.mediaId);
    if (publishedMedia) {
      const mediaSpecificQuestionnaires = publishedMedia.specificQuestionnaire;
      // We must not unlink questionnaires linked in the published version when we save the draft
      // They should only be unlinked when we save the published version with new questionnaires
      questionnairesToUnlink = questionnairesToUnlink.filter((qId) => !mediaSpecificQuestionnaires.includes(qId));
    }
    for (const questId of questionnairesToUnlink) {
      let questToUnlink = this.allSpecificQuestionnaires.find((q) => q.identifier[0].value === questId);
      // if we did not find the quiz, it could be because it was a different lang quiz that was unlinked by
      // a language switch
      if (!questToUnlink) {
        questToUnlink = this.questUnlinkedByLangSwitch.find((q) => q.identifier[0].value === questId);
      }
      if (questToUnlink) {
        allUpdatePromises.push(
          this.questionnaireService.linkQuestionnaireToMedia(questToUnlink.identifier[0].value, questToUnlink.version, "", "")
        );
      }
    }
    await Promise.all(allUpdatePromises);
  }

  public autoSave(): void {
    this.saveInterval = setInterval(() => {
      if (this.editorForm.pristine || !this.isMngtAuthorized || this.readOnly) {
        return;
      }
      this.saveDraft();
    }, 60000);
  }

  public async close(): Promise<void> {
    await this.router.navigate(["/knowledgeDetails", { id: this.draftKnowledge.identifier.value }], {
      state: { previousLocation: this.previousLocation },
    });
  }

  /**
   * Open Modal to show an overview of the knowledge mobileApp page
   */
  public showOverview(): void {
    this.dialog.open(KnowledgeOverviewComponent, {
      id: "smartphone",
      panelClass: "smartphone-content",
      data: { media: this.media },
    });
  }

  public compareVersion(): void {
    // find published version of the media for the comparison draft /published
    // if we don't have both, comparison is not necessary
    const publishedMedia = this.publishedKnowledge?.medias.find((element) => element._id === this.mediaId);

    const dialog = this.dialog.open(KnowledgeComparisonDialogComponent, {
      data: {
        publishedMedia: {
          category: publishedMedia.category,
          content: publishedMedia.content,
          description: publishedMedia.description,
          importanceLevel: publishedMedia.importanceLevel,
          label: publishedMedia.label,
          language: publishedMedia.language,
          type: publishedMedia.type,
          specificQuestionnaire: publishedMedia.specificQuestionnaire,
          notVisibleForPatient: publishedMedia.notVisibleForPatient,
          visibleForMonitoring: publishedMedia.visibleForMonitoring,
        },
        draftMedia: {
          category: this.category,
          content: this.knowledgeService.aggregateSlides(this.editorForm),
          description: this.description,
          importanceLevel: this.importanceLevel,
          label: this.label,
          language: this.language,
          type: this.type,
          specificQuestionnaire: this.specificQuestionnaire,
          notVisibleForPatient: !this.visibleForPatient,
          visibleForMonitoring: this.visibleForMonitoring,
        },
      },
      width: "100%",
    });

    dialog.afterClosed().subscribe((form: UntypedFormGroup) => {
      if (form) {
        this.editorForm = form;
        this.slidesList = Object.keys(this.content.getRawValue());
        this.editorForm.markAsTouched();
        this.currentSlide = 0;
      }
    });
  }

  public createSpecificQuestionnaire(): void {
    this.saveDraft();
    this.router.navigate(["/questionnaireEditor"], {
      state: {
        specificUse: 1,
        knowledgeId: this.draftKnowledge.identifier.value,
      },
    });
  }

  public openMediaEditorHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.knowledgeMediaEditorPageHelp },
        disableClose: false,
      },
      { maxWidth: "80vw" }
    );
  }

  public showErrorTooBig(): void {
    this.dialog
      .open(WarningBoxModalComponent, {
        data: {
          text: this.translate.instant("knowledgebase.tooBig"),
          type: "error",
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => (this.isAlreadySaving = false));
  }

  /**
   * Update the page number
   * @param newSlideId (number) the new slide's number
   */
  public onSlideChanged(newSlideId: number): void {
    this.currentSlide = newSlideId;
  }

  /**
   * Create new empty media slide
   */
  public createNewSlide(): void {
    this.content.push(
      this.fb.group({
        title: ["", Validators.required],
        text: ["", Validators.required],
        showInSummary: [true],
      })
    );
    this.slidesList = Object.keys(this.content.getRawValue());
    this.currentSlide = this.slidesList.length - 1;
  }

  /**
   * delete media slide and mark form as dirty in order to enable the save button
   */
  public removeSlide(index: number): void {
    this.editorForm.markAsDirty();
    this.isLoading = true;
    this.content.removeAt(index);
    this.slidesList = Object.keys(this.content.getRawValue());
    this.currentSlide = index - 1;
    this.isLoading = false;
  }
}
