import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import {
  DataSourceBackendParam,
  DocumentDataSourceBackendParam,
  PatientDataSourceBackendParam,
} from "../models/data-sources-backend-param.interface";
import { Filter } from "../models/filter.interface";
import { FileLogger } from "./fileLogger";
import { FilterHelper } from "./filterHelper";

/**
 * This abstract class is to be extended by the data sources classes of the tables to be managed by the backend.
 * Only the method protected data(param: DataSourceBackendParam) must be yet implemented.
 */
export abstract class MatTableDataSourceBackendExtended<T> extends DataSource<T> {
  // Behavior subject that contains the last value of the data
  protected dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  private filterHelper = new FilterHelper();

  /**
   * This method will be called once by the Data Table at table bootstrap time.
   * The Data Table expects this method to return an Observable, and the values
   * of that observable contain the data that the Data Table needs to display.
   * It is use automatically by mat-table via dataSource
   * @param collectionViewer provides an Observable that emits information about what data is being
   * displayed (the start index and the end index)
   * @returns data that the Data Table needs to display
   */
  connect(_collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  /**
   * This method is called once by the data table at component destruction time.
   * In this method, we are going to complete any observables that we have created
   * internally in this class, in order to avoid memory leaks.
   * We are going to complete both the dataSubject and the loadingSubject,
   * which are then going to trigger the completion of any derived observables.
   * @param collectionViewer provides an Observable that emits information about what data is being
   * displayed (the start index and the end index)
   */
  disconnect(_collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Method to be implemented that creates an observable that fetches data from the backend.
   * This method should not be launched as such. It just allows you to define an observable
   * that will be launched in the method loadData.
   * @param param parameter send to the route
   */
  protected abstract data(param: DataSourceBackendParam): Observable<T[]>;

  /**
   * This method launch the method data and manage the Behavior Subject to make the connection with the mat-table
   * @param param parameter send to the route
   */
  public loadData(param: DataSourceBackendParam | PatientDataSourceBackendParam | DocumentDataSourceBackendParam): void {
    // begin to load data
    this.loadingSubject.next(true);
    this.data(param)
      .pipe(
        catchError((err) => {
          FileLogger.error("MatTableDataSourceBackendExtended", "loadData", err);
          return of([]);
        })
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dataSubject.next(data);
        this.loadingSubject.next(false);
      });
  }

  public clearData(): void {
    this.dataSubject.next(null);
  }

  public setFilter(filter: Filter): void {
    this.filterHelper.setFilter(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.filterHelper.getFilter(propertyName);
  }

  public getAllFilters(): Filter[] {
    return this.filterHelper.filters;
  }

  public clearFilter(): void {
    this.filterHelper.clearFilter();
  }
}
