<mat-form-field>
  <mat-label> <span class="fa fa-search mr-5"></span>{{ placeholder }} </mat-label>
  <input
    type="search"
    matInput
    [placeholder]="placeholder"
    [value]="searchValue"
    (keyup)="updateSearch($event.target.value)"
    aria-label="Search"
  />
  <button *ngIf="searchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
