import { Location } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "app-install-comunicare-page",
  templateUrl: "./install-comunicare-page.component.html",
  styleUrls: ["./install-comunicare-page.component.scss"],
})
export class InstallComunicarePageComponent {
  constructor(private location: Location) {}
  public back(): void {
    this.location.back();
  }
}
