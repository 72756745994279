import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ResponsiveService } from "./responsive.service";

@Injectable({
  providedIn: "root",
})
export class ResponsiveDialogService {
  constructor(private dialog: MatDialog, private responsiveService: ResponsiveService) {}

  open<T>(
    component: any,
    commonConfig: Partial<MatDialogConfig<any>> = {},
    configForDesktop: Partial<MatDialogConfig<any>> = {},
    configForHandset: Partial<MatDialogConfig<any>> = {}
  ): MatDialogRef<T> {
    const isMobile = this.responsiveService.isMobile;
    const config: MatDialogConfig = isMobile
      ? {
          width: "100vw",
          minWidth: "100vw",
          maxWidth: "100vw",
          height: "100vh",
          minHeight: "100vh",
          maxHeight: "100vh",
          panelClass: "full-screen-dialog",
          ...commonConfig,
          ...configForHandset,
        }
      : {
          height: "",
          maxHeight: "80vh", // must match the css maxHeight value
          minHeight: "",
          width: "",
          minWidth: "",
          maxWidth: "",
          panelClass: "desktop-screen-dialog",

          ...commonConfig,
          ...configForDesktop,
        };
    return this.dialog.open<T>(component, config);
  }
}
