import { Component, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, of } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { UiFileDropComponent } from "src/app/components/shared/ui-file-drop/ui-file-drop.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { AppError, ERROR_MSG } from "src/app/models/app-error.interface";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { IObservationParam } from "src/app/models/patientConfig.interface";
import { IRule } from "src/app/models/rule.interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { RuleAlertService } from "src/app/providers/rule-alert.service";

@Component({
  selector: "app-import-obs-and-rule",
  templateUrl: "./import-obs-and-rule.component.html",
  styleUrls: ["./import-obs-and-rule.component.scss"],
})
export class ImportObsAndRuleComponent {
  @ViewChild("uiFileDrop", { static: false }) uiFileDrop: UiFileDropComponent;
  private obsDef: IObservationDefinition;
  private obsParam: IObservationParam;
  private obsRule: IRule;
  public hasError = false;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private observationsService: ObservationsService,
    private ruleAlertService: RuleAlertService,
    private dialogRef: MatDialogRef<ImportObsAndRuleComponent>
  ) {}

  /**
   * Reads the given file and changes it to the wanted interface
   * @param file The selected file
   * @param type The type in wich the file needs to convert
   */
  public setFile(file: File, type: "obsDef" | "obsParam" | "obsRule"): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      try {
        if (type === "obsDef") {
          const obsDefString = fileReader.result as string;
          this.obsDef = JSON.parse(obsDefString) as IObservationDefinition;
        } else if (type === "obsParam") {
          const obsParamString = fileReader.result as string;
          this.obsParam = JSON.parse(obsParamString) as IObservationParam;
        } else if (type === "obsRule") {
          const obsRuleString = fileReader.result as string;
          this.obsRule = JSON.parse(obsRuleString) as IRule;
        }
      } catch (error) {
        this.hasError = true;
        this.snackBar.open(`❗${this.translateService.instant("page.careplanEditor.tabs.observations.fileErrors.invalidFormat")}`, "OK", {
          duration: 5000,
        });
        FileLogger.error("ImportObsAndRuleComponent", `Error while importing ${type} file.`);
      }
    };
    fileReader.readAsText(file);
  }

  /**
   * Checks if the required files are given and saves them
   */
  public saveFiles(): void {
    if (!this.obsDef && !this.obsParam) {
      this.snackBar.open(this.translateService.instant("page.careplanEditor.tabs.observations.fileErrors.missingRequired"), "OK", {
        duration: 5000,
      });
      return;
    }

    const observationDef$ = this.observationsService.createObservationDef(this.obsDef, this.obsParam).pipe(
      catchError((error: AppError) => {
        if (error.message === ERROR_MSG.INVALID_DATA) {
          this.snackBar.open(`❗${this.translateService.instant("page.careplanEditor.tabs.observations.fileErrors.invalidFormat")}`, "OK", {
            duration: 5000,
          });
        }
        FileLogger.error("ImportObsAndRuleComponent", "Error while creating observation definition and parameter", error.message);
        return of(false);
      }),
      first()
    );

    const observationRule$ = this.obsRule
      ? this.ruleAlertService.createRule(this.obsRule).pipe(
          catchError((error: AppError) => {
            if (error.message === ERROR_MSG.INVALID_DATA) {
              this.snackBar.open(
                `❗${this.translateService.instant("page.careplanEditor.tabs.observations.fileErrors.invalidFormat")}`,
                "OK",
                {
                  duration: 5000,
                }
              );
            }
            FileLogger.error("ImportObsAndRuleComponent", "Error while creating observation rule", error.message);
            return of(false);
          }),
          first()
        )
      : of(true);

    forkJoin([observationDef$, observationRule$])
      .pipe(first())
      .subscribe(([obsDef, obsRule]) => {
        if (obsDef && obsRule) {
          this.snackBar.open(`✅ ${this.translateService.instant("common.success")}`, undefined, {
            duration: 3000,
          });
          this.dialogRef.close(true);
        } else {
          return;
        }
      });
  }
}
