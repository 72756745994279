<ng-container>
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="oldIns && newIns">
    <div>
      <h2>
        {{ "dialog.patientAlreadyExists.chooseInsurance" | translate }}
      </h2>
    </div>

    <div>
      <mat-radio-group [(ngModel)]="needChangeInsurance" style="display: flex; flex-direction: column">
        <mat-radio-button [value]="false">
          {{ oldIns }}
        </mat-radio-button>
        <mat-radio-button [value]="true" style="margin-top: 5px">
          {{ newIns }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div style="margin-top: 20px" *ngIf="updateAddress">
    <h2>
      {{ "dialog.patientAlreadyExists.updateAddress" | translate }}
    </h2>

    <div>
      <mat-radio-group [(ngModel)]="needChangeAddress">
        <mat-radio-button [value]="true">
          {{ "common.yes" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" style="margin-left: 5px">
          {{ "common.no" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <br />
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="validate()">
      {{ "drugSchema.confirm" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
