import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ResponsiveService {
  public isHandsetSubject = new BehaviorSubject<boolean>(false);
  public isHandset$: Observable<boolean> = this.isHandsetSubject.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(["(max-width: 600px)"]).subscribe((result) => {
      this.isHandsetSubject.next(result.matches);
    });
  }

  public get isMobile(): boolean {
    return this.isHandsetSubject.getValue();
  }
}
