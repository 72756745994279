/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ChartOptions } from "chart.js";
import * as moment from "moment";
import { BaseChartDirective } from "ng2-charts";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { FHIRHelper } from "../../../app/helpers/FHIRhelper";
import { Activity } from "../../../app/models/careplans.interface";
import {
  COLORS,
  ChartSettings,
  CustomPoint,
  HEIGHT_RATIO,
  IChartColor,
  IChartYAxis,
  IMinmax,
  MAX_CHART_LAST_ITEMS,
  WIDTH_RATIO,
} from "../../../app/models/chart.interface";
import { IObservation, IObservationDefinition, ObservationDefinitionComponent } from "../../../app/models/observations.interface";
import { Observation } from "../../../app/models/observations.model";
import { ITranslation } from "../../../app/models/translation.interface";
import { ObservationsService } from "../../../app/providers/observations.service";
import { SessionService } from "../../../app/providers/session.service";
import { Tools } from "../../helpers/tools";
import "./chartjs-chart-financial.js";

/**
 * Container for observation data in Chart
 */
export class DataChart {
  public data: any;
  public label: string;
  public type: string;
  public pointRadius = 4;
  public pointHoverRadius = 6;
  public yAxisID: string;
  // colors
  public fill: boolean;

  [key: string]: any; // allow any properties

  constructor() {
    this.label = "";
    this.data = new Array<number>();
    this.fill = false;
    this.yAxisID = "";
  }
}

/**
 * Observation Component
 */
@Component({
  selector: "app-care-observation-chart",
  templateUrl: "./observation-chart.component.html",
  styleUrls: ["./observation-chart.component.scss"],
})
export class ObservationChartComponent implements OnInit, OnDestroy {
  private chart: BaseChartDirective;
  @ViewChild("baseChart", { static: false }) set chartCompt(c: BaseChartDirective) {
    if (c) {
      this.chart = c;
      this.reloadChart();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Activity>;
  @ViewChild(MatSort) sort: MatSort;
  public superComponents: IObservationDefinition[];
  @Input() set superComponentsData(def: IObservationDefinition[]) {
    this.superComponents = def;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  public observations: Observation[];
  @Input() set observationsData(o: Observation[]) {
    this.observations = o;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  public chartCustomOpt: ChartSettings;
  @Input() set chartCustomOptData(c: ChartSettings) {
    this.chartCustomOpt = c;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  @Output() addObservation = new EventEmitter<IObservationDefinition>();
  @Output() editObservation = new EventEmitter<Observation>();
  private onDestroy$ = new Subject<void>();

  public colors: string[] = [];
  /**
   * Stores chart colors by LOINC code and associated color configuration.
   */
  public chartColorsByLoinc: { loinc: string; color: IChartColor }[] = [];
  /**
   * Stores previous chart colors, used to avoid regenerating colors when updating the chart.
   */
  public previousChartColors: { loinc: string; color: IChartColor }[] = [];
  public chartLabels: string[] = [];
  public chartData: DataChart[] = [];
  // observations charts parameters
  public chartOptions: ChartOptions = {
    animation: {
      duration: 0, // general animation time
    },
    elements: {
      line: { tension: 0 }, // curve of the line
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    responsive: true,
    scales: {
      yAxes: [],
      xAxes: [
        {
          offset: true,
          type: "time",
          distribution: "linear",
          time: {
            minUnit: "day",
          },
          gridLines: {
            offsetGridLines: true,
            display: false,
            drawBorder: true,
          },
        },
      ],
    },
    tooltips: {
      intersect: false,
      mode: "index",
      callbacks: {
        title(tooltipItem, _data) {
          if (tooltipItem && tooltipItem) {
            const date = tooltipItem[0].label;
            return moment(date).format("LLLL");
          }
          return null;
        },
        label(tooltipItem, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          // Since we use the custom field "timingName" for the line chart,
          // we need to use y and x key while it's not necessary with other types of charts
          // more info : https://stackoverflow.com/questions/37134326/chart-js-passing-objects-instead-of-int-values-as-data

          const point = Tools.isDefined(dataset?.data[tooltipItem.index]?.y)
            ? dataset?.data[tooltipItem.index].y
            : (dataset?.data[tooltipItem.index] as CustomPoint);
          const name = dataset?.label;
          const timing = dataset?.data[tooltipItem.index]?.timingName;

          if (Tools.isDefined(point)) {
            const o = point.o;
            const h = point.h;
            const l = point.l;
            const c = point.c;

            // eslint-disable-next-line max-len
            //  Bugfix [Object, Object] on "Tension Arterielle" values based on observations taken the same day but at different time.

            // eslint-disable-next-line max-len
            //  Before push into datachart.data, We check ALL properties are truphy on observation object (t,o,h,l,c)
            if (!Tools.isValidObject(point)) {
              // We return no datas (the data is hidden)
              return;
            }

            //  Because getValue() function (on observation.model.ts - line 44) return NULL or TRUPHY value (as number)
            //  We could find objects of this type that we do not want in the data graph but still valid because of the date.
            //  Example that we don't want : { date: "2021-07-14T16:19:00+02:00" o: null, h: null, l: null, c: null }.

            if (Tools.isDefined(o) && Tools.isDefined(h) && Tools.isDefined(l) && Tools.isDefined(c)) {
              return name + " Sys :" + o + " Dia :" + c;
            } else {
              return name + " " + point + (timing ? ` (${timing})` : "");
            }
          }
        },
      },
    },
    legend: {
      display: true,
    },
  };
  public chartMeta: Array<Record<string, unknown>> = [];
  public chartH: number;
  public chartW: number;

  constructor(private sessionService: SessionService, public observationsService: ObservationsService, private trans: TranslateService) {
    this.chartW = window.innerWidth * WIDTH_RATIO;
    this.chartH = window.innerHeight * HEIGHT_RATIO;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.chartW = (event.target as Window).innerWidth * 0.8;
  }

  ngOnInit(): void {
    this.trans.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((_e: LangChangeEvent) => {
      // update charts for the right lang
      if (this.observations && this.superComponents && this.chartCustomOpt) {
        this.computeChartData();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Generate random colors
   */
  public getRandomColor(): string {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
   * Build Chart Data and layout
   */
  private computeChartData() {
    if (this.chartColorsByLoinc.length) {
      this.previousChartColors = Tools.clone(this.chartColorsByLoinc);
    }
    this.chartData = [];
    this.chartLabels = [];
    this.chartColorsByLoinc = [];
    this.chartOptions.scales.yAxes = [];

    // first filter on component loinc
    let uniqueArray = this.superComponents
      .filter((obj, index) => {
        const objCpy = JSON.stringify(obj);
        return (
          index ===
          this.superComponents.findIndex((objSecond) => {
            return JSON.stringify(objSecond) === objCpy;
          })
        );
      })
      .reverse();
    // second filter to avoid double for exemple 8310-5-MM and 8310-5
    uniqueArray = uniqueArray.filter((thing, index) => {
      return index === uniqueArray.findIndex((c) => c?.loinc.startsWith(thing.loinc));
    });

    uniqueArray.forEach((superComponent) => {
      if (
        superComponent.loinc === FHIRHelper.CODE_BPCOQ ||
        superComponent.loinc === FHIRHelper.CODE_DY_COVID ||
        superComponent.loinc === FHIRHelper.CODE_BT_MM ||
        superComponent.loinc === FHIRHelper.CODE_PAIN_MM ||
        superComponent.loinc === FHIRHelper.CODE_GONFLEMENT_MM
      ) {
        superComponent.components.forEach((c, index) => {
          this.chartData.push(this.computeChartBarQ(c));
          this.chartOptions.scales.yAxes.push(this.computeLabelsBarQ(c, index));
          this.chartColorsByLoinc.push({ loinc: c.loinc, color: this.getPreviousOrNewColor(c.loinc) });
        });
      } else {
        this.chartColorsByLoinc.push({ loinc: superComponent.loinc, color: this.getPreviousOrNewColor(superComponent.loinc) });

        // temporary hotfix https://comunicare.atlassian.net/browse/CMATE-6670
        if (superComponent.loinc.startsWith("55284-4")) {
          superComponent.chartType = "candlestick";
        }
        switch (superComponent.chartType) {
          case "line":
            this.chartData.push(this.computeChartLine(superComponent));
            this.chartOptions.scales.yAxes.push(this.computeLabelsLine(superComponent));
            break;
          case "range":
          case "bar":
            this.chartData.push(this.computeChartBar(superComponent));
            this.chartOptions.scales.yAxes.push(this.computeLabelsBar(superComponent));
            break;
          case "candlestick":
            this.chartData.push(this.computeChartCandlestick(superComponent));
            this.chartOptions.scales.yAxes.push(this.computeLabelsCandlestick(superComponent));
            break;
          default:
            throw new Error("this component use an chartType we are not handle");
        }
      }
    });
    this.chartLabels = this.computeDateLabels(this.observations);
    this.reloadChart();
  }

  public computeChartLine(superComponent: IObservationDefinition): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "line";
    dataChart.axisColor = this.getColorByLoinc(superComponent.loinc);
    dataChart.fill = false;
    dataChart.steppedLine = false;
    dataChart.spanGaps = true;
    dataChart.label = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    dataChart.yAxisID = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);

    for (const observ of this.observations) {
      let timingName = "";

      if (observ.effectiveTiming && superComponent.lsTimingWhen?.length) {
        const name = superComponent.lsTimingWhen.find((el) => {
          return el.when.code === observ.effectiveTiming.repeat.when.code;
        }).name;
        timingName = this.getTranslation(name, this.sessionService.userLang, "");
      }
      dataChart.data.push({
        x: observ.issued,
        y: observ.getValue(ObservationHelper.ignoreSuffix(superComponent.loinc)),
        timingName,
      });

      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  public computeChartBar(superComponent: IObservationDefinition): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "bar";
    dataChart.axisColor = this.getColorByLoinc(superComponent.loinc);
    dataChart.fill = true;
    dataChart.borderWidth = 2;
    dataChart.barThickness = 12;
    dataChart.maxBarThickness = 12;
    dataChart.label = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    dataChart.yAxisID = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);

    for (const observ of this.observations) {
      dataChart.data.push(observ.getValue(superComponent.loinc));
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  public computeChartBarQ(component: ObservationDefinitionComponent): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "bar";
    dataChart.axisColor = this.getColorByLoinc(component.loinc);
    dataChart.fill = true;
    dataChart.borderWidth = 2;
    dataChart.barThickness = 12;
    dataChart.maxBarThickness = 12;
    dataChart.label = component.shortnameTranslation
      ? this.getTranslation(component.shortnameTranslation, this.sessionService.userLang, component.loinc)
      : this.getTranslation(component.nameTranslation, this.sessionService.userLang, component.loinc);
    dataChart.yAxisID = this.getTranslation(component.nameTranslation, this.sessionService.userLang, component.loinc);

    for (const observ of this.observations) {
      dataChart.data.push(observ.getValue(component.loinc));
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  public computeLabelsBarQ(component: ObservationDefinitionComponent, position: number): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax([component]);
    return {
      id: this.getTranslation(component.nameTranslation, this.sessionService.userLang, component.loinc),
      display: position < 1 ? true : false,
      position: "left",
      ticks: {
        fontColor: this.getColorByLoinc(component.loinc),
        suggestedMin: minmax.min,
        suggestedMax: minmax.max,
        max: minmax.max * 10,
        stepSize: 1,
        callback: (value, _index, _values) => {
          if (!component.meaning || !component.meaning.length) {
            return value;
          }
          // replace Y Axes range value by more understandable text
          for (const meaning of component.meaning) {
            if (meaning.value === value) {
              return this.getTranslation(meaning.description, this.sessionService.userLang, component.loinc);
            }
          }
          // not found, return undefined, we don't want raw value
          return undefined;
        },
      },
    };
  }

  public computeChartCandlestick(superComponent: IObservationDefinition): DataChart {
    const dataChart = new DataChart();
    dataChart.maxBarThickness = 4;
    dataChart.type = "candlestick";
    dataChart.axisColor = this.getColorByLoinc(superComponent.loinc);
    dataChart.label = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    dataChart.yAxisID = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);

    for (const observ of this.observations) {
      dataChart.data.push({
        t: moment(observ.issued).format(),
        o: observ.getValue(superComponent.components[0].loinc),
        h: observ.getValue(superComponent.components[0].loinc),
        l: observ.getValue(superComponent.components[1].loinc),
        c: observ.getValue(superComponent.components[1].loinc),
      });
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  public computeLabelsLine(superComponent: IObservationDefinition): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superComponent.components, true);
    return {
      id: this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc),
      display: !this.chartMeta[superComponent.loinc] || !this.chartMeta[superComponent.loinc].hidden,
      position: this.chartCustomOpt.axeYchangePosition ? "left" : "right",
      ticks: {
        fontColor: this.getColorByLoinc(superComponent.loinc),
        suggestedMin: minmax.min,
        suggestedMax: minmax.max,
        stepSize: null,
        callback: (value, _index, _values) => {
          if (!superComponent.components || !superComponent.components.length || !superComponent.components[0].meaning) {
            return value;
          }
          // replace Y Axes range value by more understandable text
          for (const meaning of superComponent.components[0].meaning) {
            // eslint-disable-next-line max-len
            if (meaning.value === value) {
              return this.getTranslation(meaning.description, this.sessionService.userLang, superComponent.components[0].loinc);
            }
          }
          // not found, return default value
          return value;
        },
      },
    };
  }

  public computeLabelsBar(superComponent: IObservationDefinition): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superComponent.components);
    return {
      id: this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc),
      display: !this.chartMeta[superComponent.loinc] || !this.chartMeta[superComponent.loinc].hidden,
      position: this.chartCustomOpt.axeYchangePosition ? "left" : "right",
      ticks: {
        fontColor: this.getColorByLoinc(superComponent.loinc),
        suggestedMin: minmax.min,
        suggestedMax: minmax.max,
        max: this.needBoostMax(superComponent.loinc) ? minmax.max * 10 : minmax.max,
        stepSize: null,
        callback: (value, _index, _values) => {
          if (!superComponent.components || !superComponent.components.length || !superComponent.components[0].meaning) {
            return value;
          }
          // replace Y Axes range value by more understandable text
          for (const meaning of superComponent.components[0].meaning) {
            if (meaning.value === value) {
              return this.getTranslation(meaning.description, this.sessionService.userLang, superComponent.components[0].loinc);
            }
          }
          // not found, return undefined, we don't want raw value
          return undefined;
        },
      },
    };
  }

  public needBoostMax(loinc: string): boolean {
    return loinc !== FHIRHelper.CODE_MOOD && loinc !== FHIRHelper.CODE_INJECTION_UNIT && loinc !== FHIRHelper.CODE_ACTIVITY_DURATION;
  }

  public computeLabelsCandlestick(superComponent: IObservationDefinition): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superComponent.components);
    return {
      id: this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc),
      display: !this.chartMeta[superComponent.loinc] || !this.chartMeta[superComponent.loinc].hidden,
      position: this.chartCustomOpt.axeYchangePosition ? "left" : "right",
      ticks: {
        fontColor: this.getColorByLoinc(superComponent.loinc),
        suggestedMin: minmax.min - minmax.min * 0.05,
        suggestedMax: minmax.max + minmax.min * 0.05,
        stepSize: null,
        callback: (value, _index, _values) => {
          if (!superComponent.components || !superComponent.components.length || !superComponent.components[0].meaning) {
            return value;
          }
          // replace Y Axes range value by more understandable text
          for (const meaning of superComponent.components[0].meaning) {
            if (meaning.value === value) {
              return this.getTranslation(meaning.description, this.sessionService.userLang, superComponent.components[0].loinc);
            }
          }
          // not found, return default value
          return value;
        },
      },
    };
  }

  /**
   * Retrieves a previously used color for a given LOINC or generates a new one
   * @param loinc - The LOINC code
   * @returns The chart color object
   */
  private getPreviousOrNewColor(loinc: string): IChartColor {
    // Attempt to find an existing color configuration for the provided LOINC code
    const existingColor = this.previousChartColors?.find((item) => item.loinc === loinc);
    // If an existing color configuration is found, return it; otherwise, generate a new color
    return existingColor ? existingColor.color : this.getColor(loinc);
  }

  /**
   * Generates a color configuration for a given LOINC code.
   * @param {string} loinc - The LOINC code for which the color is to be generated.
   * @returns {IChartColor} - The chart color object containing various color properties.
   */
  private getColor(loinc: string): IChartColor {
    const color = this.generateColorFromLoinc(loinc);
    return {
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: color,
    };
  }

  /**
   * Retrieves the background color associated with a specific LOINC code.
   * @param {string} loinc
   * @returns {string} - The background color (used as main color) associated with the provided LOINC code.
   */
  public getColorByLoinc(loinc: string): string {
    const filteredColor = this.chartColorsByLoinc.filter((item) => {
      return item.loinc === loinc;
    });

    if (filteredColor.length > 1) {
      FileLogger.warn("ObservationChartComponent", "getColorByLoinc", "Multiple colors for the same loinc detected");
    }

    return filteredColor.at(0)?.color.backgroundColor;
  }

  /**
   * Generates a color based on the provided LOINC code.
   * @param {string} loinc - The LOINC code for which the color is to be generated.
   * @returns {string} - The generated color as a hexadecimal string.
   */
  public generateColorFromLoinc(loinc: string): string {
    switch (loinc) {
      case FHIRHelper.CODE_BPCOQ_A:
      case FHIRHelper.CODE_BODYWEIGHT:
        return COLORS[0];
      case FHIRHelper.CODE_HEARTRATE:
      case FHIRHelper.CODE_GLUCOSE:
        return COLORS[1];
      case FHIRHelper.CODE_BPCOQ_C:
      case FHIRHelper.CODE_DY_COVID_B:
        return COLORS[2];
      case FHIRHelper.CODE_BPCOQ_D:
      case FHIRHelper.CODE_TEMPERATURE:
      case FHIRHelper.CODE_BT_MM:
      case FHIRHelper.CODE_INJECTION_UNIT:
        return COLORS[3];
      case FHIRHelper.CODE_BPCOQ_E:
      case FHIRHelper.CODE_MOOD:
      case FHIRHelper.CODE_DYSPNEA:
      case FHIRHelper.CODE_DYSPNEA_IC:
      case FHIRHelper.CODE_DY_COVID_A:
        return COLORS[4];
      case FHIRHelper.CODE_FATIGUE:
      case FHIRHelper.CODE_PAIN_B:
        return COLORS[5];
      case FHIRHelper.CODE_PAIN_IC:
      case FHIRHelper.CODE_PAIN:
      case FHIRHelper.CODE_PAIN_MM:
        return COLORS[6];
      case FHIRHelper.CODE_GONFLEMENT:
      case FHIRHelper.CODE_GONFLEMENT_IC:
      case FHIRHelper.CODE_WALKING_DISTANCE:
        return COLORS[7];
      case FHIRHelper.CODE_BPCOQ_B:
      case FHIRHelper.CODE_NEUROPATHY:
      case FHIRHelper.CODE_SPO2:
      case FHIRHelper.CODE_COUGH_COVID:
      case FHIRHelper.CODE_CHILLS:
        return COLORS[8];
      case FHIRHelper.CODE_BLOODPRESURE:
      case FHIRHelper.CODE_REDNESS:
      case FHIRHelper.CODE_ACTIVITY_DURATION:
        return COLORS[9];
      default:
        return this.getRandomColor();
    }
  }

  public computeDateLabels(observations: IObservation[]): string[] {
    if (!observations) {
      return;
    }
    const arr: string[] = [];
    for (const observ of observations) {
      arr.push(observ.issued);
    }
    return arr.reverse();
  }

  private computeMinMax(components: ObservationDefinitionComponent[], isLine?: boolean): IMinmax {
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    components.forEach((component) => {
      for (const observ of this.observations) {
        const value = observ.getValue(component.loinc);
        // value can be zero so if(value) is not OK
        if (value !== undefined && value !== null) {
          if (value < min) {
            min = value;
          }
          if (value > max) {
            max = value;
          }
        }
      }
      let variation = isLine ? Math.round(component.max / 30) : Math.round(component.max / 3);
      if (variation === 0) {
        isLine ? (variation = 10) : (variation = 1);
      }
      min = Math.round(Math.max(min - variation, component.min));
      max = Math.round(Math.min(max + variation, component.max));
    });
    const minmax = { min: min > 0 ? min - 1 : min, max }; // "min-1" to be able to see minimum value (or it will be on the X axis)
    return minmax;
  }

  /**
   * Reload chart: Fix for the charts not updating correctly
   */
  private reloadChart() {
    if (this.chart && this.chartData && this.chartData.length) {
      this.chart.chart?.destroy();
      this.chart.ngOnDestroy();
      if (this.chart.chart) {
        this.chart.chart = null as Chart;
      }
      this.chart.datasets = this.chartData;
      this.chart.labels = this.chartLabels;
      this.chart.options = this.chartOptions;
      this.chart.ngOnInit();
    }
  }

  public getTranslation(translation: ITranslation, lang: string, txtBackup: string): string {
    return Tools.getTranslation(translation, lang, txtBackup);
  }

  public getImage(): string {
    const canvas = document.getElementById("chart") as HTMLCanvasElement;
    return canvas.toDataURL();
  }
}
