import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { Quantity } from "src/app/models/quantity.model";
import { Reference } from "src/app/models/reference.interface";
import { IVitalSignsHistory } from "src/app/models/vital-signs-history.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class VitalSignApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "ReferenceVitalSign", // Theme !
      [], // Post route paths
      ["dashboard/referenceVitalSignHistoric", "dashboard/exportVitalSignsForPatient"], // Get route paths
      ["dashboard/referenceVitalSign"], // Put route paths
      [] // Delete route paths
    );
  }

  public save(vitalSign: Quantity, patientReference: string, practionerReference: Reference): Observable<unknown> {
    const referenceVitalSign = vitalSign.interface;
    if (Array.isArray(referenceVitalSign.valueArray)) {
      referenceVitalSign.value = null; // in this case, the server want that this field is not defined.
    }
    return this.api.put(this.updateRoutes[0], {
      referenceVitalSign,
      patientReference,
      practionerReference,
      reqDate: moment().format(),
    });
  }

  public list(patientReference: string): Observable<IVitalSignsHistory[]> {
    return this.api.get(this.readRoutes[0], { patientReference }) as Observable<IVitalSignsHistory[]>;
  }

  public exportVitalSigns(patientId: string, lang: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[1], { patientId: patientId, lang: lang }) as Observable<Blob>;
  }
}
