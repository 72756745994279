<span #focus></span>
<mat-card class="widget" [ngClass]="{ 'big forcePaddingBottom': isBig }">
  <mat-toolbar color="primary" class="flex-container">
    <div class="flex-container">
      <p class="fs-16">{{ "tile.questionnaires" | translate }}</p>
      <a mat-icon-button aria-label="icon button with a ? icon" (click)="openQuestionnaireHelp()">
        <mat-icon>help_outline</mat-icon>
      </a>
    </div>
    <div class="flex-container" style="justify-content: flex-end; flex-grow: 1">
      <form [formGroup]="filterFormGraph" class="datePickerContainer" *ngIf="!isMobile">
        <mat-form-field class="ml-5">
          <input
            cdkFocusInitial
            (dateChange)="onChangeFromDateGraph()"
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateGraph"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDate' | translate }}"
            (dateChange)="dateChangeFrom($event.value)"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateGraph"></mat-datepicker-toggle>
          <mat-datepicker #fromDateGraph></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="ml-5">
          <input
            matInput
            (dateChange)="onChangeToDateGraph()"
            [min]="minToDate"
            [matDatepicker]="toDate"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDate' | translate }}"
            (dateChange)="dateChangeTo($event.value)"
          />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </form>

      <app-widget-actions
        *ngIf="actions?.length"
        [actions]="actions"
        [isMobile]="responsiveService.isHandset$ | async"
        (actionTriggered)="handleAction($event.action)"
      ></app-widget-actions>
    </div>
  </mat-toolbar>
  <div class="mini-bar" *ngIf="isMobile">
    <form [formGroup]="filterFormGraph">
      <mat-form-field class="ml-5">
        <input
          cdkFocusInitial
          (dateChange)="onChangeFromDateGraph()"
          matInput
          [max]="maxFromDate"
          [matDatepicker]="fromDateGraph"
          formControlName="fromDate"
          placeholder="{{ 'itemFilter.fromDate' | translate }}"
          (dateChange)="dateChangeFrom($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="fromDateGraph"></mat-datepicker-toggle>
        <mat-datepicker #fromDateGraph></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="ml-5">
        <input
          matInput
          (dateChange)="onChangeToDateGraph()"
          [min]="minToDate"
          [matDatepicker]="toDate"
          formControlName="toDate"
          placeholder="{{ 'itemFilter.toDate' | translate }}"
          (dateChange)="dateChangeTo($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </form>
  </div>

  <!-- ======================== CHART IN BIG MODE ===============================-->
  <mat-card class="widget-inside" *ngIf="isBig">
    <mat-toolbar color="primary">
      <p class="fs-16">{{ "table.graph" | translate }}</p>
      <app-item-selector *ngIf="dataSource?.data?.length !== 0" [items]="sliderData" (changed)="changeFilter($event)"> </app-item-selector>
    </mat-toolbar>
    <app-questionnaire-scoring-graph #graph [scoringSelection]="selectedQuestionnaireScorings" [scoringsData]="graphScorings">
    </app-questionnaire-scoring-graph>
  </mat-card>
  <!-- ======================== TABLE DATA FOR SMALL AND BIG MODE ===============================-->
  <mat-card-content class="no-margin widget-inside">
    <mat-toolbar color="primary" *ngIf="isBig">
      <h3 class="fs-16">
        {{ "table.table" | translate }}
      </h3>
    </mat-toolbar>

    <mat-table matSort matSortDirection="asc" [dataSource]="dataSource" [ngClass]="{ hide: dataSource?.data?.length === 0 }">
      <ng-container matColumnDef="author">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.source" | translate }}
          <app-item-filter
            fieldName="{{ 'table.source' | translate }}"
            propertyName="questionnaireResponse.realSource"
            [dataType]="dataTypeChoice"
            [data]="dataSource?.data"
            [defaultValue]="getFilter('questionnaireResponse.realSource')"
            translatePrefix="choiceLabel.source"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon
            class="custom-icon-small sub-align primary m-auto"
            matTooltip="{{ 'choiceLabel.source.M' | translate }}"
            *ngIf="element.questionnaireResponse.realSource === 'M'"
          >
            smartphone</mat-icon
          >
          <mat-icon
            class="custom-icon-small sub-align primary m-auto"
            matTooltip="{{ 'choiceLabel.source.DB' | translate }}"
            *ngIf="element.questionnaireResponse.realSource === 'DB'"
          >
            desktop_windows</mat-icon
          >
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'table.status' | translate }}"
            propertyName="questionnaireResponse.status"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('questionnaireResponse.status')"
            translatePrefix="common.status"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ "common.status." + element.questionnaireResponse.status | translate }}
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.name' | translate }}"
            propertyName="name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element | QRName }}
        </mat-cell>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.date" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element | QRDate | date : "short" }}
        </mat-cell>
      </ng-container>

      <!-- Score Column -->
      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.score" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ul class="no-bullet font-10">
            <li *ngFor="let item; let index = index; of: element.scoreLabelValueRangeArray.slice(0, 3)">
              {{ item }}
              <i
                *ngIf="element.scoring[index] && element.scoring[index].interpretationValue?.result[translateService.currentLang]"
                class="fa fa-info-circle"
                [matTooltip]="element.scoring[index].interpretationValue.result[translateService.currentLang]"
              ></i>
            </li>
          </ul>
        </mat-cell>
      </ng-container>

      <!-- difference Column -->
      <ng-container matColumnDef="difference">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.difference" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{
            (element | findPreviousQR : QR | computeScoring : element.questionnaireResponse)?.done === true
              ? (element | findPreviousQR : QR | computeScoring : element.questionnaireResponse)?.difference
              : "n.a"
          }}
          <mat-icon class="warn custom-icon-small sub-align ml-5">timeline</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Same Column -->
      <ng-container matColumnDef="same">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.same" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{
            (element | findPreviousQR : QR | computeScoring : element.questionnaireResponse)?.done === true
              ? (element | findPreviousQR : QR | computeScoring : element.questionnaireResponse)?.same
              : "n.a"
          }}
          <mat-icon color="primary" class="custom-icon-small sub-align ml-5">arrow_right_alt</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            (click)="openDetails(element)"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-small sub-align">search</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.exportQuestionnaire' | translate }}"
            (click)="onExportPDF(element)"
            aria-label="icon button with a pdf icon"
          >
            <mat-icon class="custom-icon-small sub-align">picture_as_pdf</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.update' | translate }}"
            *ngIf="(element | canUpdate : identifier) && ('questionnaireresponse' | isAuthorized : 'POST' | async) === true"
            (click)="onEdit(element)"
            class="warn"
            [disabled]="editAlreadyClicked"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-small sub-align">create</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator [ngClass]="{ hide: dataSource?.data?.length === 0 }" #paginator [pageIndex]="0" [pageSize]="5" [hidePageSize]="true">
    </mat-paginator>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="dataSource?.data?.length <= 0 && !loading && !isBig">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
