import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IStepwise, IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { StepwiseDrug } from "src/app/models/stepwiseDrug.model";
import { SessionService } from "src/app/providers/session.service";
import { StepwiseDrugService } from "src/app/providers/stepwiseDrug.service";
import { UserService } from "src/app/providers/user.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { CreateStepwiseComponent } from "./create-stepwise/create-stepwise.component";

@Component({
  selector: "app-add-stepwise-drug",
  templateUrl: "./add-stepwise-drug.component.html",
  styleUrls: ["./add-stepwise-drug.component.scss"],
})
export class AddStepwiseDrugComponent implements OnInit, OnDestroy {
  public schemaForm = this.fb.group({
    name: [undefined, Validators.required],
    nbrOfDays: [28, Validators.required],
    saveAsSchema: [false],
    forWho: [],
  });
  private displayedColumns: string[] = [];
  public rowOfDisplayedColumns: string[][] = [];
  public stepwiseDrug: IStepwiseDrug = {
    name: "",
    stepwises: [],
  };
  public isMenu = false;
  public isCreation: boolean;
  public cellsCurrentlySelected: number[] = [];
  public showAddStepwiseButton = false;
  public showEditStepwiseButton = false;
  public showAddToPreviousButton = false;
  public lastCellClicked: number;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddStepwiseDrugComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private stepwiseDrugService: StepwiseDrugService,
    private statService: UserStatisticsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      mode: FORMS_MODE;
      isMenu: boolean;
      stepwises: IStepwiseDrug[];
      stepwiseDrug?: IStepwiseDrug;
      quantitiesMustBeNumber?: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.isMenu = this.data.isMenu ? true : false;
    this.isCreation = this.data.mode === FORMS_MODE.CREATE ? true : false;
    if (this.isMenu) {
      const checkbox = this.schemaForm.get("saveAsSchema");
      checkbox.setValue(true);
      checkbox.disable();
      this.schemaForm.get("forWho").setValidators([Validators.required]);
    }
    const forWho = this.schemaForm.get("forWho");
    this.schemaForm
      .get("saveAsSchema")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          forWho.setValidators([Validators.required]);
        } else {
          forWho.setValidators(null);
        }
        forWho.updateValueAndValidity();
      });
    if (!this.isCreation && this.data.stepwiseDrug) {
      this.stepwiseDrug = Tools.clone(this.data.stepwiseDrug);
      this.schemaForm
        .get("nbrOfDays")
        .setValue(
          this.stepwiseDrug.stepwises[this.stepwiseDrug.stepwises.length - 1].startDay +
            this.stepwiseDrug.stepwises[this.stepwiseDrug.stepwises.length - 1].days.length
        );
      this.schemaForm.get("name").setValue(this.stepwiseDrug.name);
      if (this.isMenu) {
        const forWhoChoice = this.schemaForm.get("forWho");
        if (this.stepwiseDrug.public) {
          forWhoChoice.setValue("global");
        } else if (!this.stepwiseDrug.public && this.stepwiseDrug.author) {
          forWhoChoice.setValue("me");
        } else if (!this.stepwiseDrug.public && this.stepwiseDrug.organization) {
          forWhoChoice.setValue("myOrg");
        }
      }
    }
    this.onNbrOfDaysChange();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public isAuthorizedAddStepwiseDrug(): boolean {
    return this.userService.isAuthorizedSync(null, "dashboard/stepwiseDrug", "POST") ? true : false;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onNbrOfDaysChange(): void {
    this.displayedColumns = [];
    let nbrOfDays: number = this.schemaForm.get("nbrOfDays").value;
    // Here we have to set a max numbers of days
    // Cause if you enter for example 999999 the dashboard dies
    if (nbrOfDays > 365) {
      this.schemaForm.get("nbrOfDays").setValue(365);
      nbrOfDays = 365;
    } else if (nbrOfDays < 0) {
      this.schemaForm.get("nbrOfDays").setValue(0);
      nbrOfDays = 0;
    }
    const stepwiseToDelete: IStepwise[] = [];
    this.stepwiseDrug.stepwises.forEach((s) => {
      if (s.startDay + s.days.length > nbrOfDays) {
        stepwiseToDelete.push(s);
      }
    });
    stepwiseToDelete.forEach((sToDelete) => {
      const indexToDelete = this.stepwiseDrug.stepwises.findIndex((s) => s.startDay === sToDelete.startDay);
      if (indexToDelete !== -1) {
        this.stepwiseDrug.stepwises.splice(indexToDelete, sToDelete.days.length + 1);
      }
    });
    for (let i = 0; i < nbrOfDays; i++) {
      this.displayedColumns.push(i.toString());
    }
    this.formatDisplayedColumns();
  }

  private formatDisplayedColumns() {
    const nbCellsPerLine = 15;
    this.rowOfDisplayedColumns = [];
    let value = this.displayedColumns.length / nbCellsPerLine;
    value = Math.ceil(value);
    for (let i = 0; i < value; i++) {
      this.rowOfDisplayedColumns.push([]);
      this.displayedColumns.forEach((index) => {
        if (+index >= nbCellsPerLine * i && +index < nbCellsPerLine * (i + 1)) {
          this.rowOfDisplayedColumns[i].push(index);
        }
      });
    }
  }

  public onCellClick(index: number): void {
    index = +index;
    this.lastCellClicked = index;
    this.cellsCurrentlySelected.sort((a, b) => a - b);
    if (this.isSelected(index)) {
      // already in a stepwise
      this.showAddStepwiseButton = false;
      this.showAddToPreviousButton = false;
      this.showEditStepwiseButton = true;
      this.cellsCurrentlySelected = [];
    } else {
      if (!this.cellsCurrentlySelected.includes(index)) {
        if (
          this.cellsCurrentlySelected[0] - 1 !== index &&
          this.cellsCurrentlySelected[this.cellsCurrentlySelected.length - 1] + 1 !== index
        ) {
          this.cellsCurrentlySelected = [];
        }
        this.cellsCurrentlySelected.push(index);
        this.cellsCurrentlySelected.sort((a, b) => a - b);
        if (this.isSelected(this.cellsCurrentlySelected[0] - 1)) {
          this.showAddToPreviousButton = true;
        } else {
          this.showAddToPreviousButton = false;
        }
      }
      this.showAddStepwiseButton = true;
      this.showEditStepwiseButton = false;
    }
  }

  private isSelected(index: number): boolean {
    index = +index;
    let isSelected = false;
    for (let i = 0; i < this.stepwiseDrug.stepwises.length && !isSelected; i++) {
      isSelected =
        index >= this.stepwiseDrug.stepwises[i]?.startDay &&
        index < this.stepwiseDrug.stepwises[i]?.startDay + this.stepwiseDrug.stepwises[i]?.days.length;
    }
    return isSelected;
  }

  public onCreateStepwise(): void {
    const indexToAdd: number[] = [];
    this.cellsCurrentlySelected.sort((a, b) => a - b);
    const iCurrentlySelected = this.cellsCurrentlySelected.findIndex((i) => i === this.lastCellClicked);
    if (iCurrentlySelected !== -1) {
      indexToAdd.push(this.lastCellClicked);
      let i = 1;
      while (this.cellsCurrentlySelected[iCurrentlySelected - i] === this.lastCellClicked - i) {
        indexToAdd.push(this.lastCellClicked - i);
        i++;
      }
      i = 1;
      while (this.cellsCurrentlySelected[iCurrentlySelected + i] === this.lastCellClicked + i) {
        indexToAdd.push(this.lastCellClicked + i);
        i++;
      }
      indexToAdd.sort((a, b) => a - b);
      const dialog = this.dialog.open(CreateStepwiseComponent, {
        disableClose: true,
        data: {
          mode: FORMS_MODE.CREATE,
          startDay: indexToAdd[0],
          nbDays: indexToAdd.length,
          quantitiesMustBeNumber: this.data.quantitiesMustBeNumber,
        },
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.stepwiseDrug.stepwises.push(result);
          // The next line is needed to make the pipes realize there's something that changed
          this.stepwiseDrug = Tools.clone(this.stepwiseDrug);
        }
        this.resetCurrentCellSelected();
      });
    }
  }

  public onEditStepwise(): void {
    const stepwise = this.getStepwise();
    const dialog = this.dialog.open(CreateStepwiseComponent, {
      disableClose: true,
      data: {
        mode: FORMS_MODE.UPDATE,
        startDay: stepwise.startDay,
        nbDays: stepwise.days.length,
        stepwise,
        quantitiesMustBeNumber: this.data.quantitiesMustBeNumber,
      },
    });
    dialog.afterClosed().subscribe((result: IStepwise) => {
      if (result) {
        const stepwiseIndex = this.stepwiseDrug.stepwises.findIndex((stepwise) => stepwise.startDay === result.startDay);
        if (stepwiseIndex !== -1) {
          this.stepwiseDrug.stepwises[stepwiseIndex] = result;
        }
        // The next line is needed to make the pipes realize there's something that changed
        this.stepwiseDrug = Tools.clone(this.stepwiseDrug);
      }
    });
  }

  public onExtendStepwise(): void {
    const stepwise = this.getStepwise(this.cellsCurrentlySelected[0] - 1);
    stepwise.days.push(stepwise.days[0]);
    // The next line is needed to make the pipes realize there's something that changed
    this.stepwiseDrug = Tools.clone(this.stepwiseDrug);
    this.resetCurrentCellSelected();
  }

  public onDeleteCell(): void {
    this.stepwiseDrug.stepwises.forEach((stepwise) => {
      if (stepwise.startDay <= this.lastCellClicked && this.lastCellClicked <= stepwise.startDay + stepwise.days.length - 1) {
        const indexInStepwise = this.lastCellClicked - stepwise.startDay;
        stepwise.days.splice(indexInStepwise, 1);
        if (indexInStepwise === 0) {
          stepwise.startDay++;
        }
      }
    });
    // The next line is needed to make the pipes realize there's something that changed
    this.stepwiseDrug = Tools.clone(this.stepwiseDrug);
    this.resetCurrentCellSelected();
  }

  public onDeleteStepwise(): void {
    const index = this.stepwiseDrug.stepwises.findIndex(
      (stepwise) => stepwise.startDay <= this.lastCellClicked && this.lastCellClicked <= stepwise.startDay + stepwise.days.length - 1
    );
    if (index !== -1) {
      this.stepwiseDrug.stepwises.splice(index, 1);
      // The next line is needed to make the pipes realize there's something that changed
      this.stepwiseDrug = Tools.clone(this.stepwiseDrug);
      this.resetCurrentCellSelected();
    }
  }

  private resetCurrentCellSelected() {
    this.cellsCurrentlySelected = [];
    this.lastCellClicked = undefined;
    this.showAddStepwiseButton = false;
    this.showEditStepwiseButton = false;
    this.showAddToPreviousButton = false;
  }

  private getStepwise(index = this.lastCellClicked): IStepwise {
    return this.stepwiseDrug.stepwises.find(
      (stepwise) => stepwise.startDay <= index && index <= stepwise.startDay + stepwise.days.length - 1
    );
  }

  public onSave(): void {
    if (this.schemaForm.valid && this.stepwiseDrug?.stepwises?.length) {
      this.stepwiseDrug.name = this.schemaForm.get("name").value;
      this.stepwiseDrug.stepwises.sort((a, b) => a.startDay - b.startDay);
      if (this.schemaForm.get("saveAsSchema").value === true) {
        if (!this.isMenu && this.isNameAlreadyInTheList(this.stepwiseDrug.name)) {
          const msg = this.translateService.instant("page.drugSchema.nameAlreadyExist");
          this.snackBar.open(msg, "ok", { duration: 5000 });
          return;
        }
        const result = this.saveAsSchema();
        if (!result) {
          return;
        }
      }
      this.dialogRef.close(this.stepwiseDrug);
    } else {
      this.schemaForm.markAllAsTouched();
    }
  }

  private isNameAlreadyInTheList(name: string): boolean {
    return this.data.stepwises.find((s) => s.name === name) !== undefined;
  }

  private saveAsSchema(): boolean {
    this.stepwiseDrug.public = this.schemaForm.get("forWho").value === "global";
    const forWho = this.schemaForm.get("forWho").value;
    if (forWho === "me") {
      this.stepwiseDrug.public = false;
      this.stepwiseDrug.author = {
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      };

      if (!this.isCreation && this.isMenu && this.data.stepwiseDrug.organization) {
        this.stepwiseDrug.organization = null;
      }
    } else if (forWho === "myOrg") {
      this.stepwiseDrug.public = false;
      const orgRef = this.userService.isMonitoringUser ? this.sessionService.currentMonitoringOrg : this.sessionService.organization;
      if (!orgRef || orgRef.reference === this.sessionService.allsOption) {
        const msg = this.translateService.instant("drugSchema.mustChooseOrg");
        this.snackBar.open(msg, "ok", { duration: 5000 });
        return false;
      }
      this.stepwiseDrug.organization = orgRef;

      if (!this.isCreation && this.isMenu && this.data.stepwiseDrug.author) {
        this.stepwiseDrug.author = null;
      }
    } else if (forWho === "global") {
      this.stepwiseDrug.public = true;

      if (!this.isCreation && this.isMenu && this.data.stepwiseDrug.organization) {
        this.stepwiseDrug.organization = null;
      }
      if (!this.isCreation && this.isMenu && this.data.stepwiseDrug.author) {
        this.stepwiseDrug.author = null;
      }
    }
    if (this.isMenu && !this.isCreation) {
      this.stepwiseDrug._id = this.data.stepwiseDrug._id;
      this.stepwiseDrugService
        .update(new StepwiseDrug(this.stepwiseDrug))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.statService.createStatEvent("StepwiseDrug schema updated : " + this.stepwiseDrug.name);
        });
    } else {
      this.stepwiseDrugService
        .create(new StepwiseDrug(this.stepwiseDrug))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.statService.createStatEvent("StepwiseDrug schema created : " + this.stepwiseDrug.name);
        });
    }
    return true;
  }
}
