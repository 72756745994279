import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { last, map, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { Careplan } from "src/app/models/careplan.model";
import { Activity, ICareplan } from "src/app/models/careplans.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { CareplanDialogComponent, ICareplanDialogOption } from "../careplan-dialog/careplan-dialog.component";
import { KnowledgeMediaListModalComponent } from "../knowledge-media-list-modal/knowledge-media-list-modal.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { ActivityListDataSource } from "./activity-list-datasource";

export interface IGroup {
  name: string; // name of the group of options in the select (services)
  careplans: ICareplan[];
}
@Component({
  selector: "app-patient-careplans",
  templateUrl: "./patient-careplans.component.html",
  styleUrls: ["./patient-careplans.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientCareplansComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  private paginator: MatPaginator;
  private hasCurrentCareplan$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isActiveActivity$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isBig$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(MatPaginator, { static: false }) set paginatorComp(p: MatPaginator) {
    this.paginator = p;
    if (p && this.table) {
      if (!this.dataSource) {
        this.dataSource = new ActivityListDataSource();
      }
      this.initPaginatorAndSort();
    }
  }

  private table: MatTable<Activity>;
  @ViewChild(MatTable, { static: false }) set tableComp(t: MatTable<Activity>) {
    this.table = t;
    if (t && this.paginator) {
      if (!this.dataSource) {
        this.dataSource = new ActivityListDataSource();
      }
      this.initPaginatorAndSort();
    }
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public scrollAfterDataInit = false;

  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.currentPatientUser = pu;
      this.currentCaremateIdentifier = pu.user.caremateIdentifier;
      this.initDataAndAutoRefresh(pu.user.caremateIdentifier, false, this.preferenceService.getAutoRefreshWidget());
    }
  }
  public displayedColumns: string[] = ["name", "startDate"];
  public dataSource: ActivityListDataSource;
  public dataSourceArray: { [key: string]: ActivityListDataSource } = {};
  public availableCareplans: ICareplan[];
  public currentCareplan: ICareplan;
  public currentPatientUser: PatientUser;
  public currentCaremateIdentifier: string;
  public nbCareplans: number;
  public form: UntypedFormGroup = new UntypedFormGroup({
    currentCp: new UntypedFormControl(""),
  });
  public isActiveActivity: boolean;
  public isBig = false;
  private currentPageSize: number;
  public defaultSort: MatSortable = {
    id: "default",
    start: "asc",
    disableClear: true,
  };

  public careplanGroups: IGroup[] = [];

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public isServiceSelected = false;
  public isServiceSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private $subCareplanList: Subscription;
  private $subPrefUpdate: Subscription;
  private $subPrefUpdate2: Subscription;
  private $subPrefList: Subscription;
  private refreshInterval;
  private initiatedData = false;

  public actions: WidgetActionConfig[] = [
    {
      type: "button",
      icon: "create",
      label: "btn.editCareplan",
      ariaLabel: "icon button with a pencil icon",
      condition$: combineLatest([this.isServiceSelected$, this.hasCurrentCareplan$, this.userService.isAuthorized("careplan", "PUT")]).pipe(
        map(([serviceSelected, hasCurrentCareplan, isAuthorized]) => serviceSelected && hasCurrentCareplan && isAuthorized)
      ),
      action: (): unknown => this.openCareplanDialog(),
    },
    {
      type: "button",
      icon: "library_books",
      label: "knowledgebase.title",
      ariaLabel: "icon button with a book icon",
      condition$: combineLatest([
        this.isServiceSelected$,
        this.hasCurrentCareplan$,
        this.userService.isAuthorized("dashboard/knowledges/careplans", "GET"),
      ]).pipe(map(([serviceSelected, hasCurrentCareplan, isAuthorized]) => serviceSelected && hasCurrentCareplan && isAuthorized)),
      action: (): unknown => this.showKnowledges(),
    },
    {
      type: "button",
      icon: "add",
      label: "btn.newCareplan",
      ariaLabel: "icon button with a plus icon",
      condition$: combineLatest([this.isServiceSelected$, this.userService.isAuthorized("careplan", "POST")]).pipe(
        map(([serviceSelected, isAuthorized]) => serviceSelected && isAuthorized)
      ),
      action: (): unknown => this.openCareplanDialog(true),
    },
    {
      type: "button",
      icon: "open_in_full",
      label: "btn.details",
      ariaLabel: "icon button with an expand icon",
      condition$: combineLatest([this.isActiveActivity$, this.responsiveService.isHandset$, this.isBig$]).pipe(
        map(([isActiveActivity, isHandset, isBig]) => isActiveActivity && !isHandset && !isBig) // Combine les deux conditions
      ),
      action: (): unknown => this.onPlus(),
    },
    {
      type: "button",
      icon: "close_fullscreen",
      label: "btn.reduce",
      ariaLabel: "icon button with an unexpand icon",
      condition$: this.isBig$,
      action: (): unknown => this.onPlus(),
    },
  ];

  constructor(
    private careplanService: CareplansService,
    private sessionService: SessionService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    public helpData: HelpData,
    private tileManager: TileManager,
    private patientService: PatientService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
    this.setupServicesWatch();
    this.isBig = TileManager.isBig(PatientWidgetName.CAREPLANS);
    this.isBig$.next(this.isBig);
  }

  ngOnInit(): void {
    this.dataSource = new ActivityListDataSource();
    this.sessionService.openCpModale.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.openCareplanDialog(true);
    });

    this.sessionService.refreshCp.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initDataAndAutoRefresh(this.currentCaremateIdentifier, false, this.preferenceService.getAutoRefreshWidget());
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // prevent observable to be triggered multiple times
    this.clearAutoRefresh();
  }

  public initDataAndAutoRefresh(userId: string, selectLast = false, _autoRefreshIntervalMs = 60000, isRefresh = false): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(userId, selectLast); }, autoRefreshIntervalMs);
    this.initData(userId, selectLast, isRefresh);
  }

  public clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  private initData(userId: string, selectLast = false, isRefresh = false) {
    if (this.$subCareplanList) {
      this.$subCareplanList.unsubscribe();
      if (!isRefresh) {
        this.availableCareplans = [];
        this.currentCareplan = undefined;
      }
    }
    this.$subCareplanList = this.careplanService.list(userId).subscribe((cp) => {
      this.nbCareplans = cp.length;
      this.availableCareplans = cp;
      this.availableCareplans.forEach((careplan) => {
        if (careplan?.identifier && careplan.identifier.length) {
          this.dataSourceArray[careplan.identifier[0].value] = new ActivityListDataSource();
        }
      });
      if (this.nbCareplans) {
        if (selectLast) {
          this.currentCareplan = cp[cp.length - 1];
          this.hasCurrentCareplan$.next(this.currentCareplan ? true : false);
        } else {
          let moreRecentCp: ICareplan = cp[0];
          cp.forEach((careplan) => {
            if (moment(careplan.period.start).isAfter(moreRecentCp.period.start, "day")) {
              moreRecentCp = careplan;
            }
          });
          this.currentCareplan = moreRecentCp;
          this.hasCurrentCareplan$.next(this.currentCareplan ? true : false);
        }
        this.form.setValue({ currentCp: this.currentCareplan });
      }
      this.constructCareplansGroups();
      this.loadPreferences(selectLast);
      this.loading = false;
    });
  }

  private setupServicesWatch() {
    this.isServiceSelected = this.sessionService.currentService !== null ? true : false;
    this.isServiceSelected$.next(this.isServiceSelected);
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.isServiceSelected = this.sessionService.currentService !== null ? true : false;
      this.isServiceSelected$.next(this.isServiceSelected);
    });
  }

  /**
   * Construct careplansGroups for the careplans selection options (mat-optgroup)
   */
  private constructCareplansGroups() {
    this.currentPatientUser.patient.healthcareservice.forEach((service) => {
      // find careplans of current service
      const careplansFromCurrentService: Array<ICareplan> = this.availableCareplans.filter(
        (careplan) => careplan.identifier.find((i) => i.system === "organization")?.use === service.reference
      );

      // if service already in careplansGroup, adding careplans in careplanGroups[index of where it exists].careplans
      const indexOfExistingName = this.careplanGroups.findIndex((e) => e.name === service.display);
      if (indexOfExistingName >= 0) {
        // if service already in the group
        this.careplanGroups[indexOfExistingName].careplans = careplansFromCurrentService;
      } else {
        this.careplanGroups.push({ name: service.display, careplans: careplansFromCurrentService });
      }
    });
  }

  private initPaginatorAndSort() {
    if (!this.table) {
      return;
    }
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public computeColumn(): void {
    if (this.isBig) {
      this.displayedColumns = ["name", "startDate", "endDate", "responsable", "status"];
    } else {
      this.displayedColumns = ["name", "startDate"];
    }
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.isBig$.next(this.isBig);
    this.tileManager.updateList(PatientWidgetName.CAREPLANS, this.isBig);
    this.computeColumn();
    this.updatePreference(true);
    this.loadDataSource();
    this.scroll();
  }

  public updateLastFocus(): void {
    if (this.$subPrefUpdate) {
      this.$subPrefUpdate.unsubscribe();
    }
    this.$subPrefUpdate = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.CAREPLANS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  public scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 100);
    }
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  /**
   * Filter
   */
  public getFilterCp(propertyName: string, cp: ICareplan): Filter {
    if (cp.identifier && cp.identifier.length) {
      const k = cp.identifier[0].value;
      return this.dataSourceArray[k].getFilter(propertyName);
    }
  }

  public applyFilterCp(filter: Filter, cp: ICareplan): void {
    if (cp.identifier && cp.identifier.length) {
      const k = cp.identifier[0].value;
      this.dataSourceArray[k].setFilter(filter);
    }
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public clearFilter(): void {
    this.sort.sort(this.defaultSort);
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  public getCpData(propertyName: string, cp: ICareplan): Activity[] {
    if (cp.identifier && cp.identifier.length) {
      const k = cp.identifier[0].value;
      return this.dataSourceArray[k]?.data;
    }
  }

  public switchCp(event: MatSelectChange): void {
    this.currentCareplan = event.value;
    this.hasCurrentCareplan$.next(this.currentCareplan ? true : false);
    this.updatePreference(false);
    this.loadDataSource();
  }

  public loadDataSource(): void {
    if (this.currentCareplan?.activity && this.currentCareplan.activity.length) {
      const activeActivity = this.currentCareplan.activity.filter((a) => a.detail.status === "active");
      this.isActiveActivity = activeActivity.length ? true : false;
      this.isActiveActivity$.next(this.isActiveActivity);
      this.dataSource.loadData(activeActivity);
    } else {
      this.isActiveActivity = false;
      this.isActiveActivity$.next(this.isActiveActivity);
      this.dataSource.loadData([]);
    }
    // load for array
    Object.keys(this.dataSourceArray).forEach((k) => {
      const cibledActivity = this.availableCareplans.find((cp) => cp.identifier[0].value === k)?.activity.filter((a) => a.detail.status);
      this.dataSourceArray[k].loadData(cibledActivity);
    });
  }

  public getStart(cp: ICareplan): string {
    return moment(cp?.period?.start).format("DD/MM/YYYY");
  }

  public getEnd(cp: ICareplan): string {
    return moment(cp?.period?.end).format("DD/MM/YYYY");
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    if (this.$subPrefUpdate2) {
      this.$subPrefUpdate2.unsubscribe();
    }
    this.$subPrefUpdate2 = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_CAREPLANS_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          isBig: this.isBig,
          selectedCp: this.form.get("currentCp").value,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences(onlysize = true) {
    if (this.$subPrefList) {
      this.$subPrefList.unsubscribe();
    }
    this.$subPrefList = this.preferenceService
      .list(PreferenceContext.PATIENT_CAREPLANS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });

          this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
            ? parameters.isBig
            : TileManager.isBig(PatientWidgetName.CAREPLANS);
          this.isBig$.next(this.isBig);
          this.tileManager.updateList(PatientWidgetName.CAREPLANS, this.isBig);

          if (parameters.selectedCp?.identifier && parameters.selectedCp.identifier.length && !onlysize) {
            const id = parameters.selectedCp.identifier[0].value;
            const value = this.availableCareplans.find((cp) => cp.identifier[0].value === id);
            if (value) {
              this.currentCareplan = value;
              this.hasCurrentCareplan$.next(this.currentCareplan ? true : false);
              this.form.get("currentCp").setValue(value);
            }
          }
        }
        this.computeColumn();
        this.loadDataSource();
        if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
          this.scroll();
        }
      });
  }

  public getSubTitle(cp: ICareplan): string {
    if (Careplan.hasNoEndDate(cp)) {
      return (this.translateService.instant("careplan.subTitleInfinite") as string).replace("%START%", this.getStart(cp));
    } else {
      return (this.translateService.instant("careplan.subTitle") as string)
        .replace("%START%", this.getStart(cp))
        .replace("%END%", this.getEnd(cp));
    }
  }

  public getDataSource(cp: ICareplan): ActivityListDataSource {
    if (cp.identifier && cp.identifier.length) {
      return this.dataSourceArray[cp.identifier[0].value];
    }
  }

  public isActiveActivityCareplan(cp: ICareplan): boolean {
    return cp.activity.filter((a) => a.detail.status).length > 0;
  }

  public getMainPerformer(activity: Activity): string | null {
    if (activity.detail?.performer && activity.detail.performer.length) {
      return activity.detail?.performer[0]?.display;
    }
    return null;
  }

  public isNoData(): boolean {
    return this.availableCareplans?.length === 0;
  }

  public openCareplanDialog(createNew = false): void {
    const patientOrgRef = this.currentPatientUser?.patient?.managingOrganization.reference;
    const servicesFromPatientOrg = this.userService.allServices.filter((s) => s.providedBy.reference === patientOrgRef);
    const patientServices = servicesFromPatientOrg.map((s) => s.asReference);

    if (patientServices?.length) {
      let $subGetPatient: Subscription;
      const dialogRef = this.dialog.open(CareplanDialogComponent, {
        disableClose: true,
        panelClass: "no-padding",
        data: {
          patient: this.currentPatientUser,
          careplan: createNew ? undefined : this.currentCareplan,
          careplans: this.careplanGroups,
        } as ICareplanDialogOption,
        maxHeight: "95vh",
      });
      dialogRef.afterClosed().subscribe(() => {
        this.initDataAndAutoRefresh(this.currentCaremateIdentifier, false, this.preferenceService.getAutoRefreshWidget(), true);
        if ($subGetPatient) {
          $subGetPatient.unsubscribe();
        }
        $subGetPatient = this.patientService
          .getPatientUser(this.currentCaremateIdentifier)
          .pipe(last(), takeUntil(this.onDestroy$))
          .subscribe((pu) => {
            this.currentPatientUser = pu;
            this.constructCareplansGroups();
            this.sessionService.needRefreshVitalSignsTile();
          });
      });
    } else {
      // This is not supposed to happen
      // But if a patient doesn't have any healthcare service then it should be impossible to add a careplan
      FileLogger.error("PatientCareplansComponent", "Error while getting patients healthcare services");
      const trans = this.translateService.instant("api.errors.server-error");
      this.snackBar.open(trans, "ok", { duration: 5000 });
    }
  }

  public openCareplansHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientCareplansHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public showKnowledges(): void {
    this.dialog.open(KnowledgeMediaListModalComponent, {
      data: {
        careplanId: this.currentCareplan.support[0].reference,
        patientId: this.currentPatientUser.user.caremateIdentifier,
      },
      maxHeight: this.isMobile ? "100vh" : "",
      height: this.isMobile ? "100vh" : "",
      width: this.isMobile ? "100vw" : "",
      minWidth: this.isMobile ? "100vw" : "50vw",
      maxWidth: this.isMobile ? "100vw" : "",
    });
  }
}
