import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { HelpData } from "src/app/helpers/helpData";
import { InfoCommunication } from "src/app/models/communications.model";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { CommunicationsCrudService } from "src/app/providers/communications-crud.service";
import { CommunicationsService } from "src/app/providers/communications.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";
import { Item } from "../item-selector/item-selector.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { PatientCommunicationsListDataSource } from "./patient-communications-list-datasource";

@Component({
  selector: "app-patient-communications",
  templateUrl: "./patient-communications.component.html",
  styleUrls: ["./patient-communications.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientCommunicationsComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.pu = pu;
      this.loadDataSource();
      this.loadPreferences();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<InfoCommunication>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public displayedColumns: string[] = ["status", "sendDate", "receivedDate", "subject", "action"];
  public dataSource: PatientCommunicationsListDataSource;
  private currentPageSize: number;
  public sliderData: Item[] = [];
  public showDraft = true;
  public pu: PatientUser;
  public filterFormTable = this.fb.group({
    fromDate: new UntypedFormControl(moment().subtract(3, "month"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
    toDate: new UntypedFormControl(moment().endOf("day"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
  });
  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public maxFromDate: string;
  public minToDate: string;
  public today = moment();
  public isAllServices$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private $subRefresh = this.sessionService.refreshCommunicationsList.subscribe(() => {
    if (this.pu) {
      this.loadDataSource();
    }
  });
  private $subPrefUpdate: Subscription;
  private $subPrefList: Subscription;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match

  /**
   * shortcuts to get forms value
   */
  private get from() {
    return this.filterFormTable.get("fromDate")?.value as string;
  }
  private get to() {
    return this.filterFormTable.get("toDate")?.value as string;
  }

  public actions: WidgetActionConfig[];

  constructor(
    private preferenceService: PreferenceService,
    public helpData: HelpData,
    private comService: CommunicationsService,
    private patientService: PatientService,
    private crud: CommunicationsCrudService,
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private responsiveDialog: ResponsiveDialogService,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
    this.setupServicesWatch();
  }

  ngOnInit(): void {
    this.createDataSource();
    this.filterFormTable.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.updateDate());
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataSource.clear();
    this.$subRefresh.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  private updateDate(): void {
    const from = moment(this.from).format("YYYY-MM-DD");
    const to = moment(this.to).format("YYYY-MM-DD");
    this.maxFromDate = moment(to).toISOString();
    this.minToDate = moment(from).toISOString();
    if (from && to && !this.dataSource.loading) {
      this.dataSource.loadCommunications(this.pu.user.caremateIdentifier, from, to, this.preferenceService.getAutoRefreshWidget());
    }
  }

  private setupServicesWatch(): void {
    if (this.userService.isMonitoringUser) {
      this.setupServices();
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    } else {
      this.setupServices();
      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    }
  }

  private setupServices(): void {
    if (
      (!this.userService.isMonitoringUser && !this.sessionService.currentService) ||
      (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)
    ) {
      this.isAllServices$.next(true);
      return;
    }
    this.isAllServices$.next(this.sessionService.isAllServices(this.userService.isMonitoringUser));
  }

  private createDataSource(): void {
    if (!this.dataSource) {
      this.dataSource = new PatientCommunicationsListDataSource(this.comService);
      this.initActions();
    }
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public loadDataSource(): void {
    this.createDataSource();
    this.dataSource.loadCommunications(
      this.pu.user.caremateIdentifier,
      moment(this.from).format("YYYY-MM-DD"),
      moment(this.to).format("YYYY-MM-DD"),
      this.preferenceService.getAutoRefreshWidget()
    );
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    if (this.$subPrefUpdate) {
      this.$subPrefUpdate.unsubscribe();
    }
    this.$subPrefUpdate = this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_PATIENT_COMMUNICATIONS,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences(): void {
    if (this.$subPrefList) {
      this.$subPrefList.unsubscribe();
    }
    this.$subPrefList = this.preferenceService
      .list(PreferenceContext.WIDGET_PATIENT_COMMUNICATIONS)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public refreshData(): void {
    this.dataSource.loadCommunications(this.pu.user.caremateIdentifier, this.from, this.to, this.preferenceService.getAutoRefreshWidget());
  }

  public editCommunication(communication: InfoCommunication): void {
    if (!communication.isEditable) {
      return;
    }
    this.crud.editCommunication(communication, null, false, true);
  }

  public deleteCommunication(communication: InfoCommunication): void {
    if (!communication.isEditable) {
      return;
    }
    this.crud.deleteCommunication(communication, this.patientService.toReference([this.pu]));
  }

  public viewCommunication(communication: InfoCommunication): void {
    this.crud.viewCommunication(communication);
  }

  public newCommunication(): void {
    this.crud.createCommunication(this.patientService.toReference([this.pu]), null, null, true);
  }

  public openCommunicationHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientCommunicationHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public onExportCommunications(): void {
    this.comService.exportCommunications(this.pu.user.caremateIdentifier, this.sessionService.userLang);
  }

  public initActions(): void {
    this.actions = [
      {
        type: "button",
        label: "observation.export",
        ariaLabel: "icon button with a download icon",
        icon: "download",
        condition$: combineLatest([this.dataSource.hasData$, this.userService.isAuthorized("dashboard/exportCommsForPatient", "GET")]).pipe(
          map(([hasData, isAuthorized]) => hasData && isAuthorized)
        ),
        action: () => this.onExportCommunications(),
      },
      {
        type: "button",
        label: "communication.newComm",
        ariaLabel: "Add communication",
        icon: "add",
        condition$: this.userService.isAuthorized("dashboard/communication", "POST"),
        conditionDisable$: this.isAllServices$,
        disabledTooltip: "btn.disabledWithAll",
        action: () => this.newCommunication(),
      },
    ];
  }
}
