import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { first, skipWhile, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { RewardDetailsComponent } from "src/app/components/patient-reward/reward-details/reward-details.component";
import { RewardsDialogComponent } from "src/app/components/patient-reward/rewards-dialog/rewards-dialog.component";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/reference.interface";
import { RewardDefinition } from "src/app/models/rewardDefinition.model";
import { RewardScoreExtended } from "src/app/models/rewardScore-extended.model";
import { REWARD_STATUS } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";
import { ITranslation } from "src/app/models/translation.interface";
import { CommunicationsCrudService } from "src/app/providers/communications-crud.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { RewardDefinitionService } from "src/app/providers/rewardDefinition.service";
import { RewardScoreService } from "src/app/providers/rewardScore.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { RewardsPageDataSource } from "./rewards-page-datasource";

@Component({
  selector: "app-rewards-page",
  templateUrl: "./rewards-page.component.html",
  styleUrls: ["./rewards-page.component.scss"],
})
export class RewardsPageComponent implements AfterViewInit, OnInit, OnDestroy {
  // Material table
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<RewardScoreExtended>;
  public dataSource: RewardsPageDataSource;
  public data: unknown;
  public currentPageSize: number;
  public actionOnMultiple = false;
  public filters: Filter[] = [];
  public baseColumn = [
    "gender",
    "name",
    "firstname",
    "birthday",
    "objectifName",
    "objectifStart",
    "objectifEnd",
    "objectifProgress",
    // 'rewardStatut',
    // 'rewardHistory'
    "status",
    "action",
  ];
  // Columns
  public maxFromDate: string;
  public minToDate: string;
  public get displayedColumns(): string[] {
    return this.actionOnMultiple ? ["select", ...this.baseColumn] : this.baseColumn;
  }
  // Filter
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public dataTypeRewardsName = DataType.REWARDSNAME;
  public globalSearchValue = "";
  // Services and organizations
  public availableOrganizations: Reference[];
  public availableServices: Reference[];
  // Selection
  public selection = new SelectionModel<RewardScoreExtended>(true, []);
  public filterFormTable = this.fb.group({
    fromDate: new UntypedFormControl(moment().subtract(3, "month"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
    toDate: new UntypedFormControl(moment().endOf("day"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
  });
  public today = moment();
  public objectifName: ITranslation;
  public onlyInProgress = false;
  public allDefinitions: RewardDefinition[];
  public subs: Subscription[] = [];
  public refreshSub = this.sessionService.refreshRewardDetailsDataList.subscribe(() => {
    if (!this.userService.isAuthorizedSync(null, "rewardScoresByService", "GET")) {
      return;
    }
    this.dataSource?.loadRewardsPage(this.minToDate, this.maxFromDate, this.onlyInProgress, this.preferenceService.getAutoRefreshWidget());
    this.selection.clear();
  });
  public isAllServices: boolean;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    protected rewardScoreService: RewardScoreService,
    protected rewardDefinitionService: RewardDefinitionService,
    public sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private preferenceService: PreferenceService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private crudCommunication: CommunicationsCrudService,
    private translateService: TranslateService,
    private helpData: HelpData,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {
    this.maxFromDate = moment().endOf("day").format("YYYY-MM-DD");
    this.minToDate = moment().subtract(3, "month").format("YYYY-MM-DD");

    if (
      this.sessionService.currentService &&
      this.userService.ownOrganization &&
      (this.healthcareService.availableServices()?.length > 0 || this.healthcareService.availableMonitoringServices().length > 0)
    ) {
      this.loadPreferences();
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.loadPreferences();
          this.setupServicesWatch();
        });
    }
  }

  ngOnInit(): void {
    this.filterFormTable
      .get("fromDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateDate());
    this.filterFormTable
      .get("toDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateDate());
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s?.unsubscribe());
    this.dataSource.clear();
    this.refreshSub.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      // Detect page size change
      this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
        if (this.currentPageSize !== page.pageSize) {
          this.updatePreference();
        }
      });
    }
  }

  private loadData() {
    // Load data
    this.dataSource = new RewardsPageDataSource(
      this.rewardScoreService,
      this.sessionService,
      this.translateService,
      this.userService,
      this.healthcareService
    );
    const sub = this.rewardDefinitionService.listRewardDefinitions().subscribe((res) => {
      this.allDefinitions = res;
      this.dataSource.rewardDefinitions = this.allDefinitions;
      this.init(this.minToDate, this.maxFromDate);
    });
    this.subs.push(sub);
    this.data = this.dataSource.data;
    if (this.table) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      // Detect page size change
      this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
        if (this.currentPageSize !== page.pageSize) {
          this.updatePreference();
        }
      });
    }
    this.loadPreferences();
  }

  private init(from: string, to: string) {
    this.selection.clear();
    if (!this.userService.isAuthorizedSync(null, "rewardScoresByService", "GET")) {
      return;
    }
    this.dataSource.loadRewardsPage(from, to, this.onlyInProgress);
  }

  private setupServicesWatch() {
    if (this.userService.isMonitoringUser) {
      this.setupServices();
      this.loadData();
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.sessionService.needRefreshRewardDetailsDataList();
      });
      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.sessionService.needRefreshRewardDetailsDataList();
      });
    } else {
      this.setupServices();
      this.loadData();
      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.sessionService.needRefreshRewardDetailsDataList();
      });
    }
  }

  private setupServices() {
    if (
      (!this.userService.isMonitoringUser && !this.sessionService.currentService) ||
      (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)
    ) {
      this.isAllServices = true;
      return;
    }
    this.isAllServices = this.sessionService.isAllServices(this.userService.isMonitoringUser);
  }

  private updateDate() {
    const from = moment(this.filterFormTable.get("fromDate").value).format("YYYY-MM-DD");
    const to = moment(this.filterFormTable.get("toDate").value).format("YYYY-MM-DD");
    this.maxFromDate = to;
    this.minToDate = from;
    if (from && to) {
      this.init(from, to);
    }
  }

  public clearFilter(): void {
    this.globalSearchValue = "";
    this.dataSource?.clearFilter();
    this.filters = [];
    this.updatePreference();
  }

  public updateSearch(value?: string): void {
    this.globalSearchValue = value;
    this.dataSource?.setFilter({
      data: value,
      propertyName: "globalSearch",
      dataType: DataType.COMBINED,
      combinedProperties: ["patientCustomInfo.name", "patientCustomInfo.firstname"],
    });
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource?.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource?.setFilter(filter);
    this.updatePreference();
  }

  public getChoices(propertyName: string): unknown[] {
    return this.dataSource?.data
      .map((patientUser) => {
        return propertyName.split(".").reduce((p, c) => (p && p[c]) || null, patientUser);
      })
      .filter((value, index, self) => {
        return value && self.indexOf(value) === index;
      });
  }

  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.REWARDS_PAGE,
        parameters: {
          filters: this.dataSource?.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filters = this.dataSource?.getAllFilters();
      });
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.REWARDS_PAGE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TableParameter) => {
        if (parameters) {
          if (parameters?.filters?.length) {
            // Apply saved filters
            parameters.filters.forEach((filter: Filter) => {
              this.applyFilter(filter);
            });
          }
          // Set page size from preference
          this.currentPageSize = parameters.itemsPerPage;
        }
      });
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource?.data.forEach((row) => this.selection.select(row));
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource?.data.length;
  }

  public activateCheckBox(): void {
    this.actionOnMultiple = !this.actionOnMultiple;
    if (this.actionOnMultiple) {
      this.dataSource?.clear();
    } else {
      this.selection.clear();
      this.dataSource?.startAutoRefresh(
        this.minToDate,
        this.maxFromDate,
        this.onlyInProgress,
        this.preferenceService.getAutoRefreshWidget()
      );
    }
  }

  public getTargetName(reference: string): string {
    return this.getTargetDef(reference)?.rewardDisplayName?.[this.translateService.currentLang] || "-";
  }

  public getTargetDescription(reference: string): string {
    return this.getTargetDef(reference)?.dashboardRewardText?.[this.translateService.currentLang] || "-";
  }

  private getTargetDef(reference: string) {
    return this.allDefinitions.find((d) => FHIRHelper.getMainIdentifier(d)?.value === reference);
  }

  public getTargetScore(reference: string): number {
    return this.getTargetDef(reference)?.rewardScore;
  }

  public sendCommunication(): void {
    const selection: Reference[] = this.selection.selected.map((s) => s.patientReference);
    this.crudCommunication.createCommunication(selection);
  }

  public openRewardsDialog(rewardDescription: string, rewardScore: RewardScore): void {
    this.dialog.open(RewardsDialogComponent, {
      data: { rewardDescription, rewardScore },
      disableClose: true,
    });
  }

  public getTargetValue(score: RewardScore): string {
    const targetDef = this.getTargetDef(score.rewardDefinitionReference.reference);
    return targetDef ? `${score?.currentScore}/${targetDef.rewardScore}` : "";
  }

  public onDetails(reward: RewardScore, createAction: boolean, description: string, score: string): void {
    this.dialog
      .open(RewardDetailsComponent, {
        data: {
          reward,
          createAction,
          description,
          score,
          patientServiceId: reward.patientReference,
        },
        maxWidth: createAction ? "80vw" : "30vw",
        maxHeight: "93vh",
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.loadData());
  }

  public isAlreadySeen(reward: RewardScore): boolean {
    return reward.rewardStatus === REWARD_STATUS.PROCESSED;
  }

  public openRewardHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.rewardPageHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }
}
