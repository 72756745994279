<form [formGroup]="filterFormTable">
  <div class="container" [style.flex-direction]="direction">
    <mat-form-field class="datePickerField">
      <input
        cdkFocusInitial
        matInput
        (dateChange)="onChangeFromDateTable()"
        [max]="maxFromDate"
        [matDatepicker]="fromDateTable"
        formControlName="fromDate"
        placeholder="{{ 'itemFilter.fromDate' | translate }}"
      />
      <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
      <mat-datepicker #fromDateTable></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="datePickerField">
      <input
        matInput
        (dateChange)="onChangeToDateTable()"
        [min]="minToDate"
        [max]="maxToDate"
        [matDatepicker]="toDate"
        formControlName="toDate"
        placeholder="{{ 'itemFilter.toDate' | translate }}"
      />
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate></mat-datepicker>
    </mat-form-field>
  </div>
</form>
