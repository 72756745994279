<div class="container">
  <app-date-interval-selector
    direction="column"
    [initialFromDate]="fromDate"
    [initialToDate]="toDate"
    (changeDate)="onDateChange($event)"
  ></app-date-interval-selector>
  <mat-slide-toggle class="mr-5 font-12" labelPosition="before" [(ngModel)]="showAnonymized">
    {{ "page.questionnaireList.showAnonymizedQuestionnaire" | translate }}
  </mat-slide-toggle>

  <button mat-stroked-button color="primary" (click)="cancel()">{{ "btn.cancel" | translate }}</button>
  <button mat-raised-button color="primary" (click)="dismiss()">{{ "btn.apply" | translate }}</button>
</div>
