import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { HelpData } from "src/app/helpers/helpData";
import { Account } from "src/app/models/account.interface";
import { OrganisationType, Organization } from "src/app/models/organization.model";
import { IPatientInfo } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { PatientService } from "src/app/providers/patient.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { environment } from "../../../environments/environment";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";
import { PatientsInfosDataSource } from "./user-info-datasource";

@Component({
  selector: "app-user-infos",
  templateUrl: "./user-infos.component.html",
  styleUrls: ["./user-infos.component.scss"],
})
export class UserInfosComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<IPatientInfo>;

  // selects
  @ViewChild("serviceSelect") serviceSelect: MatSelect;
  @ViewChild("monitServiceSelect") monitServiceSelect: MatSelect;
  @ViewChild("orgSelect") orgSelect: MatSelect;
  @ViewChild("monitOrgSelect") monitOrgSelect: MatSelect;

  public displayedColumns: string[] = ["fullname", "birthDate", "email", "phone", "ssin"];

  public availableOrganizations: Reference[] = [];
  public availableServices: Reference[] = [];
  public availableMonitOrgs: Reference[] = [];
  public availableMonitServices: Reference[] = [];
  public currentService: Reference = null;
  public currentOrganization: Reference = null;
  public currentMonitService: Reference = null;
  public currentMonitOrg: Reference = null;
  public currentCountry: string = null;

  public savedService: Reference = null;
  public savedOrganisation: Reference = null;
  public savedMonitoringOrg: Reference = null;
  public savedMonitoringService: Reference = null;
  public searchPatient = "";
  public searchPatientDisplayed = false;
  public isProd = environment.production;

  public user: Account;
  public isMonitoringUser = false;
  public isAuthorizeDisplayPatients = false;

  public onGoingAction = false;

  public patientsInfosDataSource: PatientsInfosDataSource;

  public dummyService: Reference = {
    display: this.translateService.instant("userInfos.noneServiceAvailable"),
    reference: "none",
  };
  public dummyOrganization: Reference = {
    display: this.translateService.instant("userInfos.noneOrganizationAvailable"),
    reference: "none",
  };
  private readonly COOKIE_SERVICEID = "redirect-serviceid";

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    public sessionService: SessionService,
    private userService: UserService,
    private organisationService: OrganizationsService,
    private healthService: HealthcareserviceService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private patientService: PatientService,
    private router: Router,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private helpData: HelpData,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.sessionService.onGoingActionWatch.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.onGoingAction = this.sessionService.onGoingAction;
    });
    this.sessionService.refreshOrganizationsList.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.init());
    this.sessionService.refreshServicesList.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.loadServices());

    this.patientsInfosDataSource = new PatientsInfosDataSource(this.patientService, this.sessionService, this.healthService);
    this.userService
      .account()
      .pipe(
        takeUntil(this.onDestroy$),
        filter((a) => a !== null && a !== undefined),
        first()
      )
      .subscribe((a) => {
        this.user = a;
        this.dummyService = {
          display: this.translateService.instant("userInfos.noneServiceAvailable"),
          reference: "none",
        };
        this.dummyOrganization = {
          display: this.translateService.instant("userInfos.noneOrganizationAvailable"),
          reference: "none",
        };
        this.clear();
        this.setupIsAuthorizeDisplayPatients();
        this.init();
        this.setupServicesWatches();
      });
  }

  ngOnDestroy(): void {
    this.patientsInfosDataSource.clear();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // close div if user click outside
  @HostListener("document:click", ["$event"])
  clickout(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.searchPatientDisplayed = false;
      this.searchPatient = "";
    }
  }

  public clear(): void {
    this.availableOrganizations = [];
    this.availableServices = [];
    this.availableMonitOrgs = [];
    this.availableMonitServices = [];
    this.currentService = null;
    this.currentOrganization = null;
    this.currentMonitService = null;
    this.currentMonitOrg = null;
    this.savedService = null;
    this.savedOrganisation = null;
    this.savedMonitoringOrg = null;
    this.savedMonitoringService = null;
    this.searchPatient = "";
    this.searchPatientDisplayed = false;
    this.isMonitoringUser = false;
    this.isAuthorizeDisplayPatients = false;
    this.currentCountry = null;
  }

  private setupSavedRef() {
    let serviceId: string = null;
    let service: Reference = null;
    if (this.cookieService.check(this.COOKIE_SERVICEID)) {
      serviceId = this.cookieService.get(this.COOKIE_SERVICEID);
      service = this.user.healthcareService.find((elem) => elem.reference === serviceId);
      this.cookieService.delete(this.COOKIE_SERVICEID);
    }

    if (this.isMonitoringUser) {
      // in that case the savedService we got earlier is probably a saved monitoring service
      // let's rectify that
      this.savedMonitoringService =
        service ??
        this.sessionService.currentMonitoringService ??
        this.sessionService.globalPref?.preferredService ??
        this.sessionService.account.healthcareService[0];

      this.savedMonitoringOrg =
        this.sessionService.currentMonitoringOrg ??
        this.sessionService.globalPref?.preferredOrg ??
        this.sessionService.account.managingOrganizations[0];

      this.savedOrganisation = this.sessionService.organization;
      this.savedService = this.sessionService.currentService;
    } else {
      this.savedMonitoringOrg = this.sessionService.currentMonitoringOrg;
      this.savedMonitoringService = this.sessionService.currentMonitoringService;
      this.savedOrganisation =
        this.sessionService.organization ??
        this.sessionService.globalPref?.preferredOrg ??
        this.sessionService.account.managingOrganizations[0];
      this.savedService =
        service ??
        this.sessionService.currentService ??
        this.sessionService.globalPref?.preferredService ??
        this.sessionService.account.healthcareService[0];
    }
  }

  private setupServicesWatches() {
    this.organisationService
      .watchAvailableOrganizations()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((orgs) => {
        this.availableOrganizations = orgs && orgs.length > 0 ? orgs.map((o) => o.asReference) : [this.dummyOrganization];
        this.setupCurrentOrg();
      });
    this.organisationService
      .watchAvailableMonitoringOrgs()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((orgs) => {
        if (!this.isMonitoringUser) {
          this.organisationService.setAvailableOrganizations(this.userService.allOrganizations);
          return;
        }
        this.availableMonitOrgs = orgs ? orgs.map((o) => o.asReference) : [this.dummyOrganization];
        this.setupCurrentMonitOrg();
      });

    this.healthService
      .watchAvailableServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        this.availableServices = services && services.length > 0 ? services.map((s) => s.asReference) : [this.dummyService];
        this.setupCurrentService();
      });

    this.healthService
      .watchAvailableMonitoringServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        if (!this.isMonitoringUser) {
          return;
        }
        this.availableMonitServices = services && services.length > 0 ? services.map((s) => s.asReference) : [this.dummyService];
        this.setupCurrentMonitService();
      });
  }

  public changeService(serviceRef: Reference): void {
    const currentRef = this.currentService; // value of the select before the change
    let newRef = serviceRef;
    newRef = newRef.reference === this.sessionService.allsOption ? this.sessionService.allsReference : newRef;
    this.handleChange(newRef, currentRef, this.serviceSelect, () => this.setupCurrentService(newRef)); // using arrow function to avoid loss of "this" when passing function
  }

  public changeMonitService(monitServiceRef: Reference): void {
    const currentRef = this.currentMonitService;
    let newRef = monitServiceRef;
    newRef = newRef.reference === this.sessionService.allsOption ? this.sessionService.allsReference : newRef;
    this.handleChange(newRef, currentRef, this.monitServiceSelect, () => this.setupCurrentMonitService(newRef)); // using arrow function to avoid loss of "this" when passing function
  }

  public changeOrganization(orgRef: Reference): void {
    const currentRef = this.currentOrganization;
    let newRef = orgRef;
    newRef = newRef.reference === this.sessionService.allsOption ? this.sessionService.allsReference : newRef;
    this.handleChange(newRef, currentRef, this.orgSelect, () => this.setupCurrentOrg(newRef)); // using arrow function to avoid loss of "this" when passing function
  }

  public changeMonitOrganization(monitOrgRef: Reference): void {
    const currentRef = this.currentMonitOrg;
    let newRef = monitOrgRef;
    newRef = newRef.reference === this.sessionService.allsOption ? this.sessionService.allsReference : newRef;
    this.handleChange(newRef, currentRef, this.monitOrgSelect, () => this.setupCurrentMonitOrg(newRef)); // using arrow function to avoid loss of "this" when passing function
  }

  public handleChange(ref: Reference, currentRef: Reference, select: MatSelect, setupMethod: (ref: Reference) => void): void {
    if (this.onGoingAction) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            message: this.translateService.instant("userInfos.selectChangeWarning"),
            type: ConfirmationDialogType.CONFIRM,
          },
          disableClose: true,
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((yes) => {
          if (yes) {
            setupMethod(ref);
          } else {
            select.value = currentRef; // restore value of the select to previous sate
          }
        });
    } else {
      setupMethod(ref);
    }
  }

  private setupCurrentOrg(orgRef?: Reference) {
    if (!this.availableOrganizations || this.availableOrganizations.length === 0) {
      return;
    }
    if (this.availableOrganizations[0].reference === this.dummyOrganization.reference) {
      this.currentOrganization = this.availableOrganizations[0];
      this.sessionService.organization = null;
      this.healthService.setAvailableServices([]);
      return;
    }
    if (!orgRef && this.availableOrganizations.length > 1 && this.savedOrganisation?.reference === this.sessionService.allsOption) {
      this.currentOrganization = this.savedOrganisation;
    } else if (!orgRef) {
      const savedOrg = this.availableOrganizations.find((o) => o.reference === this.savedOrganisation?.reference);
      this.currentOrganization = savedOrg ? savedOrg : this.availableOrganizations[0];
    } else {
      this.currentOrganization = orgRef;
    }
    const availableServices = this.healthService.filterServicesByOrgAndMonitoring(
      this.currentOrganization.reference,
      this.userService.allServices,
      this.currentMonitOrg?.reference,
      this.currentMonitService?.reference
    );
    this.sessionService.organization = this.currentOrganization;
    this.healthService.setAvailableServices(availableServices);
  }

  private setupCurrentService(serviceRef?: Reference) {
    if (!this.availableOrganizations || this.availableOrganizations.length === 0) {
      return;
    }
    if (!this.availableServices || this.availableServices.length === 0) {
      return;
    }
    if (!this.currentOrganization || this.availableServices[0].reference === this.dummyService.reference) {
      this.setupCurrentCountry();
      this.currentService = this.availableServices[0];
      this.sessionService.currentService = null;
      this.refreshData();
      return;
    }
    let newCurrent = serviceRef;
    if (!serviceRef && this.availableServices.length > 1 && this.savedService?.reference === this.sessionService.allsOption) {
      newCurrent = this.savedService;
    } else if (!serviceRef) {
      const savedService = this.availableServices.find((r) => r.reference === this.savedService?.reference);
      newCurrent = savedService ? savedService : this.availableServices[0];
    } else {
      newCurrent = serviceRef;
    }
    if (this.currentService?.reference === newCurrent.reference) {
      return;
    }
    this.currentService = newCurrent;
    this.setupCurrentCountry();
    this.sessionService.currentService = this.currentService;
    this.clearSearch();
    this.setupSavedRef();
    this.refreshData();
  }

  private setupCurrentMonitOrg(orgRef?: Reference) {
    if (!this.availableMonitOrgs || this.availableMonitOrgs.length === 0) {
      return;
    }
    if (this.availableMonitOrgs[0].reference === this.dummyOrganization.reference) {
      return;
    }
    if (!orgRef && this.availableMonitOrgs.length > 1 && this.savedMonitoringOrg?.reference === this.sessionService.allsOption) {
      this.currentMonitOrg = this.savedMonitoringOrg;
    } else if (!orgRef) {
      const savedOrg = this.userService.allMonitoringOrganizations?.find(
        (o) => o.asReference.reference === this.savedMonitoringOrg?.reference
      );
      this.currentMonitOrg = savedOrg ? savedOrg.asReference : this.userService.allMonitoringOrganizations[0].asReference;
    } else {
      this.currentMonitOrg = orgRef;
    }
    const availableMonitServices = this.healthService.filterServicesByOrgAndMonitoring(
      this.currentMonitOrg.reference,
      this.userService.allMonitoringServices
    );
    this.sessionService.currentMonitoringOrg = this.currentMonitOrg;
    this.healthService.setAvailableMonitoringServices(availableMonitServices);
  }

  private setupCurrentMonitService(serviceRef?: Reference) {
    if (!this.availableMonitOrgs || this.availableMonitOrgs.length === 0) {
      return;
    }
    if (!this.currentMonitOrg) {
      return;
    }
    if (!this.availableMonitServices || this.availableMonitServices.length === 0) {
      return;
    }
    if (this.availableMonitServices[0].reference === this.dummyService.reference) {
      this.currentMonitService = this.availableMonitServices[0];
      this.organisationService.setAvailableOrganizations([]);
      this.sessionService.currentMonitoringService = null;
      return;
    }
    if (
      !serviceRef &&
      this.availableMonitServices.length > 1 &&
      this.savedMonitoringService?.reference === this.sessionService.allsOption
    ) {
      this.currentMonitService = this.savedMonitoringService;
    } else if (!serviceRef) {
      const savedOrg = this.availableMonitServices.find((r) => r.reference === this.savedMonitoringService?.reference);
      this.currentMonitService = savedOrg ? savedOrg : this.availableMonitServices[0];
    } else {
      this.currentMonitService = serviceRef;
    }
    const availableOrgs = this.organisationService.filterOrgsByMonitoring(
      this.userService.allOrganizations,
      this.currentMonitOrg.reference,
      this.currentMonitService.reference
    );
    this.sessionService.currentMonitoringService = this.currentMonitService;
    this.organisationService.setAvailableOrganizations(availableOrgs);
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1.reference === o2.reference;
  }

  private setupIsAuthorizeDisplayPatients() {
    this.isAuthorizeDisplayPatients = this.userService.isAuthorizedSync(null, "/patients", "GET");
  }

  private init() {
    // user not loaded yet
    if (!this.user) {
      return;
    }
    this.organisationService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((orgs) => {
        orgs?.sort((a, b) => {
          return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
        });
        const ownOrg = orgs?.find((o) => o.identifier[0].value === this.user.managingOrganizations[0].reference);
        this.isMonitoringUser = ownOrg?.isMonitoring();
        this.userService.isMonitoringUser = this.isMonitoringUser;
        const monitoringOrgs = orgs?.filter((org) => org.isMonitoring());
        const normalOrgs = orgs?.filter((org) => org.organizationType === OrganisationType.PROV);

        this.userService.ownOrganization = ownOrg;
        this.userService.allOrganizations = normalOrgs;
        this.userService.allMonitoringOrganizations = monitoringOrgs;

        this.setupSavedRef();
        this.loadServices();
      });
  }

  private loadServices() {
    this.healthService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        services?.sort((a, b) => {
          return a.serviceName?.toLowerCase().localeCompare(b.serviceName?.toLowerCase());
        });
        const normalOrgsRefs = this.userService.allOrganizations?.map((o) => o.asReference.reference);
        const monitoringOrgsRefs = this.userService.allMonitoringOrganizations?.map((o) => o.asReference.reference);
        this.userService.allServices = services?.filter((s) => normalOrgsRefs?.includes(s.providedBy.reference));
        this.userService.allMonitoringServices = services?.filter((s) => monitoringOrgsRefs?.includes(s.providedBy.reference));

        // Everything will be setup thanks to the watchers
        this.organisationService.setAvailableMonitoringOrgs(this.userService.allMonitoringOrganizations);
      });
  }

  private refreshData() {
    this.sessionService.needRefreshGroupDataList();
    this.sessionService.needRefresQRDataList();
    this.sessionService.needRefreshLastActivityWidget();
    this.sessionService.needRefreshRedAlertWidget();
    this.sessionService.servicesHaveBeenSetup();
  }

  public async updateSearch(value?: string): Promise<void> {
    this.searchPatient = value ?? "";
    this.searchPatientDisplayed = this.searchPatient !== "";

    if (this.searchPatientDisplayed) {
      if (this.table && !this.table?.dataSource) {
        this.table.dataSource = this.patientsInfosDataSource;
      }
      this.patientsInfosDataSource.searchPatients(this.searchPatient);
    }
  }

  private clearSearch() {
    this.searchPatient = "";
    this.searchPatientDisplayed = false;
  }

  /**
   * Row interactions
   */
  public patientRowClicked(patientInfo: IPatientInfo): void {
    this.clearSearch();

    const b64Id = Base64Helper.utf8_to_b64(patientInfo.caremateIdentifier);
    this.router.navigateByUrl(`/patient;id=${b64Id}`);
  }

  /**
   * Open help dialog
   */
  public openSearchPatientHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientResearch },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  private setupCurrentCountry() {
    const currentOrg: Organization = this.userService.allOrganizations.find(
      (org) => org.asReference.reference === this.currentOrganization.reference
    );
    const service = this.healthService.availableServices().find((h) => h.asReference.reference === this.currentService?.reference);

    if (service?.location?.country) {
      this.currentCountry = service.location.country;
    } else if (currentOrg?.address?.length) {
      this.currentCountry = currentOrg.address[0].country;
    } else {
      this.currentCountry = "BE";
    }

    this.sessionService.currentCountry = this.currentCountry;
  }
}
