import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { SMS_TYPE } from "src/app/models/UnknownPatientData.interface";
import { Appointment } from "src/app/models/appointment.model";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Participant } from "src/app/models/participant.interface";
import { PreferenceContext, WidgetFilterParameter } from "src/app/models/preference.interface";
import { IWidgetComponent } from "src/app/models/widget.interface";
import { AppointmentService } from "src/app/providers/appointment.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../../confirmation-dialog/confirmation-dialog.component";
import { PatientTeleconsultationDialogComponent } from "../../patient-teleconsultations-dialog/patient-teleconsultations-dialog.component";
import { TeleConsultationDialogComponent } from "../../tele-consultation-dialog/tele-consultation-dialog.component";
import { TeleconsultationListDataSource } from "./tele-consultation-widget-list-datasource";

@Component({
  selector: "app-tele-consultation-widget",
  templateUrl: "./tele-consultation-widget.component.html",
  styleUrls: ["./tele-consultation-widget.component.scss"],
})
export class TeleConsultationWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Appointment>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;

  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public isDraggable = true;
  public displayedColumns: string[] = ["start", "participants", "description", "seenByPatient", "actions"];
  public dataSource: TeleconsultationListDataSource;
  public appointments: Appointment[] = [];
  public today: number = Date.now();
  public has2FA = false;
  public skip2FA = false;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private snackBar: MatSnackBar,
    public sessionService: SessionService,
    private userService: UserService,
    private translateService: TranslateService,
    private appointmentService: AppointmentService,
    private patientService: PatientService,
    private router: Router,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private helpData: HelpData,
    private preferenceService: PreferenceService
  ) {
    this.sessionService.refreshTeleconsultationsDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData();
    });
  }

  ngOnInit(): void {
    this.userService
      .account()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.has2FA = account.mfaActive;
      });
    this.dataSource = new TeleconsultationListDataSource();
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    this.appointmentService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((appointments) => {
        this.appointments = appointments.filter((app) => moment(app.start).isSame(moment(), "days") && app.isActive);
        this.loadPreferences();
        this.loadDataSource();
      });
  }

  private loadDataSource() {
    this.dataSource.loadData(this.appointments);
    this.initPaginatorAndSort();
  }

  private initPaginatorAndSort() {
    // this.sort.sort(this.defaultSort);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  /**
   * Preferences
   */
  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_TELECONSULTATIONS,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetFilterParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_TELECONSULTATIONS)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetFilterParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public getParticipants(appointment: Appointment): Participant[] {
    const participants: Participant[] = [];
    appointment.participant.forEach((participant) => {
      if (participant.participantType && participant.participantType.text === "person") {
        participants.push(participant);
      }
    });
    return participants;
  }

  public onStartVideoCall(appointment: Appointment): void {
    if (!this.has2FA && !this.skip2FA) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      appointment,
    };
    this.dialog.open(TeleConsultationDialogComponent, dialogConfig);
  }

  /**
   * Show confirmation modal before, change smsMailSent boolean and smsType
   */
  public resendSmsMail(app: Appointment): void {
    app.unknowPatientData.smsMailSent = false;
    app.unknowPatientData.smsType = SMS_TYPE.NEW;
    this.appointmentService
      .updateVisioAppointement(app)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackBar.open(this.translateService.instant("common.success"), undefined, {
          duration: 3000,
        });
      });
  }

  public delete(app: Appointment): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.appointmentService.deleteApp(app).subscribe(() => {
            this.initData();
            const msg = this.translateService.instant("common.success");
            this.snackBar.open(msg, "ok", { duration: 3000 });
          });
        }
      });
  }

  public async update(app: Appointment): Promise<void> {
    let patientUser = null;
    if (app.isNotUnknowPatient()) {
      const patientId = app.participantPatientRef.reference;
      patientUser = await this.patientService.getPatientUser(patientId).toPromise();
    }
    this.dialog.open(PatientTeleconsultationDialogComponent, {
      data: {
        appointment: app,
        patientUser,
        mode: FORMS_MODE.UPDATE,
        fromPatientWidget: app.isNotUnknowPatient(),
      },
    });
  }

  public goToPatient(app: Appointment): void {
    const patientId = app.participantPatientRef.reference;
    const b64Id = Base64Helper.utf8_to_b64(patientId);
    this.router.navigateByUrl(`/patient;id=${b64Id}`, {
      state: undefined,
    });
  }

  public openDailyVideocall(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.dailyVideocall },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  /**
   * Filter
   */
  private getFilter(propertyName: string) {
    return this.dataSource.getFilter(propertyName);
  }

  private applyFilter(filter: Filter) {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  private clearFilter() {
    this.dataSource.clearFilter();
  }
}
