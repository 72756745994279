import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-photo-booth-dialog",
  templateUrl: "./photo-booth-dialog.component.html",
  styleUrls: ["./photo-booth-dialog.component.scss"],
})
export class PhotoBoothDialogComponent implements OnInit {
  @ViewChild("videoElement", { static: false }) videoElement: ElementRef<HTMLVideoElement>;
  public imageCaptured = false;
  public capturedImage = ""; // Will contain the captured image source
  public isCameraLoading = true;
  public isUsingFrontCamera = true;
  public isFlashVisible = false;
  stream: MediaStream;
  video: HTMLVideoElement;

  constructor(public dialogRef: MatDialogRef<PhotoBoothDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogOption: any) {
    this.dialogRef.afterClosed().subscribe(() => {
      this.onModalClose();
    });
  }

  ngOnInit(): void {
    this.startCamera();
  }

  startCamera(): void {
    this.isCameraLoading = true;
    const constraints = {
      video: {
        facingMode: this.isUsingFrontCamera ? "user" : "environment",
      },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.video = this.videoElement.nativeElement;
        this.stream = stream;
        this.video.srcObject = this.stream;
        this.video.play();
        this.isCameraLoading = false;
      })
      .catch((error) => {
        console.error("Camera access error:", error);
        this.isCameraLoading = false;
      });
  }

  switchCamera(): void {
    this.isUsingFrontCamera = !this.isUsingFrontCamera;
    this.stopCamera();
    this.startCamera();
  }

  captureImage(): void {
    this.showFlash();
    const video = this.videoElement.nativeElement;

    // Creates a canvas to capture the image
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.capturedImage = canvas.toDataURL("image/png"); // Converts the image to base64
      this.imageCaptured = true; // Changes the state to display the captured image
    }
    this.stopCamera();
  }
  showFlash(): void {
    this.isFlashVisible = true;
    setTimeout(() => {
      this.isFlashVisible = false;
    }, 500);
  }

  reset(): void {
    this.imageCaptured = false; // Returns to video
    this.capturedImage = ""; // Clears the captured image
    this.startCamera(); // relaunch camera
  }

  stopCamera(): void {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => track.stop());
      this.video.srcObject = null;
    }
  }

  onModalClose(): void {
    this.stopCamera();
  }

  validate(): void {
    this.dialogRef.close(this.capturedImage);
  }
}
