<div class="login-main-container">
  <img class="logo-main-page" src="assets/images/logo_main.png" />
  <mat-card class="login-card">
    <mat-card-content>
      <form [formGroup]="loginForm" autocomplete="off" (keyup.enter)="login()">
        <mat-form-field>
          <mat-label>{{ "page.login.email" | translate }}</mat-label>
          <input matInput formControlName="email" placeholder="user@email.com" />
          <mat-error *ngIf="isFieldValid('email')">{{ "page.login.email-required" | translate }} </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "page.login.password" | translate }}</mat-label>
          <app-show-hide-password>
            <input type="password" formControlName="password" matInput />
          </app-show-hide-password>
          <mat-error *ngIf="isFieldValid('password')">{{ "page.login.password-required" | translate }} </mat-error>
        </mat-form-field>

        <p *ngIf="errorMessage" class="error">
          {{ "api.errors." + errorMessage | translate }}
        </p>
        <p *ngIf="needClassicLogin" class="error">
          {{ "page.login.needClassicLogin" | translate }}
        </p>

        <app-change-lang [mode]="langMode"></app-change-lang>

        <div class="form-action">
          <button [disabled]="loading" (click)="login()" id="loginBtn" mat-raised-button color="primary">
            {{ "page.login.login" | translate }}
          </button>
          <ng-container *ngIf="otherOptionsConnections?.length > 0">
            <div class="separator mb-05 mt-05">{{ "common.or" | translate }}</div>
            <button
              [disabled]="loading"
              (click)="loginViaEHealth()"
              mat-raised-button
              style="padding: 8px"
              *ngIf="otherOptionsConnections.includes(OptionsConnections.EHEALTH)"
            >
              <div class="custom-btn-wrapper" *ngIf="otherOptionsConnections.includes(OptionsConnections.EHEALTH)">
                <img class="ehealth_icon" src="assets/images/realm_healthcare_logo.png" />
                <span style="position: relative; top: 3px">
                  {{ "page.login.viaEHealth" | translate }}
                </span>
              </div>
            </button>
            <button
              [disabled]="loading"
              (click)="redirectToANS()"
              mat-raised-button
              style="padding: 8px"
              *ngIf="otherOptionsConnections.includes(OptionsConnections.PSC)"
            >
              <div class="custom-btn-wrapper" *ngIf="otherOptionsConnections.includes(OptionsConnections.PSC)">
                <img class="ehealth_icon" src="assets/images/PSC_Badge-Clair.png" style="position: relative; top: 3px" />
                <span style="position: relative; top: 3px">
                  {{ "page.login.viaPSC" | translate }}
                </span>
              </div>
            </button>
          </ng-container>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 5px">
          <button mat-raised-button (click)="installPwa()" color="primary">
            {{ "page.login.download" | translate }}
          </button>
        </div>

        <div class="report-problem" *ngIf="!showErrorForm" (click)="showErrorForm = true">
          <p>{{ "page.login.reportIssue" | translate }}</p>
        </div>

        <div class="error-form" *ngIf="showErrorForm">
          <p class="bold">{{ "page.login.errorForm.title" | translate }}</p>
          <mat-radio-group class="radio-group" aria-label="Select an option" formControlName="error">
            <mat-radio-button class="mt-05" [value]="1">
              {{ "page.login.errorForm.option.1" | translate }}
            </mat-radio-button>
            <p *ngIf="loginForm.get('error').value === 1" class="info">
              {{ "page.login.errorForm.info" | translate }}
            </p>
            <mat-radio-button class="mt-05" [value]="2">{{ "page.login.errorForm.option.2" | translate }}</mat-radio-button>
          </mat-radio-group>
          <div class="button-container">
            <button mat-raised-button class="mr-05" (click)="showErrorForm = false">{{ "btn.cancel" | translate }}</button>
            <button mat-raised-button color="primary" (click)="prepareMail()" [disabled]="!this.loginForm.get('error').value">
              {{ "btn.send" | translate }}
            </button>
          </div>
        </div>

        <p
          class="cgu-text"
          [innerHTML]="'page.login.cguText' | translate : { cgu: ('page.login.cgu' | translate), link: ('page.login.cguLink' | translate) }"
        ></p>
      </form>
    </mat-card-content>
  </mat-card>
  <div class="bottomContainer">
    <div class="countriesContainer">
      <mat-icon>public</mat-icon>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedCountry" (selectionChange)="countryChanged()">
          <mat-option *ngFor="let country of countries" [value]="country.countryCode">
            {{ "forms.country." + (country.countryCode | lowercase) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <p class="on-left mt-5">{{ version }}v</p>
    </div>
  </div>
</div>
