import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { AddGroupComponent } from "src/app/components/forms/add-group/add-group.component";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { StatusEntity } from "src/app/models/entity.interface";
import { Group } from "src/app/models/group.model";
import { Reference } from "src/app/models/reference.interface";
import { GroupService } from "src/app/providers/group-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-groups-list-page",
  templateUrl: "./groups-list-page.component.html",
  styleUrls: ["./groups-list-page.component.scss"],
})
export class GroupsListPageComponent implements OnInit, OnDestroy {
  public groups: Group[] = [];
  public panelOpenState = false;
  public displayedColumns: string[] = ["name", "action"];
  public expandedArray: number[] = [];
  public isAllServices = false;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private groupService: GroupService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    public helpData: HelpData,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {}

  ngOnInit(): void {
    this.setupServicesWatch();
    this.loadGroups();
    this.sessionService.refreshGroupDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.loadGroups());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch(): void {
    if (this.userService.isMonitoringUser) {
      this.setupServices();
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    } else {
      this.setupServices();
      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    }
  }

  private setupServices(): void {
    if (
      (!this.userService.isMonitoringUser && !this.sessionService.currentService) ||
      (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)
    ) {
      this.isAllServices = true;
      return;
    }
    this.isAllServices = this.sessionService.isAllServices(this.userService.isMonitoringUser);
  }

  public loadGroups(): void {
    if (!this.userService.isAuthorizedSync(null, "groups", "GET")) {
      return;
    }
    this.groupService
      .listAll()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groups) => (this.groups = groups));
  }

  public deleteGroup(group: Group) {
    group.entityStatus = [StatusEntity.DELETED];
    const msg = this.translateService.instant("group.deleteGroup");
    const success = this.translateService.instant("common.success");
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: msg, type: ConfirmationDialogType.CONFIRM },
    });
    dialogRef.afterClosed().subscribe((yes) => {
      if (yes) {
        this.groupService
          .update(group)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => this.loadGroups());
        this.snackBar.open(success, "ok", { duration: 3000 });
      }
    });
  }

  public createGroup(): void {
    const dialogRef = this.dialog.open(AddGroupComponent, {
      data: { group: null, mode: FORMS_MODE.CREATE },
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.loadGroups();
        }
      });
  }

  public editGroup(group: Group): void {
    const dialogRef = this.dialog.open(AddGroupComponent, {
      data: { group, mode: FORMS_MODE.UPDATE },
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadGroups();
      });
  }

  public deleteFromGroup(patient: Reference, group: Group): void {
    const msg = this.translateService.instant("group.deletePFromGroup");
    const success = this.translateService.instant("common.success");
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: msg, type: ConfirmationDialogType.CONFIRM },
    });
    dialogRef.afterClosed().subscribe((yes) => {
      if (yes) {
        group.patients = Tools.removePatientFromRef(group.patients, patient.reference);
        this.groupService
          .update(group)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => this.loadGroups());
        this.snackBar.open(success, "ok", { duration: 3000 });
      }
    });
  }

  public onExpand(index: number): void {
    if (this.isExpand(index)) {
      this.expandedArray = this.expandedArray.filter((v) => v !== index);
    } else {
      this.expandedArray.push(index);
    }
  }

  public isExpand(index: number): boolean {
    return this.expandedArray.indexOf(index) >= 0;
  }

  public canUpdate(group: Group): boolean {
    return (
      this.sessionService.account?.caremateIdentifier === group.caremateIdentifier ||
      this.sessionService.isAdmin() ||
      this.sessionService.isOrgaAdmin()
    );
  }

  public openGroupsHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.GroupsListPageHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }
}
