import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICareplan, ICareplanInfo, ILink2Careplan } from "src/app/models/careplans.interface";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IObservationsLoincsInfo } from "src/app/models/observations.interface";
import { IQuestionnaireIdentifierInfo } from "src/app/models/questionnaire.interface";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class CareplansApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Careplans", // Theme !
      ["careplan"], // Post route paths
      [
        "careplans", // 0
        "careplantemplates", // 1
        "dashboard/link2careplan", // 2
        "dashboard/allVitalProfileDefinitions", // 3
        "dashboard/careplanTemplatesLinkedToQuestionnaire", // 4
        "dashboard/careplanObservationsLoincs", // 5
        "dashboard/careplanQuestionnaireIdentifier", // 6
        "dashboard/careplansInfos", // 7
        "dashboard/careplanTemplate", // 8
      ], // Get route paths
      ["careplan", "dashboard/link2careplan"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string, lang: string): Observable<ICareplan[]> {
    return this.api.get(this.readRoutes[0], {
      identifier: patientId,
      language: lang,
    }) as Observable<ICareplan[]>;
  }

  public getCareplanTemplates(lang: string, serviceRef?: string): Observable<ICareplan[]> {
    return this.api.get(this.readRoutes[1], { language: lang, serviceRef: serviceRef }) as Observable<ICareplan[]>;
  }

  /**
   *
   * @param careplanRef reference of the careplan
   * @param publicationDate date of publication we want
   * @param lang if we want the template translated, use this parameter (leave empty otherwise)
   * @returns
   */
  public getCareplanTemplateByPublicationDate(
    careplanRef: string,
    publicationDate?: string,
    lang?: string
  ): Observable<{
    published: ICareplan;
  }> {
    return this.api.get(this.readRoutes[8], { careplanRef: careplanRef, publicationDate, lang }) as Observable<{
      published: ICareplan;
    }>;
  }

  public createCareplan(careplan: ICareplan): Observable<unknown> {
    return this.api.post(this.createRoutes[0], careplan);
  }

  public updateCareplan(careplan: ICareplan): Observable<unknown> {
    return this.api.put(this.updateRoutes[0], careplan);
  }

  public getLink2Careplan(careplanId: string): Observable<{
    published: ILink2Careplan;
    draft: ILink2Careplan;
  }> {
    return this.api.get(this.readRoutes[2], { careplanId: careplanId }) as Observable<{
      published: ILink2Careplan;
      draft: ILink2Careplan;
    }>;
  }

  public updateLink2Careplan(link2careplan: ILink2Careplan): Observable<ILink2Careplan> {
    return this.api.put(this.updateRoutes[1], link2careplan) as Observable<ILink2Careplan>;
  }

  public getAllVitalProfileDefinitions(codes?: string[]): Observable<IVitalProfileDefinition[]> {
    return this.api.get(this.readRoutes[3], { codes: codes ? String(codes) : null }) as Observable<IVitalProfileDefinition[]>;
  }

  public getCareplanTemplatesWithActivitiesLinkedToQuestionnaire(id: string): Observable<ICareplan[]> {
    return this.api.get(this.readRoutes[4], { questionnaireId: id }) as Observable<ICareplan[]>;
  }

  public getObservationsLinkedToCareplan(
    careplanId: string,
    draft: boolean,
    publicationDate?: string
  ): Observable<IObservationsLoincsInfo[]> {
    return this.api.get(this.readRoutes[5], { careplanId: careplanId, draft, publicationDate }) as Observable<IObservationsLoincsInfo[]>;
  }

  public getQuestionnairesLinkedToCareplan(
    careplanId: string,
    draft: boolean,
    publicationDate?: string
  ): Observable<IQuestionnaireIdentifierInfo[]> {
    return this.api.get(this.readRoutes[6], { careplanId: careplanId, draft, publicationDate }) as Observable<
      IQuestionnaireIdentifierInfo[]
    >;
  }

  public getCareplansCount(search?: string, filters?: Filter[], services?: string[]): Observable<{ count: number }> {
    return this.api.get("dashboard/nbCareplansInfos", {
      search,
      filters: JSON.stringify(filters),
      servicesIds: services?.join(","),
    }) as Observable<{ count: number }>;
  }

  public findCareplans(params: DataSourceBackendParam): Observable<ICareplanInfo[]> {
    return this.api.get(this.readRoutes[7], {
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      filters: JSON.stringify(params.filters),
      search: params.search,
      servicesIds: params.services?.join(","),
      language: params.lang,
    }) as Observable<ICareplanInfo[]>;
  }
}
