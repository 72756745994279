import { Pipe, PipeTransform } from "@angular/core";
import { EntityDrug } from "../models/entity.interface";
import { DrugsService } from "../providers/drugs.service";

@Pipe({ name: "timingDisplay" })
export class TimingDisplay implements PipeTransform {
  constructor(private drugsService: DrugsService) {}
  /**
   * Angular pipe that transforms a drug entity into a formatted string
   * representing its administration timing and quantities.
   *
   * Internally delegates to `DrugsService.getTiming()` to generate
   * an HTML-safe description (e.g., fixed hours or per-moment with dosage).
   *
   * Useful for displaying human-readable dosage instructions in the UI.
   *
   * @param {EntityDrug} element - The drug entity to format.
   * @returns {string} A formatted string describing the timing and dosage.
   */
  transform(element: EntityDrug): string {
    return this.drugsService.getTiming(element);
  }
}
