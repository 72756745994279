<div class="installPageContainer">
  <div class="left">
    <button class="color-button" mat-stroked-button (click)="back()">
      <i class="fas fa-arrow-circle-left fa-lg"></i>
    </button>
  </div>
  <h1>{{ "installPage.title" | translate }}</h1>

  <div class="section ios" id="ios">
    <h2>{{ "installPage.ios.section.title" | translate }}</h2>
    <p>{{ "installPage.ios.section.description" | translate }}</p>
    <ol>
      <li>{{ "installPage.ios.step1" | translate }}</li>
      <li>{{ "installPage.ios.step2" | translate }}</li>
      <li>{{ "installPage.ios.step3" | translate }}</li>
    </ol>
    <div class="image-container">
      <img src="assets/images/install_pwa/pwa_install_ios_1.jpg" alt="iOS Installation Step 1" />
      <img src="assets/images/install_pwa/pwa_install_ios_2.jpg" alt="iOS Installation Step 2" />
      <img src="assets/images/install_pwa/pwa_install_ios_3.jpg" alt="iOS Installation Step 3" />
    </div>
  </div>

  <div class="section android" id="android">
    <h2>{{ "installPage.android.section.title" | translate }}</h2>
    <p>{{ "installPage.android.section.description" | translate }}</p>
    <ol>
      <li>{{ "installPage.android.step1" | translate }}</li>
      <li>{{ "installPage.android.step2" | translate }}</li>
      <li>{{ "installPage.android.step3" | translate }}</li>
    </ol>
    <div class="image-container">
      <img src="assets/images/install_pwa/pwa_install_android_1.jpg" alt="Android Installation Step 1" />
      <img src="assets/images/install_pwa/pwa_install_android_2.jpg" alt="Android Installation Step 2" />
      <img src="assets/images/install_pwa/pwa_install_android_3.jpg" alt="Android Installation Step 3" />
      <img src="assets/images/install_pwa/pwa_install_android_4.jpg" alt="Android Installation Step 4" />
    </div>
  </div>

  <div class="section chrome" id="chrome">
    <h2>{{ "installPage.chrome.section.title" | translate }}</h2>
    <p>{{ "installPage.chrome.section.description" | translate }}</p>
    <ol>
      <li>{{ "installPage.chrome.step1" | translate }}</li>
      <li>{{ "installPage.chrome.step2" | translate }}</li>
      <li>{{ "installPage.chrome.step3" | translate }}</li>
      <li>{{ "installPage.chrome.step4" | translate }}</li>
    </ol>
    <img src="assets/images/install_pwa/pwa_install_chrome_1.jpg" alt="Chrome Installation Step 1" />
    <img src="assets/images/install_pwa/pwa_install_chrome_2.jpg" alt="Chrome Installation Step 2" />

    <div class="section safari" id="safari">
      <h2>{{ "installPage.safari.section.title" | translate }}</h2>
      <p>{{ "installPage.safari.section.description" | translate }}</p>
      <ol>
        <li>{{ "installPage.safari.step1" | translate }}</li>
        <li>{{ "installPage.safari.step2" | translate }}</li>
        <li>{{ "installPage.safari.step3" | translate }}</li>
        <li>{{ "installPage.safari.step4" | translate }}</li>
      </ol>
      <img src="assets/images/install_pwa/pwa_install_safari_1.jpg" alt="iOS Installation Step 1" />
      <img src="assets/images/install_pwa/pwa_install_safari_2.jpg" alt="iOS Installation Step 2" />
      <img src="assets/images/install_pwa/pwa_install_safari_3.jpg" alt="iOS Installation Step 3" />
    </div>
  </div>
</div>
