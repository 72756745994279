import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { FileLogger } from "../helpers/fileLogger";
import { CANAL, IDocumentInfo, IExportPdf, IPDFOptions } from "../models/export.interface";
import { PatientUser } from "../models/patient.interface";
import { ToolsApiService } from "./api/tools-api.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class ToolsService {
  public static SUFFIX_QUANTITY = "";

  constructor(private toolsApi: ToolsApiService, private sessionService: SessionService, private translateService: TranslateService) {
    ToolsService.SUFFIX_QUANTITY = " " + this.translateService.instant("mydrugs.dose");
    this.translateService.onLangChange.subscribe((_langEvent) => {
      ToolsService.SUFFIX_QUANTITY = " " + this.translateService.instant("mydrugs.dose");
    });
  }

  private buildDocumentInfo(pu: PatientUser): IDocumentInfo {
    if (pu) {
      const services = pu?.patient?.healthcareservice;
      const healthserviceName = services?.length === 1 ? services[0].display : undefined;
      const organizationName = pu?.patient?.managingOrganization?.display;
      const patientFullName = pu?.user?.name + " " + pu?.user?.firstname;
      const patientGender =
        pu?.patient?.gender === "male"
          ? this.translateService.instant("forms.male")
          : pu?.patient?.gender === "female"
          ? this.translateService.instant("forms.female")
          : "Inconnu";
      const patientBirthdate = pu?.user?.birthdate;
      const patientInternalNumber = FHIRHelper.getOrganizationIdentifier(pu?.patient, false)?.value;

      return {
        organizationName,
        healthserviceName,
        patientFullName,
        patientGender,
        patientBirthdate,
        patientInternalNumber,
      };
    } else {
      return {
        organizationName: null,
        healthserviceName: null,
        patientFullName: null,
        patientGender: null,
        patientBirthdate: null,
        patientInternalNumber: null,
      };
    }
  }

  private downloadFileAndOpenInBrowser(response: unknown) {
    const data = "data:application/pdf;base64," + response;

    fetch(data)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        window.open(window.URL.createObjectURL(blob));
      });
  }

  public createPFDAndOpenInBrowser(pu: PatientUser, title: string, content: string): Promise<void> {
    return new Promise((resolve) => {
      const documentInfo = this.buildDocumentInfo(pu);

      const exportPdf: IExportPdf = {
        documentInfo,
        sendingCanal: {
          canal: CANAL.RESPONSE,
        },
        lang: this.sessionService.userLang,
        title,
        content,
        preserveTempFile: false, // for debug -> preserveTempFile: true
      };

      this.toolsApi.exportToPDF(exportPdf).subscribe(
        (response) => {
          this.downloadFileAndOpenInBrowser(response);
          resolve();
        },
        (error) => {
          FileLogger.error("tools.service", "convertHTMLToPDF : error converting HTML to PDF", error);
          resolve();
        }
      );
    });
  }

  public convertHtmlToPFDAndOpenInBrowser(
    pu: PatientUser,
    title: string,
    htmlElement: string,
    style?: string,
    pdfOptions?: IPDFOptions,
    overwriteStyle?: boolean // if true, existing styles will be erased and replace with new style, else style will be append to existing styles
  ): Promise<void> {
    return new Promise((resolve) => {
      const documentInfo = this.buildDocumentInfo(pu);
      let head;

      if (overwriteStyle) {
        head = `<head><style>${style}</style></head>`;
      } else {
        head = document.head.outerHTML.replace(/\n/g, "");
        head = head.replace("</head>", `<style>${style}</style></head>`);
      }

      const html = `<html>${head}<body>#PATIENT_INFOS#${htmlElement}</body></html>`;

      const exportPdf: IExportPdf = {
        documentInfo,
        sendingCanal: {
          canal: CANAL.RESPONSE,
        },
        lang: this.sessionService.userLang,
        title,
        content: html,
        preserveTempFile: false, // for debug -> preserveTempFile: true
        pdfOptions,
      };

      this.toolsApi.convertHTMLToPDF(exportPdf).subscribe(
        (response) => {
          this.downloadFileAndOpenInBrowser(response);
          resolve();
        },
        (error) => {
          FileLogger.error("tools.service", "convertHTMLToPDF : error converting HTML to PDF", error);
          resolve();
        }
      );
    });
  }
}
