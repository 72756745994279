import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-search-input",
  templateUrl: "./search-input.component.html",
  styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
  @Input() placeholder: string;
  @Input() searchValue: string;
  @Output() searchChange = new EventEmitter<string>();

  updateSearch(value: string): void {
    this.searchValue = value;
    this.searchChange.emit(value);
  }
}
