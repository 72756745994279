import { Observable, of } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { PatientDataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IPatientsListInfo } from "src/app/models/patient.interface";
import { PatientService } from "src/app/providers/patient.service";

export class PatientDataSource extends MatTableDataSourceBackendExtended<IPatientsListInfo> {
  public rawData$ = this.dataSubject.asObservable();
  constructor(private patientService: PatientService) {
    super();
  }

  public data(params: PatientDataSourceBackendParam): Observable<IPatientsListInfo[]> {
    if (!params.services || params.services.length < 1) {
      return of([]);
    }
    return this.patientService.findPatient(params);
  }
}
