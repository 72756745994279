<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <mat-card *ngIf="createAction && alert && allDefinitions">
      <h3>{{ "alerts.alertsDetails" | translate }}</h3>
      <div class="alert-row">
        <div class="alert-column">
          <span class="alert-label">{{ "table.date" | translate }}</span>
          <span class="alert-value">{{ alert.creation | date : "short" }}</span>
        </div>

        <div class="alert-column">
          <span class="alert-label">{{ "table.level" | translate }}</span>
          <span class="alert-value">
            <mat-icon class="custom-icon-small" [ngClass]="alert | alertLevelClass">notifications</mat-icon>
          </span>
        </div>

        <div class="alert-column">
          <span class="alert-label">{{ "table.responsable" | translate }}</span>
          <span class="alert-value">{{ alert.author?.display ? alert.author?.display : "-" }}</span>
        </div>
        <div class="alert-column">
          <span class="alert-label">{{ "table.status" | translate }}</span>
          <span class="alert-value">{{ alert.statusTranslateKey() | translate }}</span>
        </div>

        <div class="alert-column">
          <span class="alert-label">{{ "table.value" | translate }}</span>
          <span class="alert-value" style="display: flex; flex-direction: column">
            <div *ngFor="let valueComponent of alert.valueComponents">
              <span>{{ valueComponent | translateAlertName : alert.observation : allDefinitions }} : </span>
              <span>
                {{
                  valueComponent.code.coding[0].code
                    | loincAndValueMeaning
                      : alert.observation?.code.coding[0].code
                      : valueComponent.valueQuantity.value
                      : allDefinitions
                      : true
                }}</span
              >
            </div>
          </span>
        </div>
      </div>
    </mat-card>

    <!-- Alert details for noDataTransmission -->
    <mat-card *ngIf="createAction && alert && alert.ruleMeta.typeTarget === RULE_TYPE_TARGET.NO_DATA_TRANSMISSION">
      <h3>{{ "alerts.alertsDetails" | translate }}</h3>
      <!-- Patient Info -->
      <div class="alert-row">
        <div class="alert-column alert-column-vertical">
          <span class="alert-label">{{ "table.patient" | translate }}</span>
          <span class="alert-value">{{ alert.subject.display }}</span>
        </div>

        <!-- No Data Transmission Info -->

        <div *ngFor="let item of $any(alert.noDataTransmission) | keyvalue">
          <div class="alert-column alert-column-vertical">
            <span class="alert-label">{{ "alerts.noDataTransmission." + item.key | translate }}</span>
            <span class="alert-value">{{ item.value | date : "dd/MM/yyyy HH:mm" }}</span>
          </div>
        </div>

        <!-- Status -->
        <div class="alert-column alert-column-vertical">
          <span class="alert-label">{{ "table.status" | translate }}</span>
          <span class="alert-value">{{ alert.statusTranslateKey() | translate }}</span>
        </div>
      </div>
    </mat-card>

    <app-alert-details-edit
      (saved)="close(true)"
      [patientServiceId]="patientServiceId"
      [alert]="alert"
      [createAction]="createAction"
    ></app-alert-details-edit>
    <app-alert-details-historic *ngIf="createAction" [alert]="alert"></app-alert-details-historic>
  </div>
</div>
