import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export interface WidgetActionConfig {
  type: "button" | "toggle";
  label?: string; // Texte du libellé ou du tooltip
  label$?: Observable<string>;
  ariaLabel?: string;
  icon?: string; // Nom de l'icône Material
  condition$?: Observable<boolean>; // Condition pour afficher le bouton
  conditionDisable$?: Observable<boolean>; // Condition pour disabled le bouton. Note: on mobile, disabled buttons are hidden
  disabledTooltip?: string;
  isLoading$?: Observable<boolean>;
  action: ($event) => unknown; // Nom de la méthode à appeler dans le parent
  toggleState$?: Observable<boolean>;
}

@Component({
  selector: "app-widget-actions",
  templateUrl: "./widget-actions.component.html",
  styleUrls: ["./widget-actions.component.scss"],
})
export class WidgetActionsComponent implements OnChanges {
  @Input() actions: WidgetActionConfig[] = [];
  @Input() isMobile: boolean;

  @Output() actionTriggered = new EventEmitter<{ action: string; event: unknown }>();
  hasVisibleActions$: Observable<boolean>;

  onAction(action: string, event?: unknown): void {
    this.actionTriggered.emit({ action, event });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions) {
      this.hasVisibleActions$ = combineLatest(
        this.actions.map(
          (action) => (action.condition$ ? action.condition$ : of(true)) // If no condition, consider as visible
        )
      ).pipe(map((conditions) => conditions.some((condition) => condition)));
    }
  }
}
