import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from "@angular/cdk/stepper";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Image, Slide } from "src/app/helpers/helpData";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";

@Component({
  selector: "app-global-help-dialog",
  templateUrl: "./global-help-dialog.component.html",
  styleUrls: ["./global-help-dialog.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class GlobalHelpDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  // import stepper from html to interact with angular material methods
  @ViewChild("stepper") private myStepper: MatStepper;

  public slides: Slide[];
  public isLastSlide = false;
  public isFirstSlide = true;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  public animationDone: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { slides: Slide[] },
    private statService: UserStatisticsService,
    private translateService: TranslateService,
    public responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.statService.createStatEvent("Opened help  modal (" + this.translateService.instant(this.data.slides[0].title) + ")");
    this.slides = this.data.slides;
  }

  ngAfterViewInit(): void {
    // when user change page the stepper emit an event : StepperSelectionEvent
    // We subscribe to it and we recompute boolean at each itteration
    if (this.slides.length > 1) {
      this.myStepper.selectionChange.pipe(takeUntil(this.onDestroy$)).subscribe((event: StepperSelectionEvent) => {
        this.isFirstSlide = event.selectedIndex === 0;
        this.isLastSlide = event.selectedIndex === this.slides.length - 1;
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Check if we need to show mat-stepper or not
   */
  public isOnlyOnePage(): boolean {
    return this.slides.length === 1;
  }

  /**
   * Check if we need to show more image than descriptions
   */
  public isMoreImageThanDescription(slide: Slide): boolean {
    return slide.descriptions.length < slide.images.length;
  }

  /**
   * Return images not show by ngFor descriptions in html
   */
  public getMissingImages(slide: Slide): Image[] {
    if (this.isMoreImageThanDescription(slide)) {
      // get diff between images and description
      const diff = slide.images.length - slide.descriptions.length;
      const images = [];
      // start by the end for the loop is why we decrement index
      for (let index = slide.images.length; index > diff; index--) {
        // push missing images in array
        images.push(slide.images[index - 1]);
      }
      return images;
    } else {
      // normally this never append
      return [];
    }
  }

  onNextSlide(): void {
    this.myStepper.next();
  }
  onPreviousSlide(): void {
    this.myStepper.previous();
  }
}
