import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { first, skipWhile, take, takeUntil } from "rxjs/operators";
import { UiAlertComponent } from "src/app/components/shared/ui-alert/ui-alert.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CareplanAccess, ICareplan, ILink2Careplan } from "src/app/models/careplans.interface";
import { StatusEntity } from "src/app/models/entity.interface";
import { IKnowMedia, IKnowledgeBase, KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { IQuestionnaire, SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/reference.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { Tools } from "../../helpers/tools";
import { IEntity, STATUS_ENTITY } from "../../models/sharedInterfaces";
import { DraftWarningDialogComponent } from "./draft-warning-dialog/draft-warning-dialog.component";
import { MediaSelectorDialogComponent } from "./media-selector-dialog/media-selector-dialog.component";

@Component({
  selector: "app-knowledge-details-page",
  templateUrl: "./knowledge-details-page.component.html",
  styleUrls: ["./knowledge-details-page.component.scss"],
})
export class KnowledgeDetailsPageComponent implements OnInit, OnDestroy {
  @ViewChild("lastAuthorAlert") lastAuthorAlert: UiAlertComponent;
  public dataSource: IKnowMedia[];
  public publishedKnowledge: IKnowledgeBase;
  public draftKnowledge: IKnowledgeBase;
  public isPublic: boolean;
  public hasErrors: boolean;
  public isPublishing: boolean;
  public disable: boolean;
  public canDownload = false;
  public StatusEntity = StatusEntity;
  private onDestroy$ = new Subject<void>();
  public isMngtAuthorized: boolean;
  public isPublishAuthorized: boolean;
  public lastAuthorRef: Reference;
  public currentUserRef: Reference;
  public startFadeOut = false;
  public showWarning = true;
  public DocumentCategory = KNOW_DOC_CATEGORY;
  public KNOW_CATEGORY = KNOW_CATEGORY;
  public STATUS_ENTITY = STATUS_ENTITY;

  columnsToDisplay = ["grip", "changes", "label", "language", "description", "action"];
  public cantPublishReason = "page.knowledgeDetails.btn.publish";
  public cannotDownloadMsg = "page.knowledgeDetails.btn.cannotDownload";
  public canDownloadMsg = "page.knowledgeDetails.btn.canDownload";
  @ViewChild(MatTable) table: MatTable<unknown>;

  public knowledgeForm: UntypedFormGroup = this.fb.group({
    public: ["", []],
  });
  public availableCareplans: ICareplan[];
  /* These three different variables are needed :
    - linkedCareplans -> is what the getCareplansByKnowledge route returns because we need the whole object to get the translations
    - changedLinkedCareplans -> is used by the ngModel
    - copyLinkedCareplans -> is needed to compare the changes
  */
  public linkedCareplans: {
    reference: string;
    name: string;
  }[] = [];
  public changedLinkedCareplans: string[] = [];
  private copyLinkedCareplans: string[] = [];
  public showCriteria: boolean;
  public previousLocation: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private knowledgeService: KnowledgeService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService,
    public sessionService: SessionService,
    private questionnaireService: QuestionnairesService,
    private fb: UntypedFormBuilder,
    private careplanService: CareplansService
  ) {
    this.previousLocation = this.router.getCurrentNavigation()?.extras?.state?.previousLocation;
  }

  ngOnInit(): void {
    this.userService
      .isAuthorized("dashboard/knowledge", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isMngtAuthorized = value;
      });
    this.userService
      .isAuthorized("dashboard/publishKnowledge", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isPublishAuthorized = value;
      });
    this.publishedKnowledge = this.route.snapshot.data.knowledgeData.published;
    this.draftKnowledge = this.route.snapshot.data.knowledgeData.draft;
    this.computeShowCriteria();

    if (this.draftKnowledge) {
      this.canDownload = false;
      if (this.draftKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
        this.knowledgeForm.get("public").setValue(true);
      } else {
        this.knowledgeForm.get("public").setValue(false);
      }
    } else if (this.publishedKnowledge) {
      this.canDownload = true;
      if (this.publishedKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
        this.knowledgeForm.get("public").setValue(true);
      } else {
        this.knowledgeForm.get("public").setValue(false);
      }
    }

    this.currentUserRef = {
      reference: this.sessionService.account.caremateIdentifier,
      display: this.sessionService.account.displayName,
    };

    this.lastAuthorRef = this.draftKnowledge?.author;

    this.setDataSource();
    this.isPublic = this.draftKnowledge ? IEntity.isPublicShared(this.draftKnowledge) : IEntity.isPublicShared(this.publishedKnowledge);
    this.disablePublish();

    this.knowledgeForm.get("public").valueChanges.subscribe((value) => {
      if (value) {
        if (this.draftKnowledge && !this.draftKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
          this.draftKnowledge.entityStatus.push(STATUS_ENTITY.SHARED);
          this.knowledgeService
            .updateDraft(this.draftKnowledge)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (k) => {
                this.draftKnowledge = k;
                this.disablePublish();
              },
              (err) => FileLogger.error("KnowledgeDetailsPageComponent updateDraft 1", "Error while updating draft knowledge", err)
            );
        } else if (!this.draftKnowledge && !this.publishedKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
          this.draftKnowledge = Tools.clone(this.publishedKnowledge);
          this.draftKnowledge.entityStatus.push(STATUS_ENTITY.SHARED);
          this.knowledgeService
            .createDraft(this.draftKnowledge)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((k) => {
              this.draftKnowledge = k;
              this.setDataSource();
              this.disablePublish();
            });
        }
      } else {
        if (this.draftKnowledge && this.draftKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
          const elemIndex = this.draftKnowledge.entityStatus.indexOf(STATUS_ENTITY.SHARED);
          if (elemIndex !== -1) {
            this.draftKnowledge.entityStatus.splice(elemIndex, 1);
          }
          this.knowledgeService
            .updateDraft(this.draftKnowledge)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (k) => {
                this.draftKnowledge = k;
                this.disablePublish();
              },
              (err) => FileLogger.error("KnowledgeDetailsPageComponent updateDraft 2", "Error while updating draft knowledge", err)
            );
        } else if (!this.draftKnowledge && this.publishedKnowledge.entityStatus.includes(STATUS_ENTITY.SHARED)) {
          this.draftKnowledge = Tools.clone(this.publishedKnowledge);
          const elemIndex = this.draftKnowledge.entityStatus.indexOf(STATUS_ENTITY.SHARED);
          if (elemIndex !== -1) {
            this.draftKnowledge.entityStatus.splice(elemIndex, 1);
          }
          this.knowledgeService
            .createDraft(this.draftKnowledge)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((k) => {
              this.draftKnowledge = k;
              this.setDataSource();
              this.disablePublish();
            });
        }
      }
    });

    this.getCareplansByKnowledge();

    // initial loading of the careplanList
    if (this.sessionService.currentService && this.userService.ownOrganization) {
      this.refreshCareplanList();
    }

    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.refreshCareplanList();
    });

    if (this.sessionService.currentService && this.userService.ownOrganization) {
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.setupServicesWatch();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch(): void {
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.refreshCareplanList();
        if (this.sessionService.currentService.reference === "all") {
          this.showCriteria = false;
        } else {
          this.computeShowCriteria();
        }
      });
    }
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.refreshCareplanList();
      if (this.sessionService.currentService.reference === "all") {
        this.showCriteria = false;
      } else {
        this.computeShowCriteria();
      }
    });
  }

  setDataSource(): void {
    if (this.draftKnowledge?.medias) {
      this.dataSource = Tools.clone(this.draftKnowledge?.medias);
    } else {
      this.dataSource = Tools.clone(this.publishedKnowledge?.medias);
    }
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    // Swap the elements around
    moveItemInArray(this.dataSource as IKnowMedia[], event.previousIndex, event.currentIndex);

    if (this.draftKnowledge) {
      this.draftKnowledge.medias = Tools.clone(this.dataSource);
      this.draftKnowledge.author = this.currentUserRef;
      this.dismissAlert(); // Remove the warning after the user has made a change
      this.table.renderRows();
      this.disablePublish();

      this.knowledgeService
        .updateDraft(this.draftKnowledge)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (k) => {
            this.draftKnowledge = k;
          },
          (err) => FileLogger.error("KnowledgeDetailsPageComponent updateDraft in onDrop", "Error while updating draft knowledge", err)
        );
    } else {
      this.draftKnowledge = Tools.clone(this.publishedKnowledge);
      this.draftKnowledge.medias = this.dataSource;
      this.draftKnowledge.author = this.currentUserRef;
      this.table.renderRows();
      this.disablePublish();

      this.knowledgeService
        .createDraft(this.draftKnowledge)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (k) => {
            this.draftKnowledge = k;
          },
          (err) => FileLogger.error("KnowledgeDetailsPageComponent createDraft in onDrop", "Error while creating draft knowledge", err)
        );
    }
    this.canDownload = false;
  }

  public addMedia(): void {
    if (!this.draftKnowledge) {
      this.draftKnowledge = this.publishedKnowledge;
    }
    if (this.publishedKnowledge && this.draftKnowledge._id === this.publishedKnowledge._id) {
      this.draftKnowledge._id = null;
    }
    this.canDownload = false;
    this.router.navigateByUrl("/knowledgeMediaEditor", {
      state: { draftKnowledge: this.draftKnowledge, isNewMedia: true, previousLocation: this.previousLocation },
    });
  }

  public openMedia(media: IKnowMedia, readOnly?: boolean): void {
    if (!this.draftKnowledge) {
      this.draftKnowledge = Tools.clone(this.publishedKnowledge);
    }

    // Check that the draft id is the one created for the draft and not the one from the published knowledge
    // It's important because we check if this.draftKnowledge._id exists to determine if we're doing an update or a creation
    if (this.publishedKnowledge && this.draftKnowledge._id === this.publishedKnowledge._id) {
      this.draftKnowledge._id = null;
    }
    this.canDownload = false;

    this.router.navigateByUrl("/knowledgeMediaEditor", {
      state: {
        media,
        draftKnowledge: this.draftKnowledge,
        publishedKnowledge: this.publishedKnowledge,
        readOnly,
        previousLocation: this.previousLocation,
      },
    });
  }

  public publish(): Promise<void> {
    // start the spinner and force disabled state
    this.disable = true;
    this.isPublishing = true;
    return new Promise<void>((resolve, reject) => {
      // Some quizzes that are linked in the published version could have been unlinked in the draft
      // After publishing the draft, we need to unlink those quizzes. For that, we first need the list of
      // all the quizzes that should be unlinked (before they disappear with the save)
      const toUnlink = this.findAllQuestionnairesToUnlink();
      this.knowledgeService
        .publishKnowledge(this.draftKnowledge.identifier.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (k) => {
            this.knowledgeService
              .getKnowledge(k.identifier.value)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result) => {
                  this.publishedKnowledge = result.published;
                  this.draftKnowledge = result.draft;
                  this.setDataSource();
                  // stop the spinner
                  this.isPublishing = false;
                  // re-evaluate disabled status
                  this.disablePublish();
                  // Then we unlink them !
                  this.unlinkQuestionnaires(toUnlink)
                    .then(() => {
                      this.canDownload = true;
                      this.snackBar
                        .open(`✅ ${this.translateService.instant("page.knowledgeDetails.publishSuccess")}`, null, { duration: 4000 })
                        .afterDismissed()
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe();
                      this.computeShowCriteria();
                      resolve();
                    })
                    .catch((err2) => {
                      FileLogger.error("KnowledgeDetailsPageComponent", "Error while trying to unlink questionnaires", err2);
                      this.isPublishing = false;
                      reject(err2);
                    });
                },
                (err) => {
                  this.isPublishing = false;
                  return reject(err);
                }
              );
          },
          (err) => {
            this.isPublishing = false;
            return reject(err);
          }
        );
    });
  }

  private findAllQuestionnairesToUnlink(): string[] {
    let questToUnlink = [];
    let draftVersionQuest = [];
    // Check for deleted medias with questionnaires and get a list of all
    // media's questionnaires:
    for (const media of this.draftKnowledge.medias) {
      if (media.entityStatus === StatusEntity.DELETED && media.specificQuestionnaire?.length) {
        questToUnlink = questToUnlink.concat(media.specificQuestionnaire);
      } else if (media.specificQuestionnaire?.length) {
        draftVersionQuest = draftVersionQuest.concat(media.specificQuestionnaire);
      }
    }
    // If there's no published knowledge, we can stop here:
    if (!this.publishedKnowledge) {
      return questToUnlink;
    }
    // Else we need to compare the published version questionnaires list with
    // the draft version'
    for (const media of this.publishedKnowledge.medias) {
      if (media.specificQuestionnaire?.length) {
        // Get all the questionnaires that are in the published version but not in the draft
        const toUnlink = media.specificQuestionnaire.filter((qId: string) => !draftVersionQuest.includes(qId));
        questToUnlink = questToUnlink.concat(toUnlink);
      }
    }
    return questToUnlink;
  }

  private async unlinkQuestionnaires(questIds: string[]): Promise<void> {
    if (!questIds.length) {
      return;
    }
    const allQuests = await this.getAllQuestionnairesList();
    const allUpdatesPromises = [];
    for (const qId of questIds) {
      // const qToUnlink = allQuests.find((q: IQuestionnaire) => q.identifier[0].value === qId);
      let qToUnlink: IQuestionnaire;
      for (const q of allQuests) {
        if (q.identifier[0].value === qId) {
          qToUnlink = q;
        }
      }
      if (qToUnlink) {
        allUpdatesPromises.push(
          this.questionnaireService.linkQuestionnaireToMedia(qToUnlink.identifier[0].value, qToUnlink.version, "", "")
        );
      } else {
        FileLogger.warn(
          "KnowledgeDetailsPageComponent",
          "Could not find questionnaire to unlink: " + qId + " in the all quest list: ",
          allQuests
        );
      }
    }
    await Promise.all(allUpdatesPromises);
  }

  private async getAllQuestionnairesList(): Promise<IQuestionnaire[]> {
    let specificUse = SPECIFIC_USE.QUIZ;
    if (this.draftKnowledge?.documentCategory === KNOW_DOC_CATEGORY.CONSENT) {
      specificUse = SPECIFIC_USE.CONSENT;
    }
    const allQuest = await this.questionnaireService.getSpecificQuestionnaireList(specificUse);
    return allQuest;
  }

  public detectErrorInDraft(): void {
    this.hasErrors = false;
    if (this.draftKnowledge) {
      for (const media of this.draftKnowledge.medias) {
        if (!media.label || !media.language || !media.type) {
          this.hasErrors = true;
          return;
        }
      }
    }
  }

  public knowledgeAreEquals(k1: IKnowledgeBase, k2: IKnowledgeBase): unknown {
    if (!k1 || !k2) {
      return false;
    }
    k1 = Tools.clone(k1);
    k2 = Tools.clone(k2);
    delete k1._id;
    delete k1.modified;
    delete k1.publicationDate;
    delete k2._id;
    delete k2.modified;
    delete k2.publicationDate;
    return Tools.isEqual(k1, k2);
  }

  public disablePublish(): void {
    this.detectErrorInDraft();
    if (
      this.hasErrors ||
      !this.draftKnowledge ||
      this.dataSource.length === 0 ||
      this.knowledgeAreEquals(this.draftKnowledge, this.publishedKnowledge) ||
      !this.isPublishAuthorized
    ) {
      this.disable = true;
    } else {
      this.disable = false;
    }
    this.setupCantPublishReason();
  }

  /**
   * Set up the tooltip message explaining why the user cannot publish the
   * current knowledge.
   */
  private setupCantPublishReason(): void {
    if (this.hasErrors) {
      this.cantPublishReason = "page.knowledgeDetails.btn.hasErrors";
    } else if (!this.draftKnowledge) {
      this.cantPublishReason = "page.knowledgeDetails.btn.noDraft";
    } else if (this.dataSource.length === 0) {
      this.cantPublishReason = "page.knowledgeDetails.btn.noMedias";
    } else if (this.knowledgeAreEquals(this.draftKnowledge, this.publishedKnowledge)) {
      this.cantPublishReason = "page.knowledgeDetails.btn.alreadyExists";
    } else if (!this.isPublishAuthorized) {
      this.cantPublishReason = "page.knowledgeDetails.btn.needPermission";
    } else {
      this.cantPublishReason = "page.knowledgeDetails.btn.publish";
    }
  }

  public handleClick(media: IKnowMedia): void {
    if (this.draftKnowledge) {
      this.openDialog(media);
    } else {
      if (this.isMngtAuthorized) {
        this.openMedia(media);
      } else {
        this.openMedia(media, true);
      }
    }
  }

  openDialog(media: IKnowMedia): void {
    const publishedMedia = this.publishedKnowledge?.medias.find((m) => m._id === media._id);

    if (publishedMedia) {
      const dialogRef = this.dialog.open(MediaSelectorDialogComponent, {
        width: "500px",
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result === "draft") {
            this.openMedia(media, !this.isMngtAuthorized);
          } else if (result === "published") {
            this.openMedia(publishedMedia, true);
          }
        });
    } else {
      this.openMedia(media, !this.isMngtAuthorized);
    }
  }

  public deleteMedia(media: IKnowMedia, index: number): void {
    if (!this.draftKnowledge) {
      this.draftKnowledge = Tools.clone(this.publishedKnowledge);
      this.draftKnowledge.medias[index].entityStatus = StatusEntity.DELETED;
      this.draftKnowledge.author = this.currentUserRef;
      this.dismissAlert(); // Remove the warning after the user has made a change
      this.knowledgeService
        .createDraft(this.draftKnowledge)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((k) => {
          this.draftKnowledge = k;
          this.setDataSource();
          this.disablePublish();
        });
    } else {
      if (this.publishedKnowledge?.medias.find((x) => x._id === media._id)) {
        // change entity status to deleted
        this.draftKnowledge.medias[index].entityStatus = StatusEntity.DELETED;
        this.draftKnowledge.author = this.currentUserRef;
        this.dismissAlert(); // Remove the warning after the user has made a change
        this.knowledgeService
          .updateDraft(this.draftKnowledge)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (k) => {
              this.draftKnowledge = k;
              this.setDataSource();
              this.disablePublish();
            },
            (err) =>
              FileLogger.error("KnowledgeDetailsPageComponent updateDraft in deleteMedia 1", "Error while updating draft knowledge", err)
          );
      } else {
        // just remove item from draft
        this.draftKnowledge.medias.splice(index);
        this.knowledgeService
          .updateDraft(this.draftKnowledge)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (k) => {
              this.draftKnowledge = k;
              this.setDataSource();
              this.disablePublish();
            },
            (err) =>
              FileLogger.error("KnowledgeDetailsPageComponent updateDraft in deleteMedia 2", "Error while updating draft knowledge", err)
          );
      }
    }
    this.canDownload = false;
  }

  public cancelDelete(media: IKnowMedia, index: number): void {
    this.draftKnowledge.medias[index].entityStatus = StatusEntity.ACTIVE;
    this.knowledgeService
      .updateDraft(this.draftKnowledge)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (k) => {
          this.draftKnowledge = k;
          this.dataSource = this.draftKnowledge.medias;
          this.disablePublish();
        },
        (err) => FileLogger.error("KnowledgeDetailsPageComponent updateDraft in cancelDelete", "Error while updating draft knowledge", err)
      );
    this.canDownload = false;
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public back(): void {
    if (this.disable) {
      this.navigateBack();
    } else {
      this.dialog
        .open(DraftWarningDialogComponent, {
          data: { id: this.draftKnowledge?._id },
        })
        .beforeClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result?.publish) {
            this.publish().then(() => this.navigateBack());
          } else {
            this.navigateBack();
          }
        });
    }
  }

  private navigateBack(): void {
    const destination = this.previousLocation ? [this.previousLocation] : ["/knowledgeList"];
    this.router.navigate(destination);
  }

  dismissAlert(): void {
    this.lastAuthorAlert?.onDismiss();
  }

  /**
   * Create a temporary invisible href link to download the publishedKnowledge,
   * then click on it to start the download, before removing it.
   * Solution found at:
   * https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
   */
  public download(): void {
    const element = document.createElement("a");
    const knowledge = this.publishedKnowledge ? this.publishedKnowledge : this.draftKnowledge;
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(knowledge)));
    element.setAttribute("download", knowledge.snomedReference.reference + ".json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public getCareplansByKnowledge(): void {
    this.knowledgeService
      .getCareplansByKnowledge(
        this.draftKnowledge ? this.draftKnowledge.identifier.value : this.publishedKnowledge.identifier.value,
        this.sessionService.userLang
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((careplans) => {
        this.linkedCareplans = careplans;
        careplans.forEach((element) => {
          this.changedLinkedCareplans.push(element.reference);
          this.copyLinkedCareplans.push(element.reference);
        });
      });
  }

  public async saveLinkedCareplan(): Promise<void> {
    const copyLinkedCareplans2 = [];
    const changedLinkedCareplans2 = [];

    // Keep only careplans that are in the selected service
    for (const cp of this.availableCareplans) {
      if (this.copyLinkedCareplans.includes(cp.support[0].reference)) {
        copyLinkedCareplans2.push(cp.support[0].reference);
      }
      if (this.changedLinkedCareplans.includes(cp.support[0].reference)) {
        changedLinkedCareplans2.push(cp.support[0].reference);
      }
    }

    const toRemove = [];
    const toAdd = [];

    for (const c of changedLinkedCareplans2) {
      if (!copyLinkedCareplans2.includes(c)) {
        toAdd.push(c);
      }
    }

    for (const c of copyLinkedCareplans2) {
      if (!changedLinkedCareplans2.includes(c)) {
        toRemove.push(c);
      }
    }

    if (toAdd.length) {
      const promisesAdd: Promise<{
        published: ILink2Careplan;
        draft: ILink2Careplan;
      }>[] = [];
      toAdd.forEach((cReference) => {
        promisesAdd.push(
          this.careplanService
            .getLink2Careplan(cReference)
            .pipe(take(1))
            .toPromise()
            .catch((err) => {
              FileLogger.error(
                "KnowledgeDetailsPageComponent",
                "Error in toAdd getLink2Careplan promisesAdd, cannot find the link2careplan with reference:",
                cReference,
                err
              );
              return null;
            })
        );
      });
      const l2cArray = (await Promise.all(promisesAdd)).map((result) => result.published);
      const promisesUp = [];
      for (const l2c of l2cArray) {
        if (l2c) {
          l2c.knowledges.push(this.draftKnowledge ? this.draftKnowledge.identifier.value : this.publishedKnowledge.identifier.value);
          promisesUp.push(
            this.careplanService
              .updateLink2Careplan(l2c)
              .pipe(take(1))
              .toPromise()
              .catch((err) => {
                FileLogger.error("KnowledgeDetailsPageComponent", "Error in toAdd updateLink2Careplan, promiseUp", err);
                return null;
              })
          );
        }
      }
      await Promise.all(promisesUp);
    }

    if (toRemove.length) {
      const promisesAdd: Promise<{
        published: ILink2Careplan;
        draft: ILink2Careplan;
      }>[] = [];
      toRemove.forEach((cReference) => {
        promisesAdd.push(
          this.careplanService
            .getLink2Careplan(cReference)
            .pipe(take(1))
            .toPromise()
            .catch((err) => {
              FileLogger.error(
                "KnowledgeDetailsPageComponent",
                "Error in toRemove getLink2Careplan promisesAdd, cannot find the link2careplan with reference: " + cReference,
                err
              );
              return null;
            })
        );
      });
      const l2cArray = (await Promise.all(promisesAdd)).map((result) => result.published);
      const promisesUp = [];
      for (const l2c of l2cArray) {
        if (l2c) {
          const elemIndex = l2c.knowledges.indexOf(
            this.draftKnowledge ? this.draftKnowledge.identifier.value : this.publishedKnowledge.identifier.value
          );
          if (elemIndex !== -1) {
            l2c.knowledges.splice(elemIndex, 1);
          }
          promisesUp.push(
            this.careplanService
              .updateLink2Careplan(l2c)
              .pipe(take(1))
              .toPromise()
              .catch((err) => {
                FileLogger.error("KnowledgeDetailsPageComponent", "Error in toRemove updateLink2Careplan, promiseUp", err);
              })
          );
        }
      }
      await Promise.all(promisesUp);
    }

    this.copyLinkedCareplans = Tools.clone(this.changedLinkedCareplans);

    this.getCareplansByKnowledge();
    this.snackBar
      .open(`✅ ${this.translateService.instant("common.success")}`, null, {
        duration: 4000,
      })
      .afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  public compareFn(user1: ICareplan, user2: string): boolean {
    return user1.support[0].reference === user2;
  }

  public changeCareplan(event: MatSelectChange): void {
    const newArray: string[] = [];
    event.value.forEach((careplan) => {
      newArray.push(careplan.support[0].reference);
    });
    this.changedLinkedCareplans = newArray;
  }

  private refreshCareplanList(): void {
    this.careplanService
      .getCareplanTemplates(this.sessionService.currentService.reference, this.sessionService.userLang)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((careplanTemplate) => {
        const onlyFree =
          this.draftKnowledge?.category === KNOW_CATEGORY.CONSENT_TPDA_GENERIC ||
          this.publishedKnowledge?.category === KNOW_CATEGORY.CONSENT_TPDA_GENERIC;
        this.availableCareplans = onlyFree ? careplanTemplate.filter((c) => c.access === CareplanAccess.FREEACCESS) : careplanTemplate;
      });
  }

  private computeShowCriteria(): void {
    const knowledge = this.publishedKnowledge ? this.publishedKnowledge : this.draftKnowledge;

    this.showCriteria =
      (knowledge?.documentCategory === this.DocumentCategory.DESCRIPTION ||
        knowledge?.documentCategory === this.DocumentCategory.RECOMMENDATION) &&
      (knowledge.category === KNOW_CATEGORY.MEDICAL ||
        knowledge.category === KNOW_CATEGORY.OBSERVATION ||
        knowledge.category === KNOW_CATEGORY.PATHOLOGY);
  }
}
