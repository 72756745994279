import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { Account } from "src/app/models/account.interface";
import { IMfaType, MFA_TYPE } from "src/app/models/authentification.interface";
import { AuthService } from "src/app/providers/auth.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { UserService } from "src/app/providers/user.service";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";

@Component({
  selector: "app-mfa-setup",
  templateUrl: "./mfa-setup.component.html",
  styleUrls: ["./mfa-setup.component.scss"],
})
export class MfaSetupComponent implements OnInit, OnDestroy {
  public account: Account;
  public mfaQRCodeUrl: string;
  public mfaSecret: string;
  public mfaCode: string;
  public mfaError: boolean;
  public mfaErrorDisable: boolean;
  public mfaWrongCode: boolean;
  public mfaSuccess: boolean;
  public mfaTypesList: IMfaType[] = [];
  public chosenMfaType: MFA_TYPE = MFA_TYPE.GOOGLE_AUTHENTICATOR;
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private responsiveDialog: ResponsiveDialogService,
    private helpData: HelpData
  ) {}

  ngOnInit(): void {
    this.userService
      .account()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.account = account;
        this.mfaTypesList = this.authService.getMfaTypesList(account);
      });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public async enable2FA(): Promise<void> {
    this.mfaError = false;
    try {
      const rep = await this.authService.enable2FA(this.chosenMfaType);
      if (this.chosenMfaType === MFA_TYPE.GOOGLE_AUTHENTICATOR) {
        this.mfaSecret = rep.secret;
        this.mfaQRCodeUrl = rep.qrCodeUrl;
      }
    } catch (error) {
      FileLogger.error("MfaSetupComponent", "Got an error while enabling mfa: ", error);
      this.mfaSecret = "";
      this.mfaQRCodeUrl = "";
      this.mfaError = true;
    }
  }

  public async disable2FA(): Promise<void> {
    this.mfaError = false;
    this.mfaSuccess = false;
    this.mfaWrongCode = false;
    this.mfaErrorDisable = false;
    this.mfaSecret = "";
    this.mfaQRCodeUrl = "";
    try {
      await this.authService.disable2FA();
      this.account.mfaActive = false;
    } catch (error) {
      FileLogger.error("MfaSetupComponent", "Got an error while disabling mfa: ", error);
      this.mfaErrorDisable = true;
    }
  }

  public async confirm2FA(): Promise<void> {
    this.mfaError = false;
    this.mfaSuccess = false;
    this.mfaWrongCode = false;
    try {
      const isActive = await this.authService.confirm2FA(this.mfaCode);
      this.mfaSuccess = isActive;
      this.mfaWrongCode = !isActive;
      this.account.mfaActive = isActive;
    } catch (error) {
      FileLogger.error("MfaSetupComponent", "Got an error while confirming mfa Code: ", error);
      this.mfaError = true;
    }
  }

  public openMfaAuthHelp(): void {
    if (this.chosenMfaType === MFA_TYPE.GOOGLE_AUTHENTICATOR) {
      this.responsiveDialog.open(
        GlobalHelpDialogComponent,
        {
          data: { slides: this.helpData.googleAuthHelp },
          disableClose: false,
        },
        { width: "55vw", height: "80vh" }
      );
    }
  }
}
