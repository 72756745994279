<div class="myServicePage">
  <button
    mat-fab
    class="newServiceBtn"
    *ngIf="currentOrganization && service === null && ('service' | isAuthorized : 'POST' | async) === true"
    matTooltip="{{ 'page.organizations.btn.newServ' | translate }}"
    (click)="onCreateService()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-card class="no-padding m-auto mt-15 vw-80" *ngIf="service">
    <app-service-details-component [service]="service"></app-service-details-component>
  </mat-card>
  <div *ngIf="!service">
    <mat-card class="no-padding m-auto mt-15 vw-80" *ngFor="let s of services">
      <app-service-details-component [service]="s"></app-service-details-component>
    </mat-card>
  </div>
  <mat-card class="m-auto mt-15 vw-80">
    <app-user-list-page [service]="service" [services]="services" [organization]="currentOrganization"></app-user-list-page>
  </mat-card>
</div>
