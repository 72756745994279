import * as d3 from "d3";
import { IChartColor } from "../models/chart.interface";
import { IStatistic } from "../models/statObservation.interface";
import { IChartConfig } from "../models/stream-observation-interface";
import { FileLogger } from "./fileLogger";

export interface IDataPoint {
  minutesAfterMidnight: number;
  [key: string]: number; // Permet d'ajouter dynamiquement les pct05, pct25, etc.
}

export enum xAxisType {
  XXIVHours = "24h",
  dates = "dates",
}

export enum yAxisType {
  percentile = "percentile",
  value = "value",
}

export class ChartHelper {
  /**
   * Creates an SVG element for the chart with the specified dimensions and margins.
   *
   * @param width - The width of the SVG element.
   * @param height - The height of the SVG element.
   * @param margin - The margin around the SVG element.
   * @param selector - The selector for the HTML element where the SVG will be appended.
   * @returns The created SVG element as a D3 selection.
   */
  public static createSvg(
    width: number,
    height: number,
    margin: number,
    selector: any,
    resizeOnExport?: boolean
  ): d3.Selection<SVGGElement, unknown, HTMLElement, unknown> {
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("class", resizeOnExport ? "toResize" : "")
      .attr("width", width + margin * 2)
      .attr("height", height + margin * 2)
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");
    return svg;
  }

  /**
   * Parse a D3 locale object from JSON format to the expected format for d3.timeFormatDefaultLocale().
   * @param d3Locale - The D3 locale object in JSON format from i18n file.
   * @returns The parsed D3 locale object in the expected format for d3.timeFormatDefaultLocale().
   */
  public static parseLocale(d3Locale: JSON): d3.TimeLocaleDefinition {
    const local = {};
    for (const [key, value] of Object.entries(d3Locale)) {
      local[key] = JSON.parse(value as string);
    }
    return local as d3.TimeLocaleDefinition;
  }

  // Fonction de transformation
  public static percentileStatToDataPoint(input: IStatistic): IDataPoint[] {
    if (!input.labels) {
      FileLogger.error("chart-helper - percentileStatToDataPoint", "Labels are missing from the input data");
    }

    const labels = input.labels; // Ex: ["pct05", "pct25", "pct50", "pct75", "pct95"]

    const transformedData = input.values.map((valueEntry) => {
      const values = valueEntry.valueQuantity.values;
      const transformed: IDataPoint = {
        minutesAfterMidnight: valueEntry.minutesAfterMidnight,
      };

      labels.forEach((label, index) => {
        transformed[label] = values[index]; // Assign values based on the order of labels
      });

      return transformed;
    });

    return transformedData.sort((a, b) => a.minutesAfterMidnight - b.minutesAfterMidnight);
  }

  public static statToDataPoint(input: IStatistic): IDataPoint[] {
    const data = input.values.map((valueEntry) => {
      const transformed: IDataPoint = {
        value: valueEntry.valueQuantity.value,
        minutesAfterMidnight: valueEntry.minutesAfterMidnight,
      };
      return transformed;
    });
    return data.sort((a, b) => a.minutesAfterMidnight - b.minutesAfterMidnight);
  }

  public static createXAxis(
    type: xAxisType,
    margin: number,
    width: number,
    height: number,
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
    chartConfig: IChartConfig
  ): d3.ScaleLinear<number, number, never> | d3.ScaleTime<number, number, never> {
    // Define x-axis using linear scale for minutesAfterMidnight

    switch (type) {
      case xAxisType.XXIVHours: {
        const x = d3
          .scaleLinear()
          .domain([0, 1440]) // 0 minutes to 1440 minutes (24 hours)
          .range([margin, width - margin]);

        // Append x axis to SVG
        svg
          .append("g")
          .attr("transform", `translate(0,${height - margin})`)
          .call(
            d3
              .axisBottom(x)
              .tickValues(d3.range(0, 1441, 180)) // Set ticks every 180 minutes (3 hours)
              .tickFormat((d: number) => {
                const hours = Math.floor(d / 60);
                const minutes = d % 60;
                return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
              }) // Format as HH:MM
          );

        return x;
      }

      case xAxisType.dates: {
        const x = d3
          .scaleUtc()
          .domain([chartConfig.xAxis.min, chartConfig.xAxis.max])
          .range([margin, width - margin]);

        svg
          .append("g")
          .attr("transform", `translate(0,${height - margin})`)
          .call(
            d3
              .axisBottom(x)
              .ticks(width / 80)
              .tickSizeOuter(0)
          );
        return x;
      }
    }
  }

  public static createYAxis(
    type: yAxisType,
    margin: number,
    width: number,
    height: number,
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
    chartConfig: IChartConfig
  ): d3.ScaleLinear<number, number, never> {
    switch (type) {
      case yAxisType.percentile: {
        const y = d3
          .scaleLinear()
          .domain([chartConfig.yAxis.min, chartConfig.yAxis.max])
          .range([height - margin, margin]);

        // append y axis to svg
        svg
          .append("g")
          .attr("transform", `translate(${margin},0)`)
          .call(d3.axisLeft(y).ticks(height / 40))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick line")
              .clone()
              .attr("x2", width - margin * 2)
              .attr("stroke-opacity", 0.1)
          );

        return y;
      }

      case yAxisType.value: {
        const y = d3
          .scaleLinear()
          .domain([chartConfig.yAxis.min, chartConfig.yAxis.max])
          .range([height - margin, margin]);

        // append y axis to svg
        svg
          .append("g")
          .attr("transform", `translate(${margin},0)`)
          .call(d3.axisLeft(y).ticks(height / 40))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick line")
              .clone()
              .attr("x2", width - margin * 2)
              .attr("stroke-opacity", 0.1)
          );

        return y;
      }
    }
  }
  public static chartColors: IChartColor[] = [
    {
      // dark blue
      backgroundColor: "rgba(0,95,179,0.5)",
      borderColor: "rgba(0,95,179,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // orange
      backgroundColor: "rgba(250,151,0,0.5)",
      borderColor: "rgba(250,151,0,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // green
      backgroundColor: "rgba(75, 192, 192,0.5)",
      borderColor: "rgba(75, 192, 192,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    /* {   //  grey
            backgroundColor: 'rgba(95,100,95,0.2)',
            borderColor: 'rgba(95,100,95,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },*/
    {
      // yellow
      backgroundColor: "rgba(245, 240, 30,0.5)",
      borderColor: "rgba(245, 240, 30,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // light blue
      backgroundColor: "rgba(54, 162, 235,0.5)",
      borderColor: "rgba(54, 162, 235,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // red
      backgroundColor: "rgba(255, 99, 132,0.5)",
      borderColor: "rgba(255, 99, 132,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // purple
      backgroundColor: "rgba(205,85,230,0.5)",
      borderColor: "rgba(205,85,230,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];
}
