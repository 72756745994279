<ng-container *ngIf="!loading">
  <div
    #legend
    class="legend-container mt-1"
    [style.paddingLeft]="margin + 'px'"
    [style.paddingRight]="margin + 'px'"
    *ngIf="data?.length > 0; else noData"
  >
    <div *ngFor="let legend of legends" class="legend-element">
      <div class="legend-icon" [ngStyle]="{ backgroundColor: legend.color }"></div>
      <div class="legend-value-container">
        <span>{{ legend.date | date : "dd-MM-YYYY" }}</span>
        <span class="value" *ngIf="legend.currentValue">({{ legend.currentValue }} mg/dl)</span>
      </div>
    </div>
  </div>
</ng-container>

<div #chartContainer id="chart" [style.height]="chartConfig.height + 'px'" class="chart-container" *ngIf="this.data?.length">
  <figure
    *ngIf="data?.length > 0"
    [id]="chartId"
    class="m-0"
    style="text-align: center"
    [ngStyle]="{ display: !loading ? 'block' : 'none' }"
  ></figure>

  <div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

<!-- <div class="selected-points-legend">
  <div *ngFor="let point of selectedPointsData" class="selected-point-container">
    <mat-icon fontSet="fas" fontIcon="fa-circle" class="legend-icon" [ngStyle]="{ color: point.color }"></mat-icon>
    <span>{{ point.value }} mg/dl</span>
  </div>
</div> -->

<ng-template #noData>
  <p class="text-center mt-10 pb-10" *ngIf="!loading">
    {{ "widget.noData" | translate }}
  </p>
</ng-template>
<div *ngIf="!loading && externalRessourceRef?.includes('dexcom')">CGM Data by DEXCOM</div>
