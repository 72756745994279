<form>
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title>{{ data?.fieldName }}</h2>

  <mat-dialog-content>
    <mat-checkbox *ngFor="let item of choices" [checked]="item.checked" (change)="item.checked = !item.checked">
      <ng-container *ngIf="data.translatePrefix">
        {{ data.translatePrefix + "." + item.value | translate }}
      </ng-container>
      <ng-container *ngIf="!data.translatePrefix">
        {{ item.value | translate }}
      </ng-container>
    </mat-checkbox>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="clear()" aria-label="Clear filter">
      {{ "itemFilter.clear" | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="apply()" aria-label="Apply filter" type="submit">
      {{ "btn.apply" | translate }}
    </button>
  </mat-dialog-actions>
</form>
