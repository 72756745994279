<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button color="warn" class="negative-margin" (click)="onClose()" type="button" [disabled]="savingInProgress">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="!loading">
    <div class="ui-dialog-content">
      <h3 *ngIf="isCreation">{{ "communication.newComm" | translate }}</h3>
      <h3 *ngIf="!isCreation">{{ "communication.updateCom" | translate }}</h3>
      <form id="communicationForm" [formGroup]="communicationForm" (ngSubmit)="submit()">
        <mat-form-field class="chip-list" style="width: 90%">
          <mat-label>{{ "communication.sendTo" | translate }}</mat-label>
          <mat-chip-list #chipListSendTo aria-label="Send to selection" formControlName="currentSendTo">
            <mat-chip *ngFor="let item of currentSendTo" [selectable]="selectable" (removed)="removeSendTo(item)">
              {{ item.display }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="{{ 'communication.addPatient' | translate }}"
              #sendToInput
              [formControl]="sendToCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipListSendTo"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addSendToChip($event)"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let item of filteredSendTo | async" [value]="item">
              {{ item.display }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'btn.addFromGroup' | translate }}"
          (click)="openGroupSearch()"
          type="button"
          [disabled]="isGroupSelected"
          *ngIf="!data.onePatientOnly"
        >
          <mat-icon>group_add</mat-icon>
        </button>
        <mat-form-field style="width: 100%">
          <mat-label>{{ "communication.subject" | translate }}</mat-label>
          <input type="text" matInput formControlName="subject" />
        </mat-form-field>
        <div class="mb-10">
          <p class="mb-10">{{ "communication.content" | translate }}</p>
          <jodit-editor
            [ngClass]="
              communicationForm.get('content').hasError('required') && (isSubmitted || communicationForm.get('content').touched)
                ? 'hasError'
                : ''
            "
            [config]="config"
            formControlName="content"
          ></jodit-editor>
        </div>
        <input hidden (change)="addPdf($event)" #fileInput type="file" accept="application/pdf" id="file" multiple />
        <input
          hidden
          (change)="addImage($event)"
          #imgInput
          type="file"
          accept="image/jpg,image/jpeg,image/png,image/tiff"
          id="img"
          multiple
        />
        <mat-chip-list #chipList>
          <mat-label *ngIf="attachementsString.length">{{ "communication.attachment" | translate }} : </mat-label>
          <mat-chip *ngFor="let item of attachementsString" [removable]="true" (removed)="remove(item)">
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </form>
    </div>
    <div class="ui-dialog-actions">
      <button mat-raised-button matTooltip="{{ 'page.organizations.btn.addImage' | translate }}" (click)="imgInput.click()">
        <mat-icon>insert_photo</mat-icon>
      </button>
      <button mat-raised-button matTooltip="{{ 'btn.addPdf' | translate }}" (click)="fileInput.click()">
        <mat-icon>attachment</mat-icon>
      </button>
      <button form="communicationForm" mat-raised-button color="primary" (click)="send(false)" type="submit" [disabled]="savingInProgress">
        {{ "btn.send" | translate }}
      </button>
      <button form="communicationForm" mat-raised-button color="primary" (click)="send(true)" type="submit" [disabled]="savingInProgress">
        {{ "communication.saveDraft" | translate }}
      </button>
      <p *ngIf="savedDate">{{ "communication.lastSaveAt" | translate }} : {{ savedDate }}</p>
    </div>
  </ng-container>

  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
