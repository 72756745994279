<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <h3>{{ "btn.overview" | translate }}</h3>
    <p *ngIf="getAttachements.length">{{ "communication.attachment" | translate }} :</p>
    <mat-chip-list>
      <mat-chip *ngFor="let item of getAttachements" (click)="openAttach(item)">{{ item.title }}</mat-chip>
      <mat-icon class="fail" *ngIf="attach" (click)="closeAttach()">visibility_off</mat-icon>
    </mat-chip-list>
    <br />
    <mat-card *ngIf="attach" style="height: 80vh; width: 50vw">
      <iframe
        [src]="attach"
        frameborder="0"
        style="border: 0; top: 0px; left: 0px; bottom: 0px; right: 0px; width: 100%; height: 100%"
        allowfullscreen
      ></iframe>
    </mat-card>
    <div [ngClass]="{ smartphone: (responsiveService.isHandset$ | async) === false }">
      <div class="content">
        <iframe [srcdoc]="safeHtml" style="width: 100%; border: none; height: 100%"></iframe>
      </div>
    </div>
  </div>
</div>
