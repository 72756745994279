import { AbstractControl } from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";
import { DELETE_REQUEST_STATUS } from "../models/delete-request.interface";
import { Identifier } from "../models/identifier.interface";
import { KNOW_DOC_CATEGORY } from "../models/knowledge.interface";
import { Reference } from "../models/reference.interface";
import { ITranslation } from "../models/translation.interface";
import { LanguagesService } from "../providers/languages.service";
import { FHIRHelper } from "./FHIRhelper";
import { FileLogger } from "./fileLogger";

export class Tools {
  public static get MONGO_ID_SIZE(): number {
    return 24;
  }

  /**
   * return a random guid valid for DB
   */
  public static genValidId(): string {
    return Tools.genGuid(Tools.MONGO_ID_SIZE);
  }

  /**
   * generate a random GUID of "size" hexa caracters
   */
  public static genGuid(size: number): string {
    try {
      return Tools.toHexString(window.crypto.getRandomValues(new Uint8Array(size / 2)));
    } catch (err) {
      return "";
    }
  }

  /**
   * Convert ArrayBuffer to Hexa string
   */
  public static toHexString(byteArray: ArrayBufferView): string {
    try {
      return Array.prototype.map
        .call(byteArray, (byte) => {
          // eslint-disable-next-line no-bitwise
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join("");
    } catch (err) {
      return "";
    }
  }

  public static capitalize(s: unknown): string {
    if (typeof s !== "string") {
      return "";
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * Generate a random password with nbrChar characters
   * @param nbrChar password length
   */
  public static generatePassword(nbrChar: number): string {
    const values = "abcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < nbrChar; i++) {
      const char = values.charAt(Math.floor(Math.random() * Math.floor(values.length)));
      const lowerCase = Math.floor(Math.random() * 2);
      password += lowerCase ? char : char.toUpperCase();
    }
    return password;
  }

  public static identifier2reference(identifier: Identifier): Reference {
    return {
      reference: identifier.value,
      display: identifier.label ? identifier.label : identifier.value,
    };
  }

  public static identifiers2MainReference(identifiers: Identifier[]): Reference {
    const identifier = identifiers.find((id) => this.isCaremateId(id.system));
    return {
      reference: identifier?.value,
      display: identifier?.label ? identifier.label : identifier?.value,
    };
  }

  private static isCaremateId(value: string) {
    return value === FHIRHelper.OLD_SYSTEM_CAREMATE || value === FHIRHelper.SYSTEM_COMUNICARE;
  }

  public static removePatientFromRef(arr: Reference[], value: string): Reference[] {
    return arr.filter((ele) => ele.reference !== value);
  }

  public static clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object)) as T;
  }

  /**
   * Check if the values are equal. By default, ignore the "_id" key in the comparison
   * @param value The first value
   * @param other The second value
   * @param keyNotToBeCompared (string[], default: ["_id"]) keys that should not be compared to determine if the objects are equal
   * @returns
   */
  public static isEqual(value: unknown, other: unknown, keyNotToBeCompared: string[] = ["_id"]): boolean {
    if (typeof value === "object" && typeof other === "object") {
      return (
        JSON.stringify(Tools.sortObjectByKeys(value, keyNotToBeCompared)) ===
        JSON.stringify(Tools.sortObjectByKeys(other, keyNotToBeCompared))
      );
    } else {
      return value === other;
    }
  }

  /**
   * Returns a copy of this object where the keys are sorted alphabetically in a recursive manner
   * and where the "keyNotToBeCompared" are set to "undefined"
   * @param obj
   * @param keyNotToBeCompared (string[], default: ["_id"]) keys that should not be compared to determine if the objects are equal
   * @returns
   */
  public static sortObjectByKeys<T>(obj: T, keyNotToBeCompared: string[] = ["_id"]): T {
    if (Tools.isNotDefined(obj)) {
      return obj;
    }

    // convert mongoose object to JSON when necessary
    if ((obj as any).toJSON) {
      obj = (obj as any).toJSON();
    }

    const ordered = Object.keys(obj)
      .sort()
      .reduce((obj2: T, key) => {
        if (keyNotToBeCompared.includes(key)) {
          obj2[key] = undefined;
        } else {
          const objKey = obj[key];
          if (typeof objKey === "object") {
            obj2[key] = this.sortObjectByKeys(objKey, keyNotToBeCompared);
          } else {
            obj2[key] = objKey;
          }
        }
        return obj2;
      }, {} as T);
    return ordered;
  }

  public static uniq(a: string[]): string[] {
    return Array.from(new Set(a));
  }

  public static uniqObject(obj: Record<string, unknown>[]) {
    let r: any = obj.map((el) => JSON.stringify(el));
    r = new Set(r);
    return Array.from(r).map((el: any) => JSON.parse(el));
  }

  /**
   * A method to check if an object is valid or not
   * @param objectToCheck => the object to check
   * @return The result
   */

  public static isValidObject(objectToCheck: unknown): boolean {
    return Object.values(objectToCheck).every((value) => !!value);
  }

  /**
   * A method that return all the display types possible for a knowledge
   * @param translateService => the service of translation
   * @return an array of strings, all the display types possible for a knowledge
   */
  public static getKnowledgeTypeChoices(): KNOW_DOC_CATEGORY[] {
    const values = this.getValuesOfEnum(KNOW_DOC_CATEGORY);
    return values;
  }

  public static suppressDiacritics(str: string): string {
    if (!str || str.length === 0) {
      return "";
    }
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * Return the values of a enum
   * @param enumeration The enum
   * @returns A array of values
   */
  public static getValuesOfEnum<T>(enumeration: T): Array<T[keyof T]> {
    const enumKeys = Object.keys(enumeration)
      .filter((v) => isNaN(Number(v))) // we need this filter because numeric enum object contains duplicate keys : value <-> key + key <-> value
      .map((k) => enumeration[k]);
    const items = new Array<T[keyof T]>();
    for (const elem of enumKeys) {
      items.push(elem as any);
    }
    return items;
  }

  /**
   * Compare two dates
   * @returns The number of days between two dates
   */
  public static differenceDate(date1: Date, date2: Date): number {
    const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const day = 1000 * 60 * 60 * 24;
    return (date1utc - date2utc) / day;
  }

  /**
   * Get current language translation from ITranslation object
   */
  public static getTranslation(definition: ITranslation, lang: string, txtBackup: string): string {
    try {
      if (!definition) {
        return txtBackup;
      }
      if (Object.prototype.hasOwnProperty.call(definition, lang)) {
        return definition[lang];
      } else {
        FileLogger.error("Tools", `Error on getting ${lang} lang`, definition, "none");
        return txtBackup;
      }
    } catch (err) {
      FileLogger.error("Tools", "getTranslation", err);
      return txtBackup;
    }
  }

  /**
   * Return a phone validator with invalid status if the input value is not valid and 'null' if it is correct.
   * (This does not seem like the right way to do this... there's definitely a better way)
   * @param control the input control containing the phone number
   */
  public static phoneValidator(control: AbstractControl): { [key: string]: true } | null {
    let phoneNumber = control.value as string;
    try {
      if (!phoneNumber) {
        return null;
      }
      if (phoneNumber.startsWith("00")) {
        phoneNumber = "+" + phoneNumber.substring(2);
      }
      if (phoneNumber.startsWith("+")) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const parsePhone = phoneUtil.parse(phoneNumber);
        return phoneUtil.isValidNumber(parsePhone) ? null : { phoneValidator: true };
      } else {
        return { phoneValidator: true };
      }
    } catch (err) {
      // const msg = err.message ? err.message : "Not a phone number";
      // console.log("phoneValidator " + phoneNumber + " : " + msg);
      return { phoneValidator: true };
    }
  }

  /**
   * Check if a value is different of null and undefined
   * @param value The specified value
   * @returns true | false
   */
  public static isDefined(value: unknown): boolean {
    return value !== null && value !== undefined;
  }

  public static isObjectEmpty(object: unknown): boolean {
    if (!Tools.isDefined(object)) {
      return true;
    }
    if (Object.keys(object).length === 0) {
      return true;
    }
    return false;
  }

  /**
   * Check if a value is equal to null or undefined
   * @param value The specified value
   * @returns true | false
   */
  public static isNotDefined(value: unknown): boolean {
    return !Tools.isDefined(value);
  }

  public static getDeleteRequestStatus(): DELETE_REQUEST_STATUS[] {
    const values = this.getValuesOfEnum(DELETE_REQUEST_STATUS);
    return values;
  }

  public static getQuizLanguage(languagesService: LanguagesService): string[] {
    let itranslations: ITranslation[];
    languagesService.list().subscribe((l) => {
      itranslations = l;
    });
    const languages = [];
    itranslations.forEach((element) => {
      languages.push(element.term);
    });
    return languages;
  }

  public static isString(s: unknown): boolean {
    return typeof s === "string";
  }

  public static observationLoincConversionMap(type: string): string {
    switch (type) {
      case "1":
        return "8310-5"; // temperature
      case "2":
        return "55284-4"; // blood pressure
      case "3":
        return "8867-4"; // heart rate
      case "4":
        return "3141-9"; // body weight
      default:
        return type;
    }
  }

  public static downloadBlob(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    a.click();
  }
  public static openBlob(blob: Blob): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("target", "_blank");
    a.click();
  }

  public static standardizePeriodUnit(unit: string): string {
    switch (unit?.toLowerCase()) {
      case "days":
      case "day":
      case "d":
        return "d";
      case "weeks":
      case "week":
      case "wk":
      case "w":
        return "w";
      case "months":
      case "month":
      case "M":
        return "M";
      case "years":
      case "year":
      case "y":
        return "y";
      case "hours":
      case "hour":
      case "h":
        return "h";
      case "minutes":
      case "minute":
      case "min":
      case "m":
        return "m";
      case "seconds":
      case "second":
      case "s":
        return "s";
      case "":
      case undefined:
        return "";
      default:
        throw new Error("Unsupported period unit: " + unit);
    }
  }

  /**
   * @param key - key to check
   * @returns a boolean that indicate if the key is a traduction key or not
   */
  public static isTranslateKey(key: string): boolean {
    if (!key) return false;
    return key.substring(0, 4) === "$TR$";
  }

  /**
   * Returns a Promise that resolves after the specified time duration.
   * @param {number} ms - The duration in milliseconds to wait before resolving the Promise.
   * @returns {Promise<void>} A Promise that resolves after the specified time duration.
   */
  public static timeout(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
