import { Component, Inject, OnInit } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import moment from "moment";
import { IDateInterval } from "src/app/models/sharedInterfaces";

@Component({
  selector: "app-mobile-questionnaire-list-settings",
  templateUrl: "./mobile-questionnaire-list-settings.component.html",
  styleUrls: ["./mobile-questionnaire-list-settings.component.scss"],
})
export class MobileQuestionnaireListSettingsComponent implements OnInit {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  showAnonymized: boolean;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<MobileQuestionnaireListSettingsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { fromDate: moment.Moment; toDate: moment.Moment; showAnonymized: boolean }
  ) {}

  ngOnInit(): void {
    this.showAnonymized = this.data.showAnonymized;
    this.fromDate = this.data.fromDate;
    this.toDate = this.data.toDate;
  }

  onDateChange(event: IDateInterval): void {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
  }

  dismiss(): void {
    this._bottomSheetRef.dismiss({ fromDate: this.fromDate, toDate: this.toDate, showAnonymized: this.showAnonymized });
  }

  cancel(): void {
    this._bottomSheetRef.dismiss();
  }
}
